.dropdownRow {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 32px;
}

.dropdownRow > div > h1 {
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 500 !important;
}

.dropdownRow > div:first-child {
    max-width: 90%;
    flex: 1;
}

.dropdownRow h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

@media (min-device-width: 768px) {
    .dropdownRow h1 {
        font-size: 18px !important;
    }

    .tabTitle {
        font-size: 30px;
        font-weight: 500 !important;
    }
}

@media screen and (min-device-width: 1024px) {
    .dropdownRow h1 {
        font-size: 24px !important;
    }
}