:global(#root)>.header {
    background: transparent;
    border-bottom: 1px solid black;
    height: var(--header-height);
}

.headerGrid {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    height: 100%;
    align-items: center;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--main-container-max-width);
    padding: 0 20px;

}

.menuGrid {
    display: grid;
    grid-template-columns: 20px 100px;
    align-items: center;
    justify-content: start;
    gap: 20px;
}

.appsGrid {
    display: grid;
    grid-template-columns: 20px 20px 32px;
    align-items: center;
    justify-content: end;
    gap: 20px;
}

.appsGrid .icon:hover {
    opacity: 0.5;
}

.icon {
    cursor: pointer;
}

.notificationIcon {
    width: 20px;
    height: 20px;
}

.search {
    display: flex;
    cursor: pointer;
}

.apps {
    display: none;
}

.logo {
    width: 100px;
    /*margin-left: 20px;*/
}

.end {
    justify-self: end;
}

.start {
    justify-self: start;
}

.dropdown {
    top: 60px !important;
    z-index: 10000;
    /* Workaround to fix 4px on left */
    /* width: 101%; */
    width: 100%;
    margin-top: -2px !important;
    position: fixed !important
}

.dropdown ul li {
    padding-left: 20px;
    padding-right: 20px;
}

.settings ul li {
    text-align: right;
}

.dropdown ul li span {
    font-size: 16px;
    font-style: normal;
    line-height: 19px;
    letter-spacing: 0em;
}

.dropdown ul li:hover {
    background-color: var(--color-medium-gray) !important;
    cursor: pointer;
}

.appsDropdown {
    position: fixed !important;
    top: var(--header-height) !important;
    z-index: 10000;
    max-width: 400px;
    border-right: 1px solid var(--color-medium-gray);
    border-left: 1px solid var(--color-medium-gray);
    border-bottom: 1px solid var(--color-medium-gray);
}

.dropdown .menuContainer {
    position: relative;
    height: calc(100vh - 57px);

    background-color: #f4f4f4;
}

.overflowActive {
    overflow-y: scroll;
}

.dropdown .menu {
    background-color: #f4f4f4;
}

.noNotification {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.noNotification>span {
    color: var(--color-secondary) !important;
}

.noscroll {
    overflow: hidden;
}

.burger {
    width: 16px;
    height: 16px;
    margin-right: 0;
}

.desktop {
    display: none;
}

.mobile {
    display: flex;
}

.sidebarOpen {
    background-color: var(--color-white) !important;
}

.sidebarOpen>.headerGrid:last-child {
    opacity: 0 !important;
    width: 50% !important;
}

.searchbarGrid {
    display: none;
}

.focusedSearchbarHeader>.headerGrid>.searchbarGrid {
    display: grid;
    grid-template-columns: auto 45px;
}

.searchBar {
    display: flex;
}

.searchBar :global(.ant-input-prefix) {
    margin-right: 16px !important;
}

.searchBar :global(.ant-input-affix-wrapper) {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.searchBar :global(.ant-input-affix-wrapper):focus,
.searchBar :global(.ant-input-affix-wrapper):hover {
    box-shadow: none !important;
    border: none !important;
}

.searchBar input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 22px;
    color: black;
    background-color: transparent !important;
}

.searchBar input::placeholder {
    color: var(--color-light-gray-2);
}

.searchBar input::-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.focusedSearchbarHeader>.headerGrid {
    grid-template-columns: auto !important;
}

.focusedSearchbarHeader>div>.menuGrid {
    display: none !important;
}

.focusedSearchbarHeader>div>.burger {
    display: none !important;
}

.focusedSearchbarHeader>div>.apps {
    display: none !important;
}

.focusedSearchbarHeader>div>.appsGrid {
    display: none !important;
}

.close {
    align-self: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    justify-self: flex-end;
}

.close path {
    stroke: var(--color-light-gray-2);
}

.close:hover path {
    stroke: black;
}

.searchbarDropdownChevron {
    margin-right: 4px;
}

.focusedSearchbarHeader {
    filter: unset !important;
    transition: 0.1s linear;
    background-color: var(--color-white) !important;
    border-bottom: 1px solid var(--color-secondary) !important;
}

.searchOverlay {
    transition: visibility 0s, opacity 0.2s linear;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
}

.displaySearchOverlay {
    visibility: visible;
    opacity: 1;
}

.searchbarDropdown {
    top: 119px !important;
    z-index: 100000 !important;
    position: fixed !important;
}

.searchbarDropdown :global(.ant-dropdown-menu) {
    padding: 5px 0 !important;
}

.searchbarDropdown :global(.ant-dropdown-menu-item) {
    color: var(--color-secondary);
    background-color: white !important;
    padding: 8px 12px 8px 20px !important;
}

.searchbarDropdown :global(.ant-dropdown-menu-item)>span {
    color: var(--color-secondary);
}

.searchbarDropdown :global(.ant-dropdown-menu-item-selected)>span {
    color: black
}

.searchbarDropdown :global(.ant-dropdown-menu-item-active)>span {
    color: black
}

.searchbarDropdownTitle {
    line-height: 60px;
    font-family: "GT-America";
    margin-right: 20px;
    margin-left: 5px;
    text-align: center;
    min-width: 120px;
    font-size: 16px !important;
    color: var(--color-light-gray-2);
}

.menuItem {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}


:global(.lightTextHeader) .searchbarDropdownTitle {
    color: white;
}

:global(.lightTextHeader) .searchbarDropdownRow svg path {
    fill: white !important;
}

:global(.lightTextHeader) .searchbarDropdownRow {
    filter: invert(1);
}

:global(.lightTextHeader) .searchBar input::placeholder {
    filter: invert(1);
    color: white;
}

:global(.lightTextHeader) .searchBar input::-ms-input-placeholder {
    filter: invert(1);
    color: white;
}

:global(.profilePicture) {
    filter: invert(0)
}

.focusedSearchbarHeader .searchbarDropdownTitle {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.focusedSearchbarHeader .searchbarDropdownRow {
    filter: unset !important;
}

.focusedSearchbarHeader .searchBar input::placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.focusedSearchbarHeader .searchBar input::-ms-input-placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.focusedSearchbarHeader .searchbarDropdownRow svg path {
    fill: black !important;
}


.searchbarDropdownRow {
    border-bottom: #D5D5D5 0.75px solid;
    justify-content: space-between !important;
    cursor: pointer;
}

.searchContainer {
    padding-left: 20px;
    padding-right: 20px;
}

.noResultsMesage {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px !important;
}

.centerSpinner {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.searchResultTitle {
    font-family: "DomaineDisplay";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px !important;
    color: black;
    margin-right: 5px;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchResultTitleClickable {
    cursor: pointer;

}

.searchResultTitleClickable:hover {
    text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.searchResultType {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px !important;
    color: var(--color-light-gray-2);
}

.searchResultsItem {
    align-items: flex-end;
    height: fit-content;
    margin-bottom: 15px;
    flex-wrap: nowrap !important;
}

.containerLoading {
    pointer-events: none;
    opacity: 0.5;
}

.searchResultsContainer {
    margin-top: 20px;
    height: 100vh;
    overflow: auto;
}

.searchResultTitleHighlight {
    background-color: black;
    color: white;
    padding: unset !important;

}

.hideSearchIcon {
    pointer-events: none;
    opacity: 0;
}

.seeAllCategories {
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--color-disabled);
    background-color: var(--color-white);
}

.seeAllCategories>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}

.notificationIconContainer {
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
}

.newNotification {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--color-danger);
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 100;
}

.menu li:last-child {
    margin-bottom: 60px !important
}

.tabsContainer {
    position: absolute;
    z-index: 1;
    top: 50px;
    display: flex;
    width: 100%;
}

@media screen and (min-width: 481px) {
    .logo {
        margin-left: -10px;
    }

    .searchContainer {
        padding-left: 50px;
        padding-right: 50px;
    }

    .headerGrid {
        padding-left: 50px;
        padding-right: 50px;
        margin: 0 auto;
    }

    .menuGrid {
        display: grid;
        grid-template-columns: 20px 115px;
        align-items: center;
        gap: 20px;
    }

    .menu li:last-child {
        margin-bottom: 0px !important
    }
}

@media screen and (min-width: 692px) {
    .logo {
        margin-left: -10px;
    }

    .menuGrid {
        display: grid;
        grid-template-columns: 20px 115px;
        align-items: center;
        gap: 20px;
    }

    .appsGrid {
        display: grid;
        grid-template-columns: 20px 20px 32px;
        align-items: center;
        justify-content: end;
        gap: 20px;
    }

    .dropdown {
        top: var(--header-height) !important;
        max-width: 320px;
        max-height: 505px;
        margin-top: 0 !important;
        box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, .1);
    }

    .dropdown>.menuContainer {
        max-height: 505px;
        position: relative;
        height: unset;
    }

    .menuContainer>.menu {
        padding-bottom: 0 !important;

    }

    .seeAllCategories {
        position: sticky;
        top: calc(100% - 60px);
    }

    .settings {
        max-width: 185px;
    }

    .settings ul li {
        text-align: left;
    }
}

@media screen and (min-width: 768px) {
    .logo {
        margin-left: 20pxn;
    }

    .searchbarDropdown {
        top: 50px !important;
    }

    .hideSearchbar {
        pointer-events: none;
        border-bottom: 1px black solid !important;
    }

    .hideSearchbar div {
        opacity: 0;
    }

    .hideSearchbar input {
        display: none;
    }

    .focusedSearchbarHeader {
        border-bottom: 1px solid #D2D2D2 !important;
    }

    .searchbarGrid {
        display: grid;
        grid-template-columns: 190px auto;
        align-items: center;
        z-index: 1000000;
        height: 100%;
    }

    .noResultsMesage {
        display: unset;
        justify-content: unset;
        margin-left: 65px;
    }

    .searchbarDropdownTitle {
        line-height: 34px;
    }

    .searchBar :global(.ant-input-affix-wrapper) {
        padding: 0 0 0 30px !important;
    }

    .close {
        justify-self: unset;
        margin-left: 50px;
    }

    .searchbarDropdownRow {
        margin-bottom: unset;
        border-right: var(--color-light-gray-2) 0.75px solid;
        border-bottom: unset;
        justify-content: center !important;
    }

    :global(.lightTextHeader) .searchbarDropdownRow {
        border-right: white 0.75px solid;
    }

    .focusedSearchbarHeader .searchbarDropdownRow {
        border-right: var(--color-light-gray-2) 0.75px solid !important;
    }

    .focusedSearchbarHeader>.headerGrid>.searchbarGrid {
        border-bottom: 1px black solid !important;
        grid-template-columns: 190px auto 115px 24px !important;
    }

    .searchButton {
        font-family: "GT-America";
        font-size: 14px !important;
        font-weight: 400;
        color: black;
        text-align: end;
        cursor: default;
        opacity: 0;
    }

    .displaySearchButton {
        cursor: pointer;
        opacity: 1 !important;
    }

    .searchButton:hover {
        text-decoration: underline solid var(--color-black) from-font;
        text-underline-offset: 4px;
    }

    .searchContainer {
        padding-left: calc(90px - 24px);
        padding-right: calc(90px - 24px);
        width: calc(var(--main-container-max-width) + 24px + 24px);
        grid-template-columns: 190px auto;
        display: grid;
    }

    .searchSuggestionsMessage {
        margin: 20px 0 0 0;
        font-family: "GT-America";
        font-size: 14px;
        font-weight: 300;
        line-height: 20px !important;
        text-align: center;
    }

    .searchResultsContainer {
        margin-left: 65px;
    }

    section>.header {
        height: 80px;
        padding: 0 40px !important;
    }

    .search {
        display: none;
    }

    .apps {
        display: flex;
        margin-left: 0;
        margin-right: 0;
    }

    .headerGrid {
        padding-left: 90px;
        padding-right: 90px;
        grid-template-columns: 20px 125px 125px auto 15px 7px;
        gap: 0;
    }

    .appsGrid {
        display: grid;
        grid-template-columns: 20px 15px 20px;
        align-items: center;
        justify-content: end;
        gap: 20px;
    }

    .desktop {
        display: flex;
    }

    .mobile {
        display: none;
    }

    .burger {
        width: 20px;
        height: 20px;

    }
}

@media screen and (max-width: 1274px) {
    .logo {
        margin-left: 10px;
    }
}

@media screen and (min-width: 1271px) {
    .logo {
        margin-left: 20px;
    }

    .searchContainer {
        max-width: none;
        padding-right: calc(90px - 24px);
        padding-left: calc(90px - 24px);
    }

    .headerGrid {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--main-container-max-width);
        padding: 0 90px;
    }

}

@media screen and (min-width: 1350px) {
    .logo {
        margin-left: 20px;
    }

    .searchContainer {
        width: calc(var(--main-container-max-width) + 24px + 24px);
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
    }

    .headerGrid {
        width: calc(var(--main-container-max-width));
        padding-right: unset;
        padding-left: unset;
    }
}