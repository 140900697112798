.menuContainer {
    border-bottom: solid 1px var(--color-black);
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.menuContainer:hover h1 {
    color: var(--color-white) !important;
}

.menuContainer > h1 {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.menuRow{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.menuRow > div:not(:nth-child(3n + 1)) {
    margin-left: 0;
}

@media (min-width: 480px){
    .menuContainer h1{
       font-size: 15px !important;
       font-weight: 500 !important;
       line-height: 22px !important;
    }
}
@media screen and (min-device-width: 768px) {
    .menuContainer * {
        z-index: 1;
    }
    
    .menuContainer::before {
        content: "";
        width: 0px;
        height: 100%;
        background-color: var(--color-black);
        position: absolute;
        left: 0;
        top: 0;
        transition: width .2s ease-in-out;
        z-index: 0;
    }
    
    .menuContainer:hover::before {
        width: 100%;
    }
}

@media screen and (min-device-width: 1024px) { 
    .menuRow > div:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2);
    }
}
