.searchInputContainer {
    width: 100%;
    margin-bottom: 40px;
}

.searchInputContainer > .searchInput {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.searchInput input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 22px;
    line-height: 30px;
}

.searchInput input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.searchInput :global(.ant-input-prefix) {
    margin-right: unset;
}

.searchInputIcon {
    width: 22px;
    height: 22px;
    margin-right: 18px;
    margin-left: 13px;
}

.resultsContainer {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.resultsContainer > span {
    color: var(--color-tertiary) !important;
}

.listContainer{
    margin-top: 60px !important;
    margin:auto;
    width:100%;
}

.listItem {
    margin-bottom: 40px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.listItem:hover {
    cursor: pointer;
}

.listItem:hover .listItemText {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.listItemText {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: unset;

}

.listRow >div >div >div >div{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.tooltip{
    margin-top: 6px !important;
}

.tooltip > div > div:nth-child(2){
    background-color: var(--color-light-gray) !important;
    color: var(--color-black) !important;
    padding: 12px 10px !important;
}

.pagination{
    justify-content: flex-start;
}

.divider {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-medium-gray);
    margin-top: 20px;
    margin-left: -20px;
}

@media (min-width: 480px) {

    .divider {
        margin-left: -50px;
    }
}

@media (min-width: 769px) {

    .divider {
        margin-left: -195px;
    }
    
}

@media (min-width: 768px) {

    .pagination > ul {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    .pagination > ul > li:nth-child(2) {
        width: auto;
    }
}

@media (min-width: 1350px) {
    .divider {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}