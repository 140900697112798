.container > div {
    position: relative;
    margin-bottom: 60px;
}

.container > div > div:nth-child(2) {
    margin-top: 15px;
}

.divider {
    position: absolute;
    width: 100vw;
    left: -20px;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 15px;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.labelContainer svg {
    width: 20px;
    height: 20px;
}

.labelContainer:hover {
    cursor: pointer;
}

.labelContainer span {
    color: var(--color-black) !important;
}

.mainLabel h1 {
    margin-bottom: 0 !important;
}

.resultsContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.resultsContainer > span {
    color: var(--color-tertiary) !important;
}

.bar {
    margin-right: 10px;
}

.value {
    margin-right: 10px;
}


.removeIcon {
    margin-right: 10px;
    width: 6px !important;
    height: 6px !important;
}

@media screen and (min-width: 481px) {
    .divider{
        left:-50px;
    }
}

@media screen and (min-width: 769px) {
    .divider{
        left:-90px;
    }
}

@media screen and (min-width: 1350px){
    .divider{
        left: calc((100vw - var(--main-container-max-width)) / -2)
    }
}