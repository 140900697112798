.container > div {
    position: relative;
}

.container > div > div:nth-child(2) {
    margin-top: 30px;
}

.container{
    width: 100%;
}

.divider {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 20px;
    margin-left: -90px;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.labelContainer svg {
    width: 20px;
    height: 20px;
}

.labelContainer:hover {
    cursor: pointer;
}

.labelContainer span {
    color: var(--color-black) !important;
}

.mainLabel h1 {
    margin-bottom: 0 !important;
}

.mainLabelText {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
}

.resultsContainer {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.resultsContainer > span {
    color: var(--color-tertiary) !important;
}

.bar {
    margin-right: .5rem;
}

.removeIcon {
    margin-right: .5rem;
}

@media (min-width: 1350px) {
    .divider {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}