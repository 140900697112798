
.sectionTitle {
    padding-top: 25px;
    font-size: 26px;
    line-height: 32px;
}

.arrowNext {
    transform: rotate(180deg);
}

.listItem {
    margin: 0 auto;
}

.listRowContainer {
    width: 100%;
}

.sectionRow { 
    margin-bottom: unset;
}

@media screen and (min-width: 769px) {
    .sectionTitle {
        border-top: 1px solid black;
        padding-top: 50px;
        font-size: 40px;
    }

    .carousel :global(.slick-arrow.slick-prev) {
        left: -45px;
    }
    
    .sectionRow { 
        margin-bottom: 40px;
    }
}
