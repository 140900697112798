.dropdown > span {
    width: 100%;
    display: block;
    border-bottom: 1px solid var(--color-black);
}

.dropdown > span > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.label {
    font-weight: 500;
    font-family: "DomaineDisplay";
    font-size: 26px !important;
}

.dropdownOverlay > div {
    margin-top: -4px;
}

.dropdownOverlay :global(.ant-dropdown-menu-item) {
    background-color: var(--color-white);
}

.dropdownOverlay > div :global(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
}

.dropdownOverlay > div > div:nth-child(2) :global(.ant-checkbox-wrapper) {
    margin: 9px 0;
}

.clearButton {
    font-weight: 600;
}

.clearAllDisabled {
    color: var(--color-medium-gray);
}