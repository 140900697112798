.sectionTitle {
    margin-top: 30px;
}

.sectionTitle > h1{
    font-weight: 400;
    margin: 0;
    font-size: 36px !important;
    line-height: 44px;
}

.divider {
    margin: 15px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.seeMore button {
    padding-left: 22px;
    padding-right: 22px;
}

.carousel :global(.slick-disabled) svg path {
    stroke: var(--color-light-gray-2);
}

.arrowNext {
    transform: rotate(180deg);
}

.containerLoading {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.listContainer {
    width: 100%;
}

@media (min-width: 576px) {
    .cardContainer:nth-child(2n + 1) {
        margin-left: calc(100%/12);   
    }
}

@media (min-width: 768px) {
    .sectionTitle {
        margin-top: 60px;
    }

    .divider {
        width: 100vw;
        margin-left: -50px;
    }

    .cardContainer {
        margin-left: 0 !important;   
    }

    .cardContainer:nth-child(2n + 1) {
        margin-left: 0;   
    }

    .cardContainer:not(:first-child) {
        margin-left: calc(300%/24/2);   
    }

    .carousel :global(.slick-prev) {
        left: -35px;
    }
    
    .carousel :global(.slick-next) {
        right: -35px;
    }

    .listContainer > div:not(:first-child) {
        margin-left: calc(((100% / 24) * 3) / 2 ) !important;
    }
}

@media screen and (min-width: 769px) {
    .divider {
        margin-left: -90px;
    }

    .carousel :global(.slick-prev) {
        left: -50px;
    }
    
    .carousel :global(.slick-next) {
        right:  calc(-50px + 1% );
    }
}

@media (min-width: 1200px) {
    .listContainer > div:not(:first-child) {
        margin-left: 0;
        margin-left: calc(((100% / 24) * 4) / 3 ) !important;
    }
}

@media screen and (min-width: 1350px) {
    .divider {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}



