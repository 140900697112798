.title {
    font-size: 26px;
    color: var(--color-black);
    margin-bottom: 20px;
    font-family: "DomaineDisplay";
}

@media (min-width: 1024px) {
    .title {
        font-size: 30px;
    }
}