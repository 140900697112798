.introContainer {
  background-color: #ebebeb;
  padding-bottom: 40px !important;
  position: relative;
  margin-bottom: 60px !important;
  background-size: cover;
  background-position: center;
  justify-content: center;
  max-width: 100vw !important;
}

.introContainer > div:first-child {
  max-width: var(--main-container-max-width);
  width: 100%;
}

.backgroundContainer {
  position: absolute;
  width: 100%;
}

.description {
  font-family: "GT-America";
  font-size: var(--font-size-page-description);
  line-height: var(--line-height-page-description);
  font-weight: 300 !important;
  width: 100%;
}

.description * {
  font-family: "GT-America";
  font-size: var(--font-size-page-description);
  line-height: var(--line-height-page-description);
  font-weight: 300 !important;
  width: 100%;
  color: inherit;
}

@media screen and (min-width: 768px) {
  .introContainer {
    padding-bottom: 80px !important;
  }
  .description * {
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-lead) !important;
  }
}

@media screen and (min-width: 992px) {
  .introContainer {
    padding-bottom: 100px !important;
    max-width: none !important;
  }

  .introContainer > div:not(:last-child) {
    max-width: var(--main-container-max-width);
    margin: auto;
  }

  .description {
    display: block;
    width: 80%;
  }

  .backgroundContainer > img:first-child {
    float: left;
  }

  .backgroundContainer > img:nth-child(2) {
    float: right;
  }
}