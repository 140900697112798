.container {
    background-color: var(--color-black);
    padding: 15px;
    max-width: 100%;
}

.container > div {
    width: 100%;
}

.title {
    color: var(--color-white) !important;
    font-size: 18px !important;
    line-height: 26px !important;
}