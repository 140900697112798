.loadingContainer {
    padding-top: 80px;
    padding-bottom: 60px;
}

.bodyContainer {
    margin-bottom: 50px;
    text-align: justify;
}

.separator {
    padding-bottom: 15px !important;
    margin-bottom: 1px !important;
}

.separator hr {
    position: absolute;
    left: 0;
    border-top: 1px solid var(--color-black);
}

.introContainer {
    margin-bottom: 20px;
}

.linkSeparator hr {
    margin-top: unset;
    border-top: 1px solid var(--color-black);
}

.linkSeparator {
    margin-bottom: 10px !important;
}

@media screen and (min-width: 769px) { 
    .linkList {
        margin-bottom: 50px;
    }

    .linksContainer {
        margin: auto;
        max-width: 270px;
    }
}

@media screen and (min-width: 1024px) {
    .pageBody p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
    
    .pageBody p {
        color: var(--color-secondary);
        font-size: 18px;
    }

    .pageBody h1 {
        font-family: "DomaineDisplay";
        font-size: 26px;
    }
}

