.description>span *,
.description>span {
    color: var(--color-black) !important;
    font-size: var(--font-size-lead) !important;
    line-height: var(--line-height-lead) !important;
    font-weight: 400;
    text-align: start;
    font-family: "DomaineDisplay";
    color: #444444 !important;
}

.description2 {
    margin-top: 20px;
}

.description2>span,
.description2>span * {
    font-family: "GT-America";
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 300 !important;
}

.descriptionsContainer {
    margin-top: 80px !important;
}

@media (min-device-width: 768px) {

    .description>span {
        color: var(--color-black) !important;
    }

    .description2>span,
    .description2>span * {
        font-family: "GT-America";
        font-size: var(--font-size-content) !important;
        line-height: var(--line-height-content) !important;
        font-weight: 300 !important
    }

    .description2 {
        margin-top: unset;
    }

    .descriptionsContainer {
        margin-top: 75px
    }
}