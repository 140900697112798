.searchInputContainer {
    width: 100%;
    margin-bottom: 40px;
}

.searchInputContainer > .searchInput {
    height: 50px;
    padding-right: unset;
    padding-left: 15px;
}

.searchInput input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 20px;    
}

.searchInput input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.searchInput :global(.ant-input-prefix) {
    margin-right: unset;
}

.searchInputIcon {
    width: 20px !important;
    height: 20px !important;
    margin-right: 18px;
    margin-left: 13px;
}

.cardListContainer {
    margin: 2rem auto 40px auto !important;
}

.pagination {
    display: none;
}

.resultsContainer {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.resultsContainer > span {
    color: var(--color-tertiary) !important;
}

.tabMenuContainer {
    width: 100%;
    margin-bottom: 20px;
}

.noMarginBottom {
    margin-bottom: 0;
}

.divider {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-medium-gray);
    margin-top: 20px;
    margin-left: -90px;
}

.divider {
    display: none;
}

.listItem {
    margin: 0 auto;
}


@media (min-width: 768px) {
    .divider {
        display: block;
    }

    .tabMenuContainer {
        width: 100%;
        margin-bottom: 40px;
    }
}


@media (min-width: 1009px) {
    .displayMobile {
        display: none;
    }
}

@media (min-width: 1024px) {
    .cardListContainer {
        margin-top: 4rem !important;
    }
}

@media (min-width: 1350px) {
    .divider {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}
