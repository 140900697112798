.background{
    background-image: url("../../../../assets/images/jpeg/mainPages/plus.jpg");
    background-position: center;
    background-size: cover;
}

.cutoutLeft{
    width: 1000px !important;
    position: absolute !important;
    left: -500px !important;
}

.cutoutRight{
    left: unset !important;
    right: 0px !important;
    position: absolute !important;
    width: 200px !important;
    top: -274px !important;
}

.loading{
    background: none !important;
}