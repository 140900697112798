.container :global(.ant-input-affix-wrapper) {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding-top: .75rem;
    padding-bottom: .75rem;
}

.container :global(.ant-input-affix-wrapper):focus,
.container :global(.ant-input-affix-wrapper):hover {
    box-shadow: none !important;
    border: none !important;
}

.container input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 14px;
}

@media (min-device-width: 768px) {
    .container input {
        font-size: 15px !important;
    }
}

@media (min-device-width: 1024px) {
    .container input {
        font-size: 18px !important;
    }
}