.listContainer {
    width: 100%;
}

.sectionTitle {
    margin-top: 30px;
    padding-bottom: 8px;
}

.sectionTitle > h1{
    font-weight: 400;
    margin: 0;
}

.divider {
    margin: 8px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.seeMore button {
    padding-left: 22px;
    padding-right: 22px;
}

.searchInputRow{
    width: 100%;
    margin-top: 30px !important;
}

.renderRow{
    margin-top: 50px !important;
}

.arrowNext {
    transform: rotate(180deg);
}

.searchInputContainer {
    width: 100%;
}

.searchInputContainer > .searchInput {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.searchInputContainer > .searchInput > :global(.ant-input-suffix) {
    width: 290px;
}

.searchInput input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 26px;
    letter-spacing: 0px;
    line-height: 26px;
}

.searchInput input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
}

.searchInput :global(.ant-input-prefix) {
    margin-right: unset;
}

.searchInputIcon {
    width: 24px;
    height: 24px;
    margin-right: 18px;
}

.filter {
    margin-top: 30px !important;
    width: 100%;
}

.filter > div:nth-child(2) span{
    font-family: "GT-America" !important;
    font-weight: 300 !important;
    font-size: 12px !important;
}

.dropdownContainer {
    margin-bottom: 18px;
}

.overlay > div {
    padding: 0;
    margin-top: -4px;
}

.overlay *:hover {
    background-color: var(--color-white) !important;
}

.overlay :global([ant-click-animating-without-extra-node='true']::after),
.overlay :global(.ant-click-animating-node) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow:none;
    opacity: 1;
    animation: none;
    animation-fill-mode: none;
    pointer-events: none;
}

.dropdownRow {
    border-bottom: solid 1px var(--color-black);
}

.dropdownRow h1 {
    margin-bottom: 6px;
    font-weight: 600;
}

.checkboxGroup {
    width: 100%;
}

.checkboxGroup > label {
    margin: 9px 0;
}

.clearButton {
    padding-left: 0 !important;
    font-weight: 600 !important;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.selectOption {
    margin-left: unset !important;
}

.labelContainer svg {
    width: 20px;
    height: 20px;
}

.labelContainer:hover {
    cursor: pointer;
}

.label {
    margin-left: .5rem;
}

.removeIcon{
    margin-right: .5rem;
}

.containerLoading {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.clearAll > span:nth-child(2){
    margin-left: .5rem;
}

.results {
    margin-right: .5rem;
}

.display {
    display: block !important;
}

.listLarge {
    display: none !important;
}

.listLarge h1 {
    margin-top: 1.5rem;
}

.listSmall {
    margin-top: 0 !important;
}

.listSmall > div > div:nth-child(1) {
    display: none;
}

.listSmall > div > div:nth-child(2) {
    margin-top: 0 !important;
}

.selectAll{
    margin-top: 9px;
    width: 100%;
}

.dropdownOptionsContainer > div:first-child {
    padding-top: 9px;
    padding-bottom: 9px;
}

.dropdownOptionsContainer > div:nth-child(2) {
    padding-top: 0;
    padding-bottom: 0;
}

@media (min-width: 576px) {
    .cardContainer:nth-child(2n) {
        margin-left: calc(100%/12);   
    }
}

@media (min-width: 710px) {

    .suffixContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .searchInputRow {
        margin-top: 48px !important;
    }

    .divider {
        width: 100vw;
        margin-left: -50px;
    }

    .cardContainer {
        margin-left: 0 !important;   
    }

    .cardContainer:nth-child(2n + 1) {
        margin-left: 0;   
    }

    .cardContainer:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2) !important;   
    }
}
@media (min-width: 992px) {

    .cardContainer:not(:nth-child(3n + 1)) {
        margin-left: 0 !important;   
    }

    .cardContainer:not(:nth-child(4n + 1)) {
        margin-left: calc(100%/6/3) !important;   
    }
}

@media (min-width: 1024px) {
    .filter {
        display: none !important;
    }

    .listLarge {
        display: block !important;
    }

    .listSmall {
        display: none !important;
    }

    .cardListContainer {
        margin-top: 4rem !important;
    }
}

@media screen and (min-width: 769px) {
    .divider {
        margin-left: -90px;
    }
}

@media screen and (min-width: 1350px) {
    .divider {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}



