.container {
    background-color: #FFFFFF;
    padding: 30px 30px 35px 35px !important;
    margin: 0;
}

.container-column {
    padding-right: 30px; 
    padding-bottom: 25px;
}

.container-column:nth-child(3n) {
    padding-bottom: 25px;
    padding-right: 0px;
}

.appItemWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.appItemWrap > img {
    max-width: 40px;
    max-height: 40px;
    width: 25px;
    height: 25px;
    margin-bottom: 15px;
    transition: ease-in-out opacity 0.3s;
}

.appItemWrap > span {
    font-family: 'GT-America';
    text-align: center;
    line-height: 18px
}

.cardContainer{
    margin-bottom: 25px !important;
    cursor: pointer;
}

.cardContainer:hover img{
    opacity: 0.6;
}

.cardContainer:hover span{
    text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.listWrap{
    padding: 25px 30px 5px !important;
    background: white;
}