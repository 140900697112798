.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
}

.titleUnselected {
    color: var(--color-light-gray-2) !important;
}

.container > div {
    margin: .5rem 0;
}

.container p {
    margin: 0;
    font-family: "DomaineDisplay";
    padding: 0.5rem 0 0.25rem 0;
}

.dropdownRow {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
}

.dropdownRow > div:first-child {
    max-width: 90%;
    flex: 1;
}

.dropdownRowDisabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.overlay {
    width: min-content;
    max-height: 300px;
}



.overlay > div {
    background-color: var(--color-white);
    margin-top: -4px;
    flex-direction: column;
    border: none !important;
    overflow: auto;
    max-height: 300px;
}

.overlay > div > label > span:first-child {
    display: none;
} 

.overlay label {
    border-bottom: 1px solid var(--color-light-gray);
    height: 52px;
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
}

.overlay label span {
    border: none !important;
}

.overlay label > span {
    line-height: 20px;
    width: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .overlay label:hover {
        background-color: var(--color-selection);
    }
  }