.wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.modal {
    top: 0 !important;
    width: 100vw !important;
    max-width: 500px;
    padding-bottom: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    max-height: calc(100vh - 20px);
}

.modal>div {
    border-radius: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modal>div>div {
    border-radius: 0;
    border: none;
}

.modal>div>button {
    height: 54px;
    width: 54px;
    top: 0;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    z-index: 10000;
}

.modal>div>button:hover svg {
    background-color: rgb(238, 238, 238, .4);
    border-radius: 50%;
}

.modal>div>button>span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100% !important;
}

.modal>div>div {
    width: 100%;
}

.modal>div>div:nth-child(2) {
    position: sticky;
    top: 0;
    max-width: 500px;
    width: calc(100% - 54px);
    order: -1;
    background-color: var(--color-white);
    z-index: 1000;
}

.modal>div>div:nth-child(2) div {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 14px;
    color: var(--color-secondary);
}

.modal>div>div:last-child {
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 3rem;
}

.modal>div>div button {
    background-color: var(--color-black);
    margin: 0 !important;
    border: none;
    border-radius: 0;
    width: 50%;
    font-family: "GT-America";
    font-weight: 500;
    height: 100%;
    transition: none;
    padding: 0;
}

.modal>div>div button:hover,
.modal>div>div button:focus {
    background-color: var(--color-black);
}

.modal>div>div button:first-child {
    color: #9A9A9A;
}

@media (min-width: 500px) {
    .modal>div>button {
        right: calc(50% - 250px);
        border: none;
    }
}

@media (min-width: 420px) {
    .wrapper {
        align-items: center;
    }
    .modal>div>div:last-child {
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        margin-bottom: 1rem;
    }
    .modal>div>div button {
        width: 100px;
    }
    .modal>div>div button:first-child {
        background-color: var(--color-white);
        color: var(--color-black);
    }
}

@media (max-width: 419px) {
    .modal>div>div button {
        position: fixed;
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;
        bottom: 0px;
    }
    .modal>div>div button:not(:first-child) {
        left: 50%;
    }
    .modal>div>div button {
        position: fixed;
    }
}