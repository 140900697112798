.seeMoreButton:hover {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}

.mobileScrollEndMessage {
    justify-content: center !important;
    font-weight: 600 !important;
    font-family: "DomaineDisplay";
}

.cardListContainerLoading {
    pointer-events: none;
    opacity: 0.6;
}

.cardListContainer {
    margin: 2rem auto 40px auto !important;
}

.searchInputContainer {
    width: 100%;
    margin-top: 7px;
}

.searchInputContainer > .searchInput {
    height: 50px;
    padding-right: unset;
    padding-left: 15px;
}

.searchInput input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 20px !important;
}

.searchInput input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.searchInput :global(.ant-input-prefix) {
    margin-right: unset;
}

.searchInputIcon {
    width: 20px;
    height: 20px;
    margin-right: 18px;
    margin-left: 10px;
}


.filter {
    margin-top: 30px !important;
}

.filter > div:nth-child(2) span{
    font-family: "GT-America" !important;
    font-weight: 300 !important;
    font-size: 12px !important;
}

.dropdownContainer {
    margin-bottom: 18px;
}

.overlay > div {
    padding: 0;
    margin-top: -4px;
}

.overlay *:hover {
    background-color: var(--color-white) !important;
}

.overlay :global([ant-click-animating-without-extra-node='true']::after),
.overlay :global(.ant-click-animating-node) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow:none;
    opacity: 1;
    animation: none;
    animation-fill-mode: none;
    pointer-events: none;
}

.dropdownRow {
    border-bottom: solid 1px var(--color-black);
}

.dropdownRow h1 {
    margin-bottom: 6px;
    font-weight: 500 !important;
}

.checkboxGroup > label {
    margin: 9px 0;
}

.clearButton {
    padding-left: 0 !important;
    font-weight: 600 !important;
}

.labelContainer {
    display: flex;
    align-items: center;
}

.labelContainer svg {
    width: 20px;
    height: 20px;
}

.labelContainer:hover {
    cursor: pointer;
}

.label {
    margin-left: .5rem;
}

.removeIcon{
    margin-right: .5rem;
}

.clearAll > span:nth-child(2){
    margin-left: .5rem;
}

.results {
    margin-right: .5rem;
}

.display {
    display: block !important;
}

.listLarge {
    display: none !important;
}

.listLarge h1 {
    margin-top: 40px;
}

.listSmall {
    margin-top: 0 !important;
}

.listSmall > div > div:nth-child(1) {
    display: none;
}

.listSmall > div > div:nth-child(2) {
    margin-top: 0 !important;
}

@media (min-width: 710px) {

    .suffixContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .pagination > ul {
        display: flex;
        justify-content: flex-end;
        margin-right: -15px !important;
    }
}

@media (min-width: 1024px) {
    .filter {
        display: none !important;
    }

    .listLarge {
        display: block !important;
    }

    .listSmall {
        display: none !important;
    }

    .cardListContainer {
        margin-top: 4rem !important;
    }
}