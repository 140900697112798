.subTitle, .subTitle p{
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 400 !important;
    font-family: 'DomaineDisplay';
    margin-top: -15px;
    cursor: pointer;
    text-decoration-color: transparent;
    transition: ease-in-out text-decoration-color 300ms, color 300ms;
}

.selected, .selected p{
    color: var(--color-black);
    text-decoration-line: underline;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 13px;
    text-decoration-color: var(--color-black);
}

.notSelected, .notSelected p{
    color: var(--color-tertiary);
}

.notSelected:hover, .notSelected:hover p {
    color: var(--color-black);
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 13px;
    text-decoration-color: var(--color-tertiary);
}

.disabled, .disabled p{
    opacity: 0.5;
    cursor:not-allowed;
}