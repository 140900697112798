.event {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-family: "GT-America" !important;
    font-size: 9px;
    overflow: hidden;
}

.event * {
    display: block;
    font-size: 10px;
    width: 100%;
    height: 100%;
}

.overlay {
    background-color: var(--color-white);
    height: 32px;
    font-size: 10px;
    border-radius: 2px;
    /* display: flex;
    align-items: center; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.overlay :global(.ant-tooltip-content) {
    padding: 6px;
    height: 32px !important;
}

.overlay :global(.ant-tooltip-inner) {
    height: 100% !important;
    min-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay :global(.ant-tooltip-inner::before) {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
}

.identifier {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
}

.internalEvent  {
    background-color: var(--color-success);
}

.externalEvent  {
    background-color: var(--color-warning);
}

.training {
    background-color: #AEA2CD;
}

.tooltipLayer > * {
    display: block;
}

.tooltipLayer {
    z-index: 2;
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.tooltipLayer > span {
    font-size: 12px;
}

.hasOpacity {
    opacity: .7;
}