.container {
    margin-top: 57px;
    min-height: calc(100vh - 57px - 192px - 50px);
}

.container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.paragraph {
    font-size: 40px;
    color: var(--color-black);
    font-weight: 500 !important;
}

.logout {
    text-decoration: none;
    color: var(--color-black);
    font-size: 12px;
    font-weight: 500;
    border: 2px solid var(--color-black);
    padding: 5px 18px;
    margin-top: 30px;
    transition: none;
}

.logout:hover {
    text-decoration: none;
    background-color: var(--color-black);
    color: var(--color-white);
}

@media (min-width: 574px) {
    .container {
        min-height: calc(100vh - 50px - 170px - 50px - 7px);
    }
}

@media (min-width: 768px) {
    .container {
        min-height: calc(100vh - 158px - 50px - 57px);
    }
}

@media (min-width: 890px) {
    .container {
        min-height: calc(100vh - 136px - 50px - 50px - 7px);
    }
}