
.container {
    width: 100%;
}

.container > div:not(:first-child) {
    margin-top: 15px;
}

.descriptionContainer {
    margin-top: 24px !important;
}

.title {
    margin-top: 1rem;
}

.icon {
    justify-content: center;
    display: flex !important;
    align-items: flex-start;
    margin-top: 4px;
}

.icon svg {
    stroke: var(--color-black);
    width: 20px;
    height: 20px;
}

.info {
    font-family: "GT-America";
    font-weight: 400;
    color: var(--color-tertiary) !important;
}

.clickableInfo {
    cursor: pointer;
}

.discount {
    margin-top: calc(3rem + 1px) !important;
}

.discount > div:last-child span {
    font-size: 40px !important;
    color: var(--color-success) !important;
    line-height: 28px;
}

.discountText > div:last-child span {
    display: block;
    font-size: 20px !important;
}

.description {
    font-family: "GT-America";
    font-weight: 300;
    color: var(--color-secondary) !important;
}

.discount > .statisticText {
    font-size: 30px !important;
}

.linkInfo {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (min-width: 420px){
    .container {
        width: 48%;
    }

    .container:nth-child(2) {
        margin-left: 4%;
    }

    .discount {
        margin-top: .3rem !important;
        align-items: flex-start;
    }

    .discount > div:nth-child(2) {
        padding-left: .5rem;
    }

    .discountText {
        margin-top: 0 !important;
    }

    .icon {
        margin-top: 0.25rem;
    }
}