.sectionHeader {
    font-size: 22px;
    color: black;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D5D5D5;
}

.sectionItemsContainer {
    margin-bottom: 25px;
}

@media screen and (min-width: 768px) {

    .sectionHeader {
        font-size: 26px !important;
    }

}