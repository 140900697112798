.container {
    padding-bottom: 60px;
}

.spinner {
    margin-top: 72px !important;
}

/* BANNER */
.banner {
    height: 300px;
    width: 100vw;
    position: absolute;
    padding: 0 !important;
    max-width: none !important;
    overflow: hidden;
}

.banner img {
    position: absolute;
    width: 800px;
    height: 600px;
    left: -140px;
    top: -225px;
}

.sectionLine{
    margin-top: 100px !important;
}

@media only screen and (min-width: 376px){
    .banner {
        background-position: -75px;
    }
}

@media only screen and (min-width: 447px) {
    .banner img {
        left: -75px;
    }
}

@media only screen and (min-width: 481px) {
    .banner img {
        top: -150px
    }
}

@media only screen and (min-width: 525px) {
    .banner img {
        top: -175px
    }
}

@media only screen and (min-width: 622px) {
    .banner img {
        top: -250px;
        width: 1200px
    }
}

@media (min-width: 769px) {
    .banner img {
        left: -1vw;
        top: 0;
        width: 110vw;
        height: 400px;
        top: -50px;
    }
}

@media (min-width: 839px) {
    .banner img {
        top: -50px;
    }
}

@media (min-width: 928px) {
    .banner img {
        height: 350px;
        left: -5vw;
    }
}

@media (min-width: 1024px) {
    .banner img {
        left: 0px;
        width: 100%;
        height: 1000px;
        top: -375px
    }

    .filterIconMobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .banner {
        height: 400px;
    }
    .banner img {
        height: 800px;
        width: 1300px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1300px) {
    .banner img {
        height: 800px;
        width: 1400px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1400px) {
    .banner img {
        height: 800px;
        width: 1500px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1500px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 700px;
        width: 1600px;
        top: -200px;
        left: -20px;
    }
}

@media (min-width: 1600px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 800px;
        width: 1700px;
        top: -290px;
        left: -20px;
    }
}

@media (min-width: 1700px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 900px;
        width: 1900px;
        top: -350px;
        left: -20px;
    }
}

@media (min-width: 1880px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 1200px;
        width: 2000px;
        top: -500px;
        left: -20px;
    }
}

@media (min-width: 1981px) {
    .banner img {
        height: calc(100vw/1.7);
        width: calc(100vw + 200px);
        top: calc((100vw/3.5)*-1);
        left: calc(100vw/100*-3)
    }
}
