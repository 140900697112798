.container {
    margin-top: 100px !important;
    display: block;
}

.container > div > div >:global(.ant-dropdown-trigger) {
    border-bottom: 2px solid var(--color-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.dropdownTitle {
    font-weight: 400;
    display: block;
    font-size: 30px;
    font-family: 'DomaineDisplay';
}

.overlay {
    background-color: var(--color-white);
    padding: 0;
    margin-top: -4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.overlay > div > label {
    padding: 14px 0;
    width: 100%;
    margin-right: 0;
}

.overlay > div > label:hover {
    background-color: #F0F0F0;
}

.overlay label > span {
    padding: 0;
}

.overlay > div > label > :global(.ant-radio){
    display: none;
}

.radioGroup {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: -4px;
    background-color: var(--color-white);
}

.radioGroup > * {
    padding-left: 10px !important;
}

.eventsList {
    margin-top: 0px;
}

.dayContainer {
    margin-top: 30px;
}

.dayContainer > span:first-child {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-secondary) !important;
    font-size: 14px !important;
    margin-bottom: 5px;
    display: block;
}

.card {
    display: flex;
    border: 1px solid rgb(0, 0 ,0 , 20%);
    padding: 15px 0;
    cursor: pointer;
}


.card:not(:nth-child(2)) {
    margin-top: -1px;
    border-top: 1px solid rgb(0, 0 ,0 , 0);
}

.card:hover {
    border: solid 1px var(--color-black);
}

.card > div {
    display: flex;
    align-items: center;
    margin-right: 12px;
    width: 75px;
    justify-content: flex-end;
}

.card > span {
    white-space: nowrap;
    max-width: calc(100% - 75px - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.card > div > span {
    font-weight: 500;
}

.category {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;
}

.tick {
    display: flex;
    width: 24px !important;
    height: 24px !important;
    border: solid 1px var(--color-black);
    border-radius: 50%;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 0 !important;
    margin-left: 12px;
}

.noResultsContainer{
    margin-top: 25px;
}

.noResults,
.seeMore,
.listEnd {
    margin-top: 40px;
}

.noResults span,
.listEnd span {
    color: var(--color-secondary) !important;
}

.seeMore button {
    width: 106px;
    height: 32px;
    font-weight: 500;
    font-size: 13px !important;
}

.containerLoading {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.calendarMenu {
    margin-top: 60px;
}

.calendarContainer {
   margin-top: 30px;
}

@media (min-width: 768px) {
    .container {
        display: flex;
        flex-wrap: wrap;
    }
    
    .container > div:first-child{
        order: 1;
    }
    
    .container > div:nth-child(2){
        order: 3;
    }
    
    .container > div:nth-child(3){
        order: 5;
    }
    
    .container > div:nth-child(4){
        order: 2;
    }
    
    .container > div:nth-child(5){
        order: 4;
    }

    .eventsList {
        height: 607px;
        overflow-y: scroll;
    }

    .divider > div {
        margin: 0;
        border-top: #DDD solid 1px;
    }

    .calendarMenu {
        margin-top: 0px;
    } 
}