@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap);
.pageContainer_pageContainer__14G-A {
    background-color: var(--color-white);
    width: 100vw;
}

.pageContainer_pageContainer__14G-A > div {
    width: 100%;
}

.pageContainer_pageContainer__14G-A > div > div {
    padding: 0 20px;
    max-width: var(--main-container-max-width);
    margin-left: auto;
    margin-right: auto;
}

.pageContainer_pageContainer__14G-A * {
    box-sizing: border-box;
}

@media screen and (min-width: 481px) {
    .pageContainer_pageContainer__14G-A > div > div {
        padding: 0 50px;
    }
}

@media screen and (min-width: 768px) {
    .pageContainer_pageContainer__14G-A > div > div {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 90px;
        padding-right: 97px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .pageContainer_pageContainer__14G-A > div > div {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 90px;
        padding-right: 97px;
        max-width: none;
    }
}

@media screen and (min-width: 1350px) {
    .pageContainer_pageContainer__14G-A > div > div {
        padding: 0px;
        padding-right: 7px;
        max-width: var(--main-container-max-width);
    }
}
.paragraph_text__1h7Ih {
    font-size: 16px;
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    color: var(--color-secondary);
}

@media (min-width: 481px) {
    .paragraph_text__1h7Ih {
        font-size: 20px;
        line-height: 26px;
    }
}

@media (min-width: 769px) {
    .paragraph_text__1h7Ih {
        font-size: 26px;
        line-height: 34px;
    }
}

@media (min-width: 1025px) {
    .paragraph_text__1h7Ih {
        font-size: 26px;
    }
}
.accessDenied_container__2VAZm {
    margin-top: 57px;
    min-height: calc(100vh - 57px - 192px - 50px);
}

.accessDenied_container__2VAZm > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.accessDenied_paragraph__2zKhN {
    font-size: 40px;
    color: var(--color-black);
    font-weight: 500 !important;
}

.accessDenied_logout__1R3fx {
    text-decoration: none;
    color: var(--color-black);
    font-size: 12px;
    font-weight: 500;
    border: 2px solid var(--color-black);
    padding: 5px 18px;
    margin-top: 30px;
    transition: none;
}

.accessDenied_logout__1R3fx:hover {
    text-decoration: none;
    background-color: var(--color-black);
    color: var(--color-white);
}

@media (min-width: 574px) {
    .accessDenied_container__2VAZm {
        min-height: calc(100vh - 50px - 170px - 50px - 7px);
    }
}

@media (min-width: 768px) {
    .accessDenied_container__2VAZm {
        min-height: calc(100vh - 158px - 50px - 57px);
    }
}

@media (min-width: 890px) {
    .accessDenied_container__2VAZm {
        min-height: calc(100vh - 136px - 50px - 50px - 7px);
    }
}
.h3_title__1T6wl{
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 30px !important;
}
.headerTabs_tab2__2DJ81{
    padding-left: 9px;
    height: 30px;
    line-height: 28px;
    width: 165px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.headerTabs_tab2__2DJ81 > .headerTabs_title__9t9Oc{
    font-size: 16px !important;
    margin: 0;
    padding: 0;
    line-height: 27px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1;
}

.headerTabs_tabWhite__2_EwU{
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
}

.headerTabs_tabWhite__2_EwU .headerTabs_title__9t9Oc{
    color: var(--color-black) !important;
}

.headerTabs_tabBlack__1CnVb{
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
}

.headerTabs_tabBlack__1CnVb .headerTabs_title__9t9Oc{
    color: var(--color-white) !important;
}

.headerTabs_iconWrap__HX-Vb{
    width: 44px;
    height: 28px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 3px;
}

.headerTabs_closeWrap2__MfeXO > svg{
    width: 14px;
    height: 14px;
    margin-top: 8.5px;
    margin-right: 6px;
}

.headerTabs_closeWrap2__MfeXO > svg > path{
    stroke: var(--color-medium-gray) !important;
}

.headerTabs_tabBlack__1CnVb .headerTabs_closeWrap2__MfeXO{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.headerTabs_tabWhite__2_EwU .headerTabs_closeWrap2__MfeXO{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.headerTabs_tabBlack__1CnVb .headerTabs_pinWrap__1QQut{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.headerTabs_tabWhite__2_EwU .headerTabs_pinWrap__1QQut{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.headerTabs_pinWrap__1QQut > svg{
    width: 20px !important;
    height: 20px !important;
    margin-top: 4px !important;
    margin-right: 5px !important;
    fill: var(--color-medium-gray) !important;
}

.headerTabs_pinWrap__1QQut > svg > path{
    stroke: var(--color-medium-gray) !important;
}



.headerTabs_tabWhite__2_EwU:hover{
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
}

.headerTabs_tabWhite__2_EwU:hover .headerTabs_title__9t9Oc{
    color: var(--color-white) !important;
}

.headerTabs_tabBlack__1CnVb:hover{
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
}

.headerTabs_tabBlack__1CnVb:hover .headerTabs_title__9t9Oc{
    color: var(--color-black) !important;
}

.headerTabs_tabBlack__1CnVb:hover .headerTabs_closeWrap2__MfeXO{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.headerTabs_tabWhite__2_EwU:hover .headerTabs_closeWrap2__MfeXO{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.headerTabs_tabBlack__1CnVb:hover .headerTabs_pinWrap__1QQut{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.headerTabs_tabWhite__2_EwU:hover .headerTabs_pinWrap__1QQut{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.headerTabs_tabBlack__1CnVb .headerTabs_closeWrap2__MfeXO:hover > svg > path{
    stroke: var(--color-black) !important;
}

.headerTabs_tabWhite__2_EwU .headerTabs_closeWrap2__MfeXO:hover > svg > path{
    stroke: var(--color-white) !important;
}

.headerTabs_tabBlack__1CnVb .headerTabs_pinWrap__1QQut:hover > svg{
    fill: var(--color-black) !important;
}

.headerTabs_tabBlack__1CnVb .headerTabs_pinWrap__1QQut:hover > svg > path{
    stroke: var(--color-black) !important;
}

.headerTabs_tabWhite__2_EwU .headerTabs_pinWrap__1QQut:hover > svg{
    fill: var(--color-white) !important;
}

.headerTabs_tabWhite__2_EwU .headerTabs_pinWrap__1QQut:hover > svg > path{
    stroke: var(--color-white) !important;
}



.headerTabs_dropdown__2ggYk{
    width: 30px;
    min-width: 30px;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin-right: 2.5px;
    margin-left: 2.5px;
}

.headerTabs_dropdown__2ggYk > span{
    font-size: 13px;
    color: var(--color-white);
}

.headerTabs_dropdown__2ggYk:hover > span{
    color: var(--color-black);
}

.headerTabs_menu__2i1FQ{
    padding: 0 !important;
    background-color: var(--color-black) !important;
}

.headerTabs_menu__2i1FQ > li{
    padding: 0;
}
.profile_profile__1jZvq {
    height: 32px;
    width: 32px;
    background-color: #A0C6D2;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 32px;
}

.profile_profile__1jZvq span {
    font-weight: 600;
}

.profile_profilePicture__7lZNo img {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.profile_profilePicture__7lZNo {
    line-height: 22px;

}
.lightTextHeader .profile_profilePicture__7lZNo {
    filter: invert(1);
}
.spinner_spin__3Qv1E {
    animation: spinner_icon-spin__2IiIA 1.5s infinite linear;
    stroke: var(--color-green);
    fill: black;
}

.spinner_fullHeight__iuz6o{
    align-self: center;
    height: 1.5rem !important;
    width: 1.5rem !important;
    flex: 1 1;
}

.spinner_large__1XUrm {
    font-size: 22px !important;
}
 
 @keyframes spinner_icon-spin__2IiIA {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
 }

.b2_body__3TxFJ {
    color: var(--color-black) !important;
    font-size: 14px !important;
    line-height: 20px !important;
}
.appList_container__1Z0dY {
    background-color: #FFFFFF;
    padding: 30px 30px 35px 35px !important;
    margin: 0;
}

.appList_container-column__hLhx7 {
    padding-right: 30px; 
    padding-bottom: 25px;
}

.appList_container-column__hLhx7:nth-child(3n) {
    padding-bottom: 25px;
    padding-right: 0px;
}

.appList_appItemWrap__2a9ig{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.appList_appItemWrap__2a9ig > img {
    max-width: 40px;
    max-height: 40px;
    width: 25px;
    height: 25px;
    margin-bottom: 15px;
    transition: ease-in-out opacity 0.3s;
}

.appList_appItemWrap__2a9ig > span {
    font-family: 'GT-America';
    text-align: center;
    line-height: 18px
}

.appList_cardContainer__1gsdP{
    margin-bottom: 25px !important;
    cursor: pointer;
}

.appList_cardContainer__1gsdP:hover img{
    opacity: 0.6;
}

.appList_cardContainer__1gsdP:hover span{
    -webkit-text-decoration: underline solid black from-font;
            text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.appList_listWrap__3pOqN{
    padding: 25px 30px 5px !important;
    background: white;
}
#root>.header_header__2S0IO {
    background: transparent;
    border-bottom: 1px solid black;
    height: var(--header-height);
}

.header_headerGrid__3gWBR {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    height: 100%;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--main-container-max-width);
    padding: 0 20px;

}

.header_menuGrid__268yy {
    display: grid;
    grid-template-columns: 20px 100px;
    align-items: center;
    justify-content: start;
    grid-gap: 20px;
    gap: 20px;
}

.header_appsGrid__2YPuG {
    display: grid;
    grid-template-columns: 20px 20px 32px;
    align-items: center;
    justify-content: end;
    grid-gap: 20px;
    gap: 20px;
}

.header_appsGrid__2YPuG .header_icon__S97Zk:hover {
    opacity: 0.5;
}

.header_icon__S97Zk {
    cursor: pointer;
}

.header_notificationIcon__6mF9X {
    width: 20px;
    height: 20px;
}

.header_search__2MRCi {
    display: flex;
    cursor: pointer;
}

.header_apps__dYqfh {
    display: none;
}

.header_logo__3yiHD {
    width: 100px;
    /*margin-left: 20px;*/
}

.header_end__1QpdW {
    justify-self: end;
}

.header_start__mln4U {
    justify-self: start;
}

.header_dropdown__3_hCJ {
    top: 60px !important;
    z-index: 10000;
    /* Workaround to fix 4px on left */
    /* width: 101%; */
    width: 100%;
    margin-top: -2px !important;
    position: fixed !important
}

.header_dropdown__3_hCJ ul li {
    padding-left: 20px;
    padding-right: 20px;
}

.header_settings__23C8h ul li {
    text-align: right;
}

.header_dropdown__3_hCJ ul li span {
    font-size: 16px;
    font-style: normal;
    line-height: 19px;
    letter-spacing: 0em;
}

.header_dropdown__3_hCJ ul li:hover {
    background-color: var(--color-medium-gray) !important;
    cursor: pointer;
}

.header_appsDropdown__WDyJ_ {
    position: fixed !important;
    top: var(--header-height) !important;
    z-index: 10000;
    max-width: 400px;
    border-right: 1px solid var(--color-medium-gray);
    border-left: 1px solid var(--color-medium-gray);
    border-bottom: 1px solid var(--color-medium-gray);
}

.header_dropdown__3_hCJ .header_menuContainer__1JgDA {
    position: relative;
    height: calc(100vh - 57px);

    background-color: #f4f4f4;
}

.header_overflowActive__8RKmq {
    overflow-y: scroll;
}

.header_dropdown__3_hCJ .header_menu__2XXle {
    background-color: #f4f4f4;
}

.header_noNotification__CVVSm {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

.header_noNotification__CVVSm>span {
    color: var(--color-secondary) !important;
}

.header_noscroll__2vZJX {
    overflow: hidden;
}

.header_burger__139gg {
    width: 16px;
    height: 16px;
    margin-right: 0;
}

.header_desktop__1u3Oq {
    display: none;
}

.header_mobile__vBmFu {
    display: flex;
}

.header_sidebarOpen__2bNe5 {
    background-color: var(--color-white) !important;
}

.header_sidebarOpen__2bNe5>.header_headerGrid__3gWBR:last-child {
    opacity: 0 !important;
    width: 50% !important;
}

.header_searchbarGrid__3kDxw {
    display: none;
}

.header_focusedSearchbarHeader__3E9uF>.header_headerGrid__3gWBR>.header_searchbarGrid__3kDxw {
    display: grid;
    grid-template-columns: auto 45px;
}

.header_searchBar__10HQe {
    display: flex;
}

.header_searchBar__10HQe .ant-input-prefix {
    margin-right: 16px !important;
}

.header_searchBar__10HQe .ant-input-affix-wrapper {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.header_searchBar__10HQe .ant-input-affix-wrapper:focus,
.header_searchBar__10HQe .ant-input-affix-wrapper:hover {
    box-shadow: none !important;
    border: none !important;
}

.header_searchBar__10HQe input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 22px;
    color: black;
    background-color: transparent !important;
}

.header_searchBar__10HQe input:-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.header_searchBar__10HQe input::placeholder {
    color: var(--color-light-gray-2);
}

.header_searchBar__10HQe input::-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.header_focusedSearchbarHeader__3E9uF>.header_headerGrid__3gWBR {
    grid-template-columns: auto !important;
}

.header_focusedSearchbarHeader__3E9uF>div>.header_menuGrid__268yy {
    display: none !important;
}

.header_focusedSearchbarHeader__3E9uF>div>.header_burger__139gg {
    display: none !important;
}

.header_focusedSearchbarHeader__3E9uF>div>.header_apps__dYqfh {
    display: none !important;
}

.header_focusedSearchbarHeader__3E9uF>div>.header_appsGrid__2YPuG {
    display: none !important;
}

.header_close__27Aeo {
    align-self: center;
    width: 20px;
    height: 20px;
    cursor: pointer;
    justify-self: flex-end;
}

.header_close__27Aeo path {
    stroke: var(--color-light-gray-2);
}

.header_close__27Aeo:hover path {
    stroke: black;
}

.header_searchbarDropdownChevron__2p3MC {
    margin-right: 4px;
}

.header_focusedSearchbarHeader__3E9uF {
    filter: unset !important;
    transition: 0.1s linear;
    background-color: var(--color-white) !important;
    border-bottom: 1px solid var(--color-secondary) !important;
}

.header_searchOverlay__LdA3l {
    transition: visibility 0s, opacity 0.2s linear;
    position: fixed;
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    z-index: 2;
}

.header_displaySearchOverlay__28DnT {
    visibility: visible;
    opacity: 1;
}

.header_searchbarDropdown__1joys {
    top: 119px !important;
    z-index: 100000 !important;
    position: fixed !important;
}

.header_searchbarDropdown__1joys .ant-dropdown-menu {
    padding: 5px 0 !important;
}

.header_searchbarDropdown__1joys .ant-dropdown-menu-item {
    color: var(--color-secondary);
    background-color: white !important;
    padding: 8px 12px 8px 20px !important;
}

.header_searchbarDropdown__1joys .ant-dropdown-menu-item>span {
    color: var(--color-secondary);
}

.header_searchbarDropdown__1joys .ant-dropdown-menu-item-selected>span {
    color: black
}

.header_searchbarDropdown__1joys .ant-dropdown-menu-item-active>span {
    color: black
}

.header_searchbarDropdownTitle__nKDKD {
    line-height: 60px;
    font-family: "GT-America";
    margin-right: 20px;
    margin-left: 5px;
    text-align: center;
    min-width: 120px;
    font-size: 16px !important;
    color: var(--color-light-gray-2);
}

.header_menuItem__2dVRR {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}


.lightTextHeader .header_searchbarDropdownTitle__nKDKD {
    color: white;
}

.lightTextHeader .header_searchbarDropdownRow__1V5Xf svg path {
    fill: white !important;
}

.lightTextHeader .header_searchbarDropdownRow__1V5Xf {
    filter: invert(1);
}

.lightTextHeader .header_searchBar__10HQe input:-ms-input-placeholder {
    filter: invert(1);
    color: white;
}

.lightTextHeader .header_searchBar__10HQe input::placeholder {
    filter: invert(1);
    color: white;
}

.lightTextHeader .header_searchBar__10HQe input::-ms-input-placeholder {
    filter: invert(1);
    color: white;
}

.profilePicture {
    filter: invert(0)
}

.header_focusedSearchbarHeader__3E9uF .header_searchbarDropdownTitle__nKDKD {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__3E9uF .header_searchbarDropdownRow__1V5Xf {
    filter: unset !important;
}

.header_focusedSearchbarHeader__3E9uF .header_searchBar__10HQe input:-ms-input-placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__3E9uF .header_searchBar__10HQe input::placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__3E9uF .header_searchBar__10HQe input::-ms-input-placeholder {
    filter: unset !important;
    color: var(--color-light-gray-2) !important;
}

.header_focusedSearchbarHeader__3E9uF .header_searchbarDropdownRow__1V5Xf svg path {
    fill: black !important;
}


.header_searchbarDropdownRow__1V5Xf {
    border-bottom: #D5D5D5 0.75px solid;
    justify-content: space-between !important;
    cursor: pointer;
}

.header_searchContainer__cH_xx {
    padding-left: 20px;
    padding-right: 20px;
}

.header_noResultsMesage__QFwm0 {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px !important;
}

.header_centerSpinner__3Cj6W {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

.header_searchResultTitle__53-k8 {
    font-family: "DomaineDisplay";
    font-size: 18px;
    font-weight: 400;
    line-height: 26px !important;
    color: black;
    margin-right: 5px;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.header_searchResultTitleClickable__30YNX {
    cursor: pointer;

}

.header_searchResultTitleClickable__30YNX:hover {
    -webkit-text-decoration: underline solid var(--color-black) from-font;
            text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.header_searchResultType__1HUhF {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px !important;
    color: var(--color-light-gray-2);
}

.header_searchResultsItem__1Ulky {
    align-items: flex-end;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin-bottom: 15px;
    flex-wrap: nowrap !important;
}

.header_containerLoading__3nofM {
    pointer-events: none;
    opacity: 0.5;
}

.header_searchResultsContainer__3CTP8 {
    margin-top: 20px;
    height: 100vh;
    overflow: auto;
}

.header_searchResultTitleHighlight__1GRFX {
    background-color: black;
    color: white;
    padding: unset !important;

}

.header_hideSearchIcon__mkcuL {
    pointer-events: none;
    opacity: 0;
}

.header_seeAllCategories__AMoAy {
    height: 60px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid var(--color-disabled);
    background-color: var(--color-white);
}

.header_seeAllCategories__AMoAy>div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}

.header_notificationIconContainer__3rX5M {
    height: 32px;
    display: flex;
    align-items: center;
    position: relative;
}

.header_newNotification__UeQdx {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: var(--color-danger);
    position: absolute;
    right: 0;
    top: 5px;
    z-index: 100;
}

.header_menu__2XXle li:last-child {
    margin-bottom: 60px !important
}

.header_tabsContainer__1Dcbu {
    position: absolute;
    z-index: 1;
    top: 50px;
    display: flex;
    width: 100%;
}

@media screen and (min-width: 481px) {
    .header_logo__3yiHD {
        margin-left: -10px;
    }

    .header_searchContainer__cH_xx {
        padding-left: 50px;
        padding-right: 50px;
    }

    .header_headerGrid__3gWBR {
        padding-left: 50px;
        padding-right: 50px;
        margin: 0 auto;
    }

    .header_menuGrid__268yy {
        display: grid;
        grid-template-columns: 20px 115px;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_menu__2XXle li:last-child {
        margin-bottom: 0px !important
    }
}

@media screen and (min-width: 692px) {
    .header_logo__3yiHD {
        margin-left: -10px;
    }

    .header_menuGrid__268yy {
        display: grid;
        grid-template-columns: 20px 115px;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_appsGrid__2YPuG {
        display: grid;
        grid-template-columns: 20px 20px 32px;
        align-items: center;
        justify-content: end;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_dropdown__3_hCJ {
        top: var(--header-height) !important;
        max-width: 320px;
        max-height: 505px;
        margin-top: 0 !important;
        box-shadow: 10px 10px 25px 0 rgba(0, 0, 0, .1);
    }

    .header_dropdown__3_hCJ>.header_menuContainer__1JgDA {
        max-height: 505px;
        position: relative;
        height: unset;
    }

    .header_menuContainer__1JgDA>.header_menu__2XXle {
        padding-bottom: 0 !important;

    }

    .header_seeAllCategories__AMoAy {
        position: -webkit-sticky;
        position: sticky;
        top: calc(100% - 60px);
    }

    .header_settings__23C8h {
        max-width: 185px;
    }

    .header_settings__23C8h ul li {
        text-align: left;
    }
}

@media screen and (min-width: 768px) {
    .header_logo__3yiHD {
        margin-left: 20pxn;
    }

    .header_searchbarDropdown__1joys {
        top: 50px !important;
    }

    .header_hideSearchbar__2dT-l {
        pointer-events: none;
        border-bottom: 1px black solid !important;
    }

    .header_hideSearchbar__2dT-l div {
        opacity: 0;
    }

    .header_hideSearchbar__2dT-l input {
        display: none;
    }

    .header_focusedSearchbarHeader__3E9uF {
        border-bottom: 1px solid #D2D2D2 !important;
    }

    .header_searchbarGrid__3kDxw {
        display: grid;
        grid-template-columns: 190px auto;
        align-items: center;
        z-index: 1000000;
        height: 100%;
    }

    .header_noResultsMesage__QFwm0 {
        display: unset;
        justify-content: unset;
        margin-left: 65px;
    }

    .header_searchbarDropdownTitle__nKDKD {
        line-height: 34px;
    }

    .header_searchBar__10HQe .ant-input-affix-wrapper {
        padding: 0 0 0 30px !important;
    }

    .header_close__27Aeo {
        justify-self: unset;
        margin-left: 50px;
    }

    .header_searchbarDropdownRow__1V5Xf {
        margin-bottom: unset;
        border-right: var(--color-light-gray-2) 0.75px solid;
        border-bottom: unset;
        justify-content: center !important;
    }

    .lightTextHeader .header_searchbarDropdownRow__1V5Xf {
        border-right: white 0.75px solid;
    }

    .header_focusedSearchbarHeader__3E9uF .header_searchbarDropdownRow__1V5Xf {
        border-right: var(--color-light-gray-2) 0.75px solid !important;
    }

    .header_focusedSearchbarHeader__3E9uF>.header_headerGrid__3gWBR>.header_searchbarGrid__3kDxw {
        border-bottom: 1px black solid !important;
        grid-template-columns: 190px auto 115px 24px !important;
    }

    .header_searchButton__Ktb3d {
        font-family: "GT-America";
        font-size: 14px !important;
        font-weight: 400;
        color: black;
        text-align: end;
        cursor: default;
        opacity: 0;
    }

    .header_displaySearchButton__1XA15 {
        cursor: pointer;
        opacity: 1 !important;
    }

    .header_searchButton__Ktb3d:hover {
        -webkit-text-decoration: underline solid var(--color-black) from-font;
                text-decoration: underline solid var(--color-black) from-font;
        text-underline-offset: 4px;
    }

    .header_searchContainer__cH_xx {
        padding-left: calc(90px - 24px);
        padding-right: calc(90px - 24px);
        width: calc(var(--main-container-max-width) + 24px + 24px);
        grid-template-columns: 190px auto;
        display: grid;
    }

    .header_searchSuggestionsMessage__1ybFj {
        margin: 20px 0 0 0;
        font-family: "GT-America";
        font-size: 14px;
        font-weight: 300;
        line-height: 20px !important;
        text-align: center;
    }

    .header_searchResultsContainer__3CTP8 {
        margin-left: 65px;
    }

    section>.header_header__2S0IO {
        height: 80px;
        padding: 0 40px !important;
    }

    .header_search__2MRCi {
        display: none;
    }

    .header_apps__dYqfh {
        display: flex;
        margin-left: 0;
        margin-right: 0;
    }

    .header_headerGrid__3gWBR {
        padding-left: 90px;
        padding-right: 90px;
        grid-template-columns: 20px 125px 125px auto 15px 7px;
        grid-gap: 0;
        gap: 0;
    }

    .header_appsGrid__2YPuG {
        display: grid;
        grid-template-columns: 20px 15px 20px;
        align-items: center;
        justify-content: end;
        grid-gap: 20px;
        gap: 20px;
    }

    .header_desktop__1u3Oq {
        display: flex;
    }

    .header_mobile__vBmFu {
        display: none;
    }

    .header_burger__139gg {
        width: 20px;
        height: 20px;

    }
}

@media screen and (max-width: 1274px) {
    .header_logo__3yiHD {
        margin-left: 10px;
    }
}

@media screen and (min-width: 1271px) {
    .header_logo__3yiHD {
        margin-left: 20px;
    }

    .header_searchContainer__cH_xx {
        max-width: none;
        padding-right: calc(90px - 24px);
        padding-left: calc(90px - 24px);
    }

    .header_headerGrid__3gWBR {
        margin-left: auto;
        margin-right: auto;
        max-width: var(--main-container-max-width);
        padding: 0 90px;
    }

}

@media screen and (min-width: 1350px) {
    .header_logo__3yiHD {
        margin-left: 20px;
    }

    .header_searchContainer__cH_xx {
        width: calc(var(--main-container-max-width) + 24px + 24px);
        padding-right: 0;
        padding-left: 0;
        margin: 0 auto;
    }

    .header_headerGrid__3gWBR {
        width: calc(var(--main-container-max-width));
        padding-right: unset;
        padding-left: unset;
    }
}
.b1_body__WIqke {
    font-family: "GT-America";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 16px !important;
}
.overline_overline__3wFyj {
    color: var(--color-black) !important;
    font-size: 12px !important;
    line-height: 18px;
}
.notificationCard_menuItem__mn6v0 {
    padding-top: 15px !important;
    height: unset !important;
    margin: 0 !important;
    background-color: #f4f4f4 !important;
}

.notificationCard_menuItem__mn6v0 > span {
    width: 100%;
}

.notificationCard_postType__3VR1_ > div > span,
.notificationCard_eventCategory__1_lGu > span {
    font-size: 11px !important;
    text-transform: uppercase;
    color: var(--color-secondary) !important;
    display: block;
}

.notificationCard_postTitle__NN_W4 {
    margin-top: 5px;
}

.notificationCard_postTitle__NN_W4 > div > span {
    max-width: 100%;
    width: 100%;
    white-space: normal;
    font-family: "DomaineDisplay";
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    white-space: initial;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 21px !important;
}

.notificationCard_date__3mmj6 {
    margin-top: 5px;
}

.notificationCard_date__3mmj6 > div > span {
    color: #9F9F9F !important;
    font-size: 12px !important;
}

.notificationCard_date__3mmj6 > div > .notificationCard_eventDate__Bj3jL {
    color: var(--color-black) !important;
}

.notificationCard_divider__3OTqW {
    margin-bottom: 0 !important;
}

.notificationCard_eventCategory__1_lGu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.notificationCard_eventCategory__1_lGu > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.notificationCard_eventCategory__1_lGu > span {
    display: block;
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notificationCard_menuItem__mn6v0 > span > a {
    text-decoration: none;
}

.notificationCard_menuItem__mn6v0 > span > a:hover {
    text-decoration: none;
}
.footerContent_footer__339YP {
    background: none !important;
    background-color: var(--color-black) !important;
    position: relative;
    background-repeat: no-repeat !important;
}

.footerContent_title__1JQWu h1 {
    color: var(--color-secondary) !important;
    margin-bottom: 1rem !important;
}

.footerContent_title__1JQWu:first-child {
    margin-top: 40px;
}

.footerContent_linksContainer__p5mha span {
    color: var(--color-white) !important;
    font-family: "GT-America";
    font-weight: 300;
    margin-top: 1rem !important;
}

.footerContent_icon__OFS-w path {
    fill: var(--color-white);
}

.footerContent_copyright__wqOkp>span {
    font-size: 12px !important;
    color: var(--color-white) !important;
    font-family: "GT-America";
    font-weight: 300;
}

.footerContent_footerBanner__3A_4r {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.footerContent_footerBanner__3A_4r img {
    position: absolute;
    bottom: 0;
    right: -100px;
    width: 140%
}

@media (min-width:420px) {
    .footerContent_footerBanner__3A_4r img {
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 120%
    }
}

@media (min-width: 768px) {
    .footerContent_icon__OFS-w {
        display: flex !important;
        align-items: center;
    }
    .footerContent_footerBanner__3A_4r img {
        position: absolute;
        bottom: 0;
        right: -160px;
        width: 100%
    }
}

@media (min-width: 992px) {
    .footerContent_copyright__wqOkp {
        margin-left: 30px;
    }
}

@media (min-width: var(--main-container-max-width)) {
    .footerContent_footerBanner__3A_4r img {
        display: none !important;
    }
}
.footer_container__A2HBR {
    background-color: var(--color-black);
    overflow: hidden;
    z-index: 1;
    position: relative;
}

.footer_container__A2HBR>div {
    overflow: hidden !important;
}

.footer_footerBanner__3up0Q {
    display: none;
}

@media (min-width: var(--main-container-max-width)) {
    .footer_footerBanner__3up0Q {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 1000;
        padding: 0 !important;
        right: 0;
    }
    .footer_footerBanner__3up0Q img {
        width: 600px;
    }
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
    content: none !important;
}

.sidebar_side__po0cr.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    border-color: var(--color-black);
}

.sidebar_apps__2W8DC {    
    background-color: var(--color-light-gray);
    margin-bottom: 20px;
}

.sidebar_apps__2W8DC > .pro-inner-item {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.sidebar_apps__2W8DC > .pro-inner-item > .pro-icon-wrapper > .pro-icon {
    justify-content: start !important;
}

.sidebar_apps__2W8DC > .prefix-wrapper {
    width: 10px;
}

.sidebar_appsRow__3HfQl > .pro-inner-item > .pro-item-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sidebar_appsRow__3HfQl > .pro-inner-item > .pro-item-content > svg {
    width: 24px;
    margin-right: 10px
}

.sidebar_side__po0cr.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding: 20px;
}

.pro-item-content {
    font-family: "GT-America";
    font-weight: 400;
    font-size: 16px;
}

.sidebar_side__po0cr {
    top: var(--header-height);
    color: var(--color-black) !important;
    position: fixed !important;
} 

.sidebar_side__po0cr > .pro-sidebar-inner {
    background: var(--color-white) !important;
    box-shadow: 5px 45px 45px rgba(0, 0, 0, 0.1);
}

.sidebar_side__po0cr > div > div > nav.pro-menu {
    padding-top: 0px;
    padding-bottom: 70px;
}

.sidebar_sub__2666G.open > div > span {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.sidebar_sub__2666G > .react-slidedown.pro-inner-list-item {
    background-color: var(--color-white) !important;
}

.sidebar_sideTitle__dyk4m, .sidebar_sub__2666G > div > span {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 18px;
    color: var(--color-black);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset !important;
}

.popper-inner {
    display: none !important;
}

@media screen and (min-width: 769px) {
    .sidebar_side__po0cr > div > div > nav.pro-menu {
        padding-top: 40px;
        padding-left: 40px;
        margin-bottom: 40px;
    }

    .sidebar_side__po0cr.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
        padding: 15px 35px 15px 20px;
    }
}

.backToTop_backTop__2vLP0 {
    display: none;
}

.backToTop_backTop__2vLP0>div>div {
    border: 1px solid white;
}

.backToTop_tooltip__1suiH>div>div:nth-child(2) {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    padding: 12px 10px !important;
    border: 1px solid white;
}

@media (min-width: 1024px) {
    .backToTop_backTop__2vLP0 {
        display: block
    }
}
.h2_title__2-opT{
    font-family: "DomaineDisplay";
    color: var(--color-black) !important;
    font-size: 36px !important;
    line-height: 44px !important;
    font-weight: 500 !important;
    margin-bottom: 25px !important;
}
.pageTitle_title__2wlbR {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    margin-top: 95px;
    margin-bottom: 20px !important;
}

.pageTitle_title__2wlbR *{
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 36px !important;
    line-height: 48px !important;
    margin-top: 95px;
    margin-bottom: 20px !important;
    color: inherit;
}

@media (min-width: 768px) {
    .pageTitle_title__2wlbR {
        font-size: var(--font-size-title-page) !important;
        line-height: var(--line-height-title)!important;
        margin-top: 145px;
        margin-bottom: 20px !important;
    }

    .pageTitle_title__2wlbR *{
        font-size:  var(--font-size-title-page) !important;
        line-height: var(--line-height-title)!important;
        margin-top: 145px;
        margin-bottom: 20px !important;
        color: inherit;
    }
}
.h5_title__1C7VK{
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 20px !important;
}
.kiosk_kioskHeader__20LXg {
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.kiosk_headerLink__1-OBy {
    display: contents;
}

.kiosk_kioskHeaderTitle__2P2yq {
    margin-bottom: unset !important;
    font-weight: 500 !important;
}

.kiosk_kioskHeaderArrow__35uHp {
    display: flex;
    align-items: center;
}

.kiosk_kioskFooter__3VEwf {
    justify-content: flex-end;
}

.kiosk_cardTitle__GY1oX {
    margin-bottom: 0px !important;
    font-weight: 400 !important;
}

.kiosk_newspaperCard__1dhS7 {
    background: rgb(0 0 0 / 0%) !important;
    border: unset !important;
}

.kiosk_kioskCarousel__337Xn > div:nth-child(2) > div > *{
    max-height: 100% !important;
}

.kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img{
    object-fit: contain;
}

.kiosk_newspaperCard__1dhS7 > div:nth-child(2){
    padding: unset;
    margin-top: 15px;
}

.kiosk_newspaperCard__1dhS7:hover .kiosk_cardTitle__GY1oX {
    -webkit-text-decoration: underline solid black from-font;
            text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.kiosk_newspaperCard__1dhS7:hover .ant-card-cover {
    opacity: 0.6;
}

.kiosk_newspaperCard__1dhS7 > div:first-child {
    border: 1px solid #e3e3e3;
}

.kiosk_seeMoreButton__24jC4:hover {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}

.kiosk_cardContainer__3lABZ { 
    margin-bottom: 41px !important; 
    margin-left: unset !important; 
    margin-right: unset !important; 
}

.kiosk_publishedDate__2WXuD {
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
    margin-top: unset !important;
    margin-bottom: 21px !important;
}

.kiosk_kioskHeaderArrowSvg__2y30t {
    margin-left: 1rem;
}

.kiosk_arrowNext__3ckmZ {
    transform: rotate(180deg);
}

.kiosk_tooltip__2LptH{
    margin-top: 6px !important;
}

.kiosk_tooltip__2LptH > div > div:nth-child(2){
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    padding: 12px 10px !important;
    width: 1200px;
}

.kiosk_tooltip__2LptH > div > div:first-child{
    display: none !important;
}

.kiosk_tooltip__2LptH .kiosk_tooltipRow__1YvhL{
    cursor: pointer;
}

.kiosk_tooltip__2LptH .kiosk_tooltipRow__1YvhL > div{
    width: 100%;
}

.kiosk_tooltip__2LptH .kiosk_tooltipRow__1YvhL:last-child {
    margin-top: 10px;
}

.kiosk_tooltip__2LptH .kiosk_labelRow__HVvWi{
    justify-content: space-between;
}

.kiosk_tooltip__2LptH .kiosk_labelRow__HVvWi > .kiosk_label__htXgH{
    color: var(--color-gray) !important;
}

.kiosk_tooltip__2LptH .kiosk_labelRow__HVvWi > .kiosk_copyLabel__1AYmO{
    color: var(--color-gray) !important;
    text-transform: uppercase;
}

.kiosk_tooltip__2LptH .kiosk_tooltipRow__1YvhL:hover .kiosk_copyLabel__1AYmO{
    color: var(--color-white) !important;
}


@media (max-width: 575px) {

    .kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img {
        max-height: 225px;
    }

    .kiosk_kioskHeaderArrow__35uHp {
        padding-left: 17px;
    }

    .kiosk_kioskHeaderArrowSvg__2y30t {
        height: 21px !important;
        width: 11px !important;
    }

    .kiosk_kioskHeader__20LXg {
        margin-bottom: 40px;
    }

    .kiosk_kioskHeaderTitle__2P2yq {
        font-size: 30px !important;
    }

}

@media (min-width: 576px) and (max-width: 767.98px) {
    .kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img {
        max-height: 225px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img {
        max-height: 202px;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img {
        max-height: 116px;
    }
}

@media (min-width: 1201px) and (max-width: 1350px) {
    .kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img {
        max-height: 137px;
    }
}

@media (min-width: 1351px) {
    .kiosk_newspaperCard__1dhS7 > div:nth-child(1) > img {
        max-height: 162px;
    }
}
.cardItem_card__3sSj_ > div:first-child {
    margin: 0;
}
.plmjApps_contentContainer__1iudx {
    width: 100%
}

.plmjApps_plmjAppsHeader__5pFzZ {
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.plmjApps_plmjAppsSubtitle__3J6T1 {
    align-items: center;
    width: 100%;
    justify-content: center;
}

.plmjApps_appCardLink__29AZj {
    display: contents;
}

.plmjApps_plmjAppsHeaderTitle__3GjCo {
    margin-bottom: unset !important;
    font-weight: 500 !important;
}

.plmjApps_appCard__34GP3:hover .plmjApps_cardTitle__1tt_p {
    -webkit-text-decoration: underline solid black from-font;
            text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.plmjApps_appCard__34GP3:hover .ant-card-cover {
    opacity: 0.6;
}

.plmjApps_appCardCover__1WuBr {
    height: 40px;
    max-width: 40px;
    margin: 0 auto;
}

.plmjApps_plmjAppsOpenInNew__3Lriv {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: -4px;
}

.plmjApps_plmjAppsHeaderArrow__3O0pR {
    display: flex;
    align-items: center;
    padding-left: 0 !important; 
    padding-right: 0 !important; 
    margin-left: 1rem;
}

.plmjApps_plmjAppsFooter__13fT3 {
    justify-content: flex-end;
}

.plmjApps_cardTitle__1tt_p {
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.plmjApps_appCard__34GP3 {
    max-width: 240px;
    background: rgb(0 0 0 / 0%) !important;
    border: unset !important;
    margin: 0 auto !important;
}

.plmjApps_cardContainer__1GirY { 
    margin-bottom: 41px !important; 
    margin-left: unset !important; 
    margin-right: unset !important; 
}

.plmjApps_publishedDate__1ygvo {
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
    margin-top: unset !important;
    margin-bottom: 21px !important;
}

.plmjApps_arrowNext__o8BQt {
    transform: rotate(180deg);
}

@media (max-width: 575px) {
    .plmjApps_plmjAppsHeaderArrow__3O0pR {
        
    }

    .plmjApps_plmjAppsHeader__5pFzZ {
        margin-bottom: 40px;
    }

    .plmjApps_plmjAppsHeaderTitle__3GjCo {
        font-size: 30px !important;
    }

    .plmjApps_appCard__34GP3 {
        max-width: unset;
    }

    .plmjApps_cardContainer__1GirY {
        grid-row-gap: 30px !important;
        row-gap: 30px !important;
    }

    .plmjApps_appCardContainer__2UQnR {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }

}

@media (min-width: 576px) {
    .plmjApps_appCardContainer__2UQnR {
        padding-bottom: .5rem;
    }

    .plmjApps_appCardContainer__2UQnR:nth-child(2n +1) {
        border-right: 1px solid black;
    }

    .plmjApps_plmjAppsHeaderTitle__3GjCo {
        font-size: 40px !important;
    }
}

@media (min-width: 768px) {
    .plmjApps_appCardContainer__2UQnR:not(:nth-child(3n)) {
        border-right: 1px solid black;
    }

    .plmjApps_appCardContainer__2UQnR:nth-child(3n) {
        border-right: none;
    }
}

@media (min-width: 992px) {
    .plmjApps_appCardContainer__2UQnR {
        border-right: 1px solid black !important;
    }

    .plmjApps_appCardContainer__2UQnR:last-child {
        border-right: unset !important;
    }

}
.home_home-apps-section__2xqlD {
    background-color: var(--color-selection);
    padding-top: 80px;
    padding-bottom: 80px;
}

.home_container__2dK55 {
    overflow: hidden;
    margin-bottom: 30px;
}

.home_sections__2TpZx {
    padding-top: 120px;
}

.home_banner__3LECn {
    position: absolute;
    padding: 0 !important;
    width: 100%;
    height: 100%;
    max-width: none !important;
}

.home_knowledgeSection__32758 {
    padding-bottom: 80px;
}

.home_bannerInner__2cjw0{
    height:100%;
    display: flex;
    justify-content:space-between;
    background-position:center;
    background-size:cover;
    opacity: 0.5;
    transition: transform 0.6s ease-out 0.2s;
}

.home_bannerInner__2cjw0 > div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0 0 100%;
    max-width: 100%;
}

.home_bannerInner__2cjw0 img {
    width: 15vw;
    height: auto;
    margin-bottom: -50px;
    margin-right: -5px;
}

.home_overlay__1xMjI{
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    transition: opacity 0.6s ease-out 0.2s;
    opacity: 0;
    pointer-events: none;
}

@media (min-width: 767px) {
    .home_banner__3LECn>img {
        max-height: 1064px;
    }

    .home_container__2dK55 {
        margin-bottom: 60px;
    }
}

@media (min-width: 1440px) {
    .home_banner__3LECn>img {
        width: 100%;
    }
}
.spotlight_spotlightContainer__YCwIE {
    margin-bottom: 28px;
}

@media screen and (min-width: 768px) {
    .spotlight_spotlightContainer__YCwIE {
        margin-bottom: 60px;
    }
}
.mobile_label__17hKS{
    font-size: 12px;
    line-height: 18px;
}

.mobile_labelBlog___Ql66 {
    text-transform: uppercase;
}

.mobile_labelCategory__2hcFl {
    text-transform: uppercase;
    color: var(--color-gray);
}

.mobile_title__2wHRd {
    margin-top: 10px;
    margin-bottom: 20px !important;
    font-size: 36px !important;
    line-height: 44px !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.mobile_title__2wHRd:hover{
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
}

.mobile_subtitle__2AyhW {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.mobile_image__11wze {
    max-width: 100%;
    height: 300px;
    max-height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 25px;
}

.mobile_titleShadow__UjRJH {
    text-shadow: 1px 1px 15px rgb(255 255 255 / 25%);
}

.mobile_imageRow__1dfJg > div {
    padding: 0;
}

.mobile_paginationSpot__2MYu3 ul {
    text-align: right;
}

.mobile_paginationSpot__2MYu3 ul .ant-pagination-simple-pager, .mobile_paginationSpot__2MYu3 ul li {
    margin-bottom: 0px;
}

.mobile_spotlightMobile__1fQoV .slide {
    text-align: start;
}

.button-v2_button__2jik1 {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 12px !important;
    line-height: 20px !important;
    border-radius: 0 !important;
    transition: none !important;
    height: unset !important;
    padding: 10px 24px !important;
}

.button-v2_primary-enabled__GBTSq {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-black) !important;
}

.button-v2_primary-danger__3ozPy {
    background-color: var(--color-danger) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-danger) !important;
}

.button-v2_primary-disabled__DvA8F {
    background-color: var(--color-disabled) !important;
    color: var(--color-white) !important;
    border: 1px solid var(--color-disabled) !important;
}

.button-v2_default-enabled__rYdsK {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-black) !important;
    border: 1px solid var(--color-black) !important;
}

.button-v2_default-danger__19qEa {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-danger) !important;
    border: 1px solid var(--color-danger) !important;
}

.button-v2_default-disabled__2N7uq {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-disabled) !important;
    border: 1px solid var(--color-disabled) !important;
}

.button-v2_text-enabled__39yil {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-black) !important;
    border: 1px solid var(--color-white) !important;
    box-shadow: none !important;
}

.button-v2_text-danger__bcr2K {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-danger) !important;
    border: 1px solid var(--color-white) !important;
}

.button-v2_text-disabled__1Lb2G {
    background-color: transparent !important;
    background: transparent !important;
    color: var(--color-disabled) !important;
    border: 1px solid var(--color-white) !important;
}

@media screen and (min-width: 768px) {

    .button-v2_button__2jik1 {
        font-size: 14px !important;
    }

}
.pagination_phonePaginationContainer__1hc2L, .pagination_paginationContainer__3wfUr {
    justify-content: flex-end !important;
}

.pagination_phoneBottomReachedMessage__2ARzb {
    justify-content: center !important;
    font-weight: 600 !important;
    font-family: "DomaineDisplay";
}

.pagination_paginationContainer__3wfUr .ant-pagination-prev {
    width: 40px !important;
    height: 50px !important;
    margin-right: 10px;
}

.pagination_paginationContainer__3wfUr .ant-pagination-next {
    width: 35px !important;
    height: 50px !important;
    margin: 0;
}

.pagination_paginationContainer__3wfUr .ant-pagination-prev > svg {
    width: 100%;
    height: 50px;
}

.pagination_paginationContainer__3wfUr .ant-pagination-next > svg {
    width: 100%;
    height: 50px;
}

.pagination_paginationContainer__3wfUr .ant-pagination-disabled > li > svg > path {
    stroke: #D2D2D2 !important;
}

.pagination_paginationContainer__3wfUr .ant-pagination-disabled > svg > path {
    stroke: #D2D2D2 !important;
}

.pagination_paginationContainer__3wfUr .ant-pagination-simple-pager {
    font-family: "DomaineDisplay";
    font-weight: 600;
    font-size: 20px !important;
    color: #979797 !important;
    height: auto !important;
    margin-right: 0px !important;
    width: 45%;
}

.pagination_paginationContainer__3wfUr .ant-pagination-simple-pager > input {
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 40px !important;
    border: none !important;
    cursor: default;
    pointer-events: none;
    width: 55% !important;
    margin: unset !important;
    padding: unset !important;
}

.pagination_paginationContainer__3wfUr .ant-pagination-simple-pager > input:disabled {
    background: unset !important;
    color: #D2D2D2 !important;
}

.pagination_paginationContainer__3wfUr .ant-pagination-slash {
    margin: 0 5px 0 0;
}

.pagination_paginationContainer__3wfUr>ul>li:nth-child(2)>input {
    -webkit-background-clip: text;
            background-clip: text;
}
.desktop_label__3urxw {
    font-size: 12px;
    line-height: 18px;
}

.desktop_labelBlog__1c82I {
    text-transform: uppercase;
}

.desktop_labelCategory__OhGGU {
    text-transform: uppercase;
    color: var(--color-gray);
}

.desktop_subtitle__iPjN5 {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.desktop_arrowNext__3GQsA {
    transform: rotate(180deg);
}

.desktop_tinyImage__2Nct4 {
    width: 78px;
    height: 75px;
    background-position: top center;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0px solid #e3e3e3;
    opacity: 1;
    transition: ease-in-out opacity 0.2s, ease-in-out border 0.2s;
    cursor: pointer;
    margin: 0 4px;
}

.desktop_tinyImage__2Nct4:hover {
    opacity: 0.6;
}

.desktop_tinyImageSelected__fURWe {
    border: 4px solid #e3e3e3;
    opacity: 0.6;
}

.desktop_titleShadow__GtqAu {
    text-shadow: 1px 1px 15px rgb(255 255 255 / 25%);
}

.desktop_image__23f85 {
    width: 513px;
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    margin: 0 auto;
    padding: 0 16px;
}

@media screen and (max-width: 767px) {
    .desktop_image__23f85 {
        width: 100%;
        height: auto;
        padding: 0;
    }
}

@media screen and (min-width: 768px) {
    .desktop_title__39iir {
        font-size: var(--font-size-title-page) !important;
        line-height: var(--line-height-title) !important;
        margin-top: 10px;
        margin-bottom: 20px !important;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }

    .desktop_title__39iir:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1px;
    }

    .desktop_primaryCarouselRow__1XMhx>div:nth-child(2),
    .desktop_secondaryCarouselRow__YJxc7>div:nth-child(2) {
        padding-left: 30px;
    }

    .desktop_primaryCarouselRow__1XMhx>div>.desktop_noMargin__2pcxJ {
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1349px) {
    .desktop_primaryCarouselRow__1XMhx {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .desktop_secondaryCarouselRow__YJxc7 {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .desktop_secondaryCarouselRow__YJxc7 .desktop_slick-slide__1NTwN {
        width: 75px !important;
        margin-left: 13px;
    }

    .desktop_container__TkXGp {
        display: flex;
        justify-content: center;
        /* Centraliza horizontalmente o conteúdo */
        align-items: center;
        /* Centraliza verticalmente o conteúdo */
        flex-wrap: wrap;
        /* Permite que o conteúdo quebre para a próxima linha se necessário */
    }

    .desktop_image__23f85 {
        width: 397px;
        height: 450px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 3px;
        /* Adiciona espaço ao redor da imagem */
    }

    .desktop_tinyImage__2Nct4 {
        width: 57px;
        height: 65px;
        margin: 0 3px;
    }

    .desktop_secondaryCarouselRow__YJxc7 .desktop_slick-slide__1NTwN:first-child {
        margin-left: 0px;
    }

    .desktop_spotlightContainer__2F0f5 {
        margin-bottom: 60px;
    }

    .desktop_primaryCarouselRow__1XMhx>div:nth-child(2),
    .desktop_secondaryCarouselRow__YJxc7>div:nth-child(2) {
        padding-left: 60px;
    }
}

@media screen and (min-width: 1350px) {
    .desktop_primaryCarouselRow__1XMhx {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .desktop_secondaryCarouselRow__YJxc7 {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .desktop_secondaryCarouselRow__YJxc7 .desktop_slick-slide__1NTwN {
        width: 75px !important;
        margin-left: 13px;
    }

    .desktop_secondaryCarouselRow__YJxc7 .desktop_slick-slide__1NTwN:first-child {
        margin-left: 0px;
    }

    .desktop_spotlightContainer__2F0f5 {
        margin-bottom: 60px;
    }

    .desktop_primaryCarouselRow__1XMhx>div:nth-child(2),
    .desktop_secondaryCarouselRow__YJxc7>div:nth-child(2) {
        padding-left: 60px;
    }
}
.h4_title__3kjtN{
    font-family: "DomaineDisplay";
    font-weight: 400;
    color: var(--color-black) !important;
    font-size: 22px !important;
    line-height: 30px !important;
}
.welcome_row__23BcB {
  margin-bottom: 35px;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  position: relative;
}

.welcome_background__2BJXI {
  position: absolute;
  width: 50000vw;
  height: 105%;
  left: -2500vw;
  background-color: var(--color-white);
  opacity: .7;
}

.welcome_container__GymGR {
  padding-bottom: 1rem;
  border-bottom: solid 1px  var(--color-tertiary);
}

.welcome_container__GymGR:last-child {
  padding-bottom: 10px;
  border-bottom: none;
}

.welcome_container__GymGR:not(:first-child) {
  margin-top: 1rem;
}

.welcome_title__2VVrf > h1 {
  margin-bottom: 2px !important;
  font-weight: 300 !important;
}

.welcome_text__wOhnq > span {
  color: var(--color-secondary) !important;
  font-family: "GT-America";
  font-weight: 300;
  font-size: 12px !important;
  line-height: 18px !important;
}

.welcome_seeIcon__VU83a {
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.welcome_seeIcon__VU83a > a {
  display: flex;
  align-items: center;
}

.welcome_weeklyMenu__3Dzan {
  height: 25px;
  cursor: pointer;
}

.welcome_weeklyMenu__3Dzan h1 {
  margin-bottom: 0px !important;
  font-size:18px !important;
  line-height: 26px !important;
}

.welcome_text__wOhnq .welcome_musicName__1K1iU {
  font-size:14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  margin-bottom: 2px;
  text-decoration: none;
}

.welcome_text__wOhnq .welcome_musicLink__2NYj2:hover {
  text-decoration: underline;
}

.welcome_disableLink__3gDqc {
  pointer-events: none;
  cursor: not-allowed !important;
}

.welcome_container__GymGR > .welcome_dateContainer__w0tWv {
  justify-content: space-between;
  flex-flow: row nowrap;
}

.welcome_modal__1ccQJ {
  max-width: 900px !important;
}

.welcome_wrapper__10iWh {
  align-items: center !important;
}

.welcome_modal__1ccQJ > div > div:nth-child(2) {
  max-width: 1000px !important;
}

.welcome_modal__1ccQJ .ant-modal-body {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.welcome_modal__1ccQJ .ant-modal-content {
  height: 212px !important;
  /* height: auto !important; */
}

.welcome_modal__1ccQJ .ant-modal-close {
  width: 25px;
  height: 25px;
  margin-top: 7px;
  margin-bottom: 0;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 92%;
  order: 2;
  background-color: black !important;
  z-index: 10000 !important;
}

.welcome_modal__1ccQJ .ant-modal-close-x {
  display: flex;
  align-items: center !important;
}

.welcome_modal__1ccQJ .ant-modal-close-x > svg {
  width: 100%;
  height: 100%;
}

.welcome_modal__1ccQJ .ant-modal-close-x > svg > path {
  stroke: white;
}

.welcome_menuImg__1A-Ba {
    width: 100%;
    height: auto;
}

@media (min-width: 480px) {
  .welcome_modal__1ccQJ .ant-modal-content {
    height: 300px !important;
  }

  .welcome_modal__1ccQJ .ant-modal-close {
    width: 27px;
    height: 27px;
    margin-top: 7px;
    left: 93%;
  }
}

@media (min-width: 576px) {
  .welcome_container__GymGR > .welcome_dateContainer__w0tWv {
    justify-content: flex-start;
  }

  .welcome_modal__1ccQJ .ant-modal-content {
    height: 330px !important;
  }

  .welcome_modal__1ccQJ .ant-modal-close {
    width: 28px;
    height: 28px;
    margin-top: 6px;
    left: 94%;
  }
}

@media (min-width: 768px) {
  .welcome_modal__1ccQJ .ant-modal-content {
    height: 485px !important;
  }

  .welcome_modal__1ccQJ .ant-modal-close {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    left: 95%;
  }
}

@media (min-width: 992px) {
  .welcome_row__23BcB {
    margin-bottom: 60px;
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
  }

  .welcome_container__GymGR {
    border-bottom: none;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }

  .welcome_container__GymGR:last-child {
    border-bottom: none;
  }

  .welcome_container__GymGR:not(:last-child) {
    border-right: solid 1px  var(--color-tertiary);
  }

  .welcome_background__2BJXI {
    display: block;
    background-color: var(--color-white);
    height: 100%;
    opacity: .7;
  }

  .welcome_seeIcon__VU83a {
    align-items: flex-start;
  }

  .welcome_seeIcon__VU83a > a {
    margin-top: .25rem;
  }

  .welcome_container__GymGR:not(:nth-child(2)) {
    padding: 0 1.5rem;
  }

  .welcome_modal__1ccQJ .ant-modal-content {
    height: 565px !important;
  }

  .welcome_modal__1ccQJ .ant-modal-close {
    margin-top: 10px;
    left: 96%;
  }
}

@media (min-width: 1200px) {
  .welcome_modal__1ccQJ .ant-modal-content {
    height: 530px !important;
  }

  .welcome_modal__1ccQJ > div > div:nth-child(2) {
    max-width: 1000px !important;
  }

  .welcome_modal__1ccQJ .ant-modal-close {
    width: 35px;
    height: 35px;
    margin-top: 15px;
    left: 95%;
  }
}

@media (min-width: var(--main-container-max-width)) {
  .welcome_background__2BJXI {
    left: calc((100vw - var(--main-container-max-width)) / -2);
  }
}

.modal_wrapper__3iMHm {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.modal_modal__340Md {
    top: 0 !important;
    width: 100vw !important;
    max-width: 500px;
    padding-bottom: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    max-height: calc(100vh - 20px);
}

.modal_modal__340Md>div {
    border-radius: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modal_modal__340Md>div>div {
    border-radius: 0;
    border: none;
}

.modal_modal__340Md>div>button {
    height: 54px;
    width: 54px;
    top: 0;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    z-index: 10000;
}

.modal_modal__340Md>div>button:hover svg {
    background-color: rgb(238, 238, 238, .4);
    border-radius: 50%;
}

.modal_modal__340Md>div>button>span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100% !important;
}

.modal_modal__340Md>div>div {
    width: 100%;
}

.modal_modal__340Md>div>div:nth-child(2) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-width: 500px;
    width: calc(100% - 54px);
    order: -1;
    background-color: var(--color-white);
    z-index: 1000;
}

.modal_modal__340Md>div>div:nth-child(2) div {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 14px;
    color: var(--color-secondary);
}

.modal_modal__340Md>div>div:last-child {
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 3rem;
}

.modal_modal__340Md>div>div button {
    background-color: var(--color-black);
    margin: 0 !important;
    border: none;
    border-radius: 0;
    width: 50%;
    font-family: "GT-America";
    font-weight: 500;
    height: 100%;
    transition: none;
    padding: 0;
}

.modal_modal__340Md>div>div button:hover,
.modal_modal__340Md>div>div button:focus {
    background-color: var(--color-black);
}

.modal_modal__340Md>div>div button:first-child {
    color: #9A9A9A;
}

@media (min-width: 500px) {
    .modal_modal__340Md>div>button {
        right: calc(50% - 250px);
        border: none;
    }
}

@media (min-width: 420px) {
    .modal_wrapper__3iMHm {
        align-items: center;
    }
    .modal_modal__340Md>div>div:last-child {
        display: flex;
        justify-content: flex-end;
        padding-right: 24px;
        margin-bottom: 1rem;
    }
    .modal_modal__340Md>div>div button {
        width: 100px;
    }
    .modal_modal__340Md>div>div button:first-child {
        background-color: var(--color-white);
        color: var(--color-black);
    }
}

@media (max-width: 419px) {
    .modal_modal__340Md>div>div button {
        position: fixed;
        display: flex;
        height: 80px;
        justify-content: center;
        align-items: center;
        bottom: 0px;
    }
    .modal_modal__340Md>div>div button:not(:first-child) {
        left: 50%;
    }
    .modal_modal__340Md>div>div button {
        position: fixed;
    }
}
.knowledge_knowledgeContainer__2cLkH {
    margin: auto !important;
}

.knowledge_background__3XOkH {
    width: 100%;
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 0;
    background-color: var(--color-black);
    background-image: url(/static/media/knowledge-banner-mob.97d3289e.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    margin-top: 3px;
}

.knowledge_allContainer__2gpUy {
    margin-bottom: 80px;
}

.knowledge_knowledgeHeader__3ragm {
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 80px;
}

.knowledge_knowledgeHeaderTitle__2VBMc {
    margin-bottom: unset !important;
    color: var(--color-white) !important;
    font-weight: 500 !important;
}

.knowledge_contentsContainer__3RfCw {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important;
    padding-bottom: 16px !important;
}

.knowledge_knowledgeContentRow__u7S9Z {
    margin-bottom: 15px;
}

.knowledge_knowledgeContentRow__u7S9Z > div > h1 {
    margin: unset;
}

.knowledge_knowledgeContentTitle__1NDH1 {
    color: var(--color-white) !important;
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 290px;
    white-space: nowrap;
}

.knowledge_knowledgeTitleArrow__VriTi {
    margin-bottom: -6px;
    margin-left: 5px;
}

.knowledge_knowledgeTitleArrow__VriTi path {
    stroke: var(--color-white) !important;
}

.knowledge_knowledgeContentLink__3-6Fy {
    display: block;
}

.knowledge_knowledgeContentLink__3-6Fy:hover>div {
    -webkit-text-decoration: underline solid var(--color-white) from-font !important;
            text-decoration: underline solid var(--color-white) from-font !important;
    text-underline-offset: 4px;
}

.knowledge_knowledgeContentText__1N2ir {
    color: var(--color-tertiary) !important;
    font-family: "GT-America";
    font-weight: 300;
}

.knowledge_knowledgeFooter__2mi4f {
    justify-content: flex-end;
    padding-top: 1rem;
}

.knowledge_seeMoreButton__loDIf {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
    border-color: var(--color-white) !important;
    margin-bottom: 100px;
}

.knowledge_seeMoreButton__loDIf:hover {
    background: var(--color-white) !important;
    color: var(--color-black) !important;
}

.knowledge_arrowPrev__QhKUk {
    filter: invert(1);
}

.knowledge_arrowNext__2jce3 {
    transform: rotate(180deg);
    filter: invert(1);
}

@media (min-width: 768px) {
    .knowledge_knowledgeContentTitle__1NDH1 {
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: unset;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media (min-width: 991px) {
    .knowledge_contentsContainer__3RfCw {
        border-bottom: none !important;
    }
    .knowledge_background__3XOkH {
        display: block;
        height: 100%;
        width: 100vw;
        background-image: url(/static/media/knowledge-banner-desk.0d768698.svg);
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        background-color: unset;
    }
}

@media (max-width: 991px) {
    .knowledge_knowledgeFooter__2mi4f {
        float: left;
    }
}

@media (max-width: 575px) {
    .knowledge_knowledgeHeader__3ragm {
        margin-bottom: 40px;
    }
    .knowledge_knowledgeHeaderTitle__2VBMc {
        font-size: 30px !important;
    }
}
.latestNews_container__3pPC- {
    margin-bottom: 80px !important;
}

.latestNews_container__3pPC- > div > div > .latestNews_item__1vGGP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.latestNews_item__1vGGP:hover a {
    opacity: .7;
}

.latestNews_item__1vGGP > a > h1 {
    margin: 5px 0 10px 0 !important;
}

.latestNews_itemBody__6bDMh, .latestNews_itemBody__6bDMh > * {
    margin-bottom: 0px !important;
    color: var(--color-secondary) !important;
    font-size: 14px;
    line-height: 20px;
}

.latestNews_itemBody__6bDMh {
    margin-bottom: 10px !important;
}

.latestNews_faded__eUaLb {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
}

.latestNews_button__2yDxn {
    height: 32px;
    margin-top: 40px;
}

.latestNews_button__2yDxn:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.latestNews_overline__JfRWC{
    text-transform: uppercase;
}

.latestNews_chevron__1QZhW {
    transform: rotate(180deg);
    margin-left: 30px;
    margin-top: 6px;
}

.latestNews_title__2DYas{
    font-size: 18px !important;
    line-height: 26px !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-weight: 500 !important;
}

.latestNews_subtitle__1DVDT > * {
    display: flex;
    align-items: center;
}

.latestNews_subtitle__1DVDT h1 {
    margin: 0 !important;
}

.latestNews_subtitle__1DVDT:hover h1{
    -webkit-text-decoration: underline solid black from-font;
            text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.latestNews_mainTitle__YZYAJ{
    font-weight: 500 !important;
}


@media (min-width: 992px) {
    .latestNews_buttonRow__UUaY_ {
        display: flex;
        justify-content: flex-start;
    }
}
.agenda_agendaContainer__1ZiQS {
    margin-top: 60px;
}

.agenda_subtitle__l3pkc > * {
    display: flex;
    align-items: center;
}

.agenda_subtitle__l3pkc h1 {
    margin: 0 !important;
}

.agenda_subtitle__l3pkc:hover h1{
    -webkit-text-decoration: underline solid var(--color-black) from-font;
            text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.agenda_chevron__ZMEsM {
    transform: rotate(180deg);
    margin-left: 30px;
    margin-top: 6px;
}

.agenda_categoryContainer__1Ta1L {
    display: flex !important;
    align-items: center;
}

.agenda_nextEvent__3TUg3, .agenda_noEventsContainer__2dtBs {
    display: block !important;
    cursor: pointer;
    margin-top: 60px;
    padding-right: 10px;
    text-align: justify;

}

.agenda_nextEventTitle__21RQG{
    line-height: 32px !important;
}

.agenda_nextEvent__3TUg3:hover .agenda_nextEventTitle__21RQG{
    -webkit-text-decoration: underline solid var(--color-black);
            text-decoration: underline solid var(--color-black);
    text-underline-offset: 4px;
}

.agenda_nextEvent__3TUg3:hover .agenda_bookButton__3a9Vn{
    border: 1px solid var(--color-white) !important;
    filter: invert(1);
}

.agenda_nextEvent__3TUg3 h1 {
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.agenda_noEventsContainer__2dtBs {
    cursor: auto;
}

.agenda_noEventsContainer__2dtBs > span {
    color: var(--color-tertiary) !important;
}

.agenda_categoryContainer__1Ta1L > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.agenda_categoryContainer__1Ta1L > span {
    margin: 0 0 0 10px;
    text-transform: uppercase;
}

.agenda_titleContainer__2Ny7k > h1 {
    margin: 5px 0 0 0;
}

.agenda_dateContainer__2gfad {
    margin-top: 20px;
}

.agenda_training__SFkA5 {
    color: #A298C3 !important;
}

.agenda_internalEvent__1giZ4 {
    color: var(--color-success) !important;
}

.agenda_externalEvent__S-WYP {
    color: var(--color-warning) !important;
}

.agenda_bookButton__3a9Vn {
    width: 100%;
    margin-top: 20px;
    height: 44px !important;
}

.agenda_eventRow__1qFgL {
    margin-top: 40px !important;
}

.agenda_dateList__1ujwF {
    text-transform: uppercase;
}

.agenda_dateList__1ujwF > span {
    font-size: 13px !important;
    color: var(--color-secondary) !important;
}

.agenda_seeMore__2zR2w {
    margin-top: 40px !important;
}

.agenda_button__21GxI {
    height: 32px;
}

.agenda_button__21GxI:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.agenda_highlightEventDate__xJ4F1 {
    display: block;
    width: 100%;
}
.agenda_subscriptionRow__2iaou {
    width: 100%;
    margin-top: 20px;
    height: 44px !important;
}

.agenda_subscription__3DdFN {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.agenda_subscription__3DdFN span {
    font-weight: 700;
}

.agenda_tickIcon__3CcVh {
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid var(--color-black) 1px;
    margin-right: 10px;
}

.agenda_mainTitle__2Ivh6{
    font-weight: 500 !important;
}

.agenda_nextEventTitle__21RQG{
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    margin-bottom: 10px !important;
}

@media screen and (min-width: 576px) {
    .agenda_seeMore__2zR2w {
        display: flex !important;
        justify-content: flex-end;
    }

    .agenda_bookButton__3a9Vn {
        max-width: 106px;
    }
}

@media screen and (min-width: 992px) {
    .agenda_agendaContainer__1ZiQS {
        margin-top: 0px;
    }

    .agenda_bookButton__3a9Vn {
        max-width: unset;
    }

    .agenda_eventRow__1qFgL:first-child{
        margin-top: 0 !important;
    }

    .agenda_eventRow__1qFgL:not(:first-child) {
        margin-top: 20px !important;
    }

    .agenda_eventRow__1qFgL > div:nth-child(2) {
        margin-top: 10px;
    }

    .agenda_divider__diT3j {
        margin: 30px 0 25px 0;
        border-color: rgba(0, 0, 0, .3);
    }
    
}
.tick_tagIcon__H7JCW g path {
    stroke: var(--color-black) !important;
}
.eventSmallCard_card__1jx-X {
  display: flex;
  border: 1px solid rgb(0, 0, 0, 20%);
  padding: 15px 15px;
  padding-right: 0;
  cursor: pointer;
}

.eventSmallCard_card__1jx-X > * {
    margin-right: 10px;
}

.eventSmallCard_card__1jx-X > .eventSmallCard_tick__GwwGu{
    position: absolute;
    right: 0px;
 
}

.eventSmallCard_card__1jx-X:not(:nth-child(2)) {
  margin-top: -1px;
  border-top: 1px solid rgb(0, 0, 0, 0);
}

.eventSmallCard_card__1jx-X:hover {
  border: solid 1px var(--color-black);
}

.eventSmallCard_card__1jx-X > span {
  white-space: unset;
  max-width: calc(100% - 75px - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.eventSmallCard_card__1jx-X:hover > span:nth-child(3) {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}

.eventSmallCard_category__2LK5D {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  margin-right: 10px;
}

.eventSmallCard_tick__GwwGu {
    display: flex;
    width: 24px !important;
    height: 24px !important;
    border: solid 1px var(--color-black);
    border-radius: 50%;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 15px !important;
}

.eventSmallCard_title__1zNPi{
  padding-left: 10px;
}
.eventModal_drawer__1xw1_ {
    z-index: 10000;
}

.eventModal_modal__1dsSj .ant-btn {
    background-color: var(--color-black);
    border: var(--color-black) 1px solid;
}

.eventModal_modal__1dsSj .ant-btn span {
    font-size: 16px;
    font-weight: 500;
}

.eventModal_modal__1dsSj .ant-btn:hover {
    background-color: var(--color-white);
    border: var(--color-black) 1px solid;
    color: var(--color-black);
}

.eventModal_drawer__1xw1_ > div:last-child {
    height: calc(100% - 10px) !important;
}

.eventModal_drawer__1xw1_ .ant-drawer-close{
    padding: 16px 20px;
}

.eventModal_closeIcon__2OHPm {
    border-radius: 50%;
    margin-bottom: 0 !important;
}

.eventModal_closeIcon__2OHPm path {
    stroke: var(--color-black);
}

.eventModal_closeIcon__2OHPm:hover {
    background-color: var(--color-light-gray);
}

.eventModal_drawer__1xw1_ .ant-drawer-header {
    border-bottom: none;
    padding: 16px 20px;
}

.eventModal_headerTitle__3Mjyt {
    font-size: 14px !important;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-secondary) !important;
}

.eventModal_drawer__1xw1_ .ant-drawer-body {
    padding: 0 20px 20px 20px;
}

.eventModal_drawer__1xw1_ .eventModal_image__7feQg {
    object-fit: cover;
    width: calc(100vw - 40px);
    height: 150px;
    object-position: 50% 50%;
}

.eventModal_row__ggm9v > div {
    position: relative;
    padding-bottom: 48px;
}

.eventModal_category__38ZSz {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.eventModal_category__38ZSz div:first-child {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.eventModal_category__38ZSz span {
    text-transform: uppercase;
}

.eventModal_drawer__1xw1_ h1 {
    margin-top: 10px;
    margin-bottom: 0;
}


.eventModal_subtitle__16t7F {
    display: block;
    color: var(--color-secondary) !important;
    font-weight: 300;
    margin-bottom: 12px;
    margin-top: 20px;
    font-size: 15px !important;
}

.eventModal_date__1Y1NX {
    font-size: 12px !important;
}

.eventModal_iconsContainer__20YxW {
    margin-top: 30px;
    margin-bottom: 50px;
}

.eventModal_iconsContainer__20YxW > div {
    display: flex;
    align-items: center;
}

.eventModal_iconsContainer__20YxW > div:not(:first-child) {
    margin-top: 20px;
}

.eventModal_iconsContainer__20YxW span {
    color: var(--color-secondary) !important;
}

.eventModal_icon__2mF-- {
    margin-right: 12px;
}

.eventModal_icon__2mF-- path {
    stroke: var(--color-black);
}

.eventModal_linksIcon__TrKId {
    margin-top: 2px;
}

.eventModal_mailIcon__1K2Dh {
    stroke-width: 1px;
}

.eventModal_iconsContainer__20YxW > .eventModal_organizersGroup__3fg5p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 32px;
}

.eventModal_subscribe__1FfL8 {
    width: 80%;
    height: 40px !important;
    left: 0 !important;
    bottom: 0;
    font-weight: 500;
    padding: 0;
    margin-bottom: 0;
    margin-top: 1em;
    font-size: 14px !important;
}

.eventModal_subscriptionMobile__Fe2iK {
    position: absolute !important;
    bottom: 0;
    padding-bottom: 0 !important;
    display: flex !important;
    align-items: center;
    height: 70px;
    width: 100%;
    left: 0px;
    justify-content: center;
    border-top: var(--color-medium-gray) 1px solid;
    background-color: white;
}

.eventModal_subscriptionMobile__Fe2iK > div {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eventModal_subscriptionMobile__Fe2iK span {
    font-weight: 700;
    font-size: 13px !important;
}

.eventModal_bodyText__3wxu7 p {
    margin-bottom: 0;
    font-size: 14px;
}

.eventModal_bodyText__3wxu7 strong {
    font-weight: 500;
}

.eventModal_bodyText__3wxu7 {
    background-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    line-height: 22px;
}

.eventModal_bodyText__3wxu7 ul {
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-before: 0;
            margin-block-start: 0;
}

.eventModal_body__2eiU3 {
    margin-bottom: 40px;
}

.eventModal_instructions__1DwIT {
    margin-bottom: 0;
}

.eventModal_bodyTitle__5dcNs {
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.eventModal_linksContainer__11uPR {
    display: flex;
    flex-direction: column;
}

.eventModal_linkText__3Y0Im {
    width: 220px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;   
}

@media (min-width: 576px) {
    .eventModal_modal__1dsSj {
        width: 570px !important;
        background-color: var(--color-white);
        padding-bottom: 12px;
    }

    .eventModal_modal__1dsSj .ant-btn span {
        font-size: 14px;
        font-weight: 400;
    }

    .eventModal_modal__1dsSj .ant-modal-content {
        max-height: 80vh;
        overflow: auto;
        overflow-y: overlay;
        box-sizing: border-box;
        box-shadow: none;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .eventModal_modal__1dsSj .ant-modal-body,
    .eventModal_modal__1dsSj .ant-modal-header {
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-bottom: 16px;
    }

    .eventModal_modal__1dsSj .ant-modal-header {
        border-bottom: none;
        position: -webkit-sticky;
        position: sticky;
        width: calc(100% - 56px);
        top: 0;
        left: 0;
        background-color: var(--color-white);
        z-index: 1;
        border-radius: 0 !important;
    }

    .eventModal_modal__1dsSj .ant-modal-body {
        padding-top: 0;
        order: 3;
    }

    .eventModal_modal__1dsSj .ant-modal-footer {
        order: 3;
        width: 100%;
        border: none;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 5px;
        padding-top: 16px;
    }

    .eventModal_userAdded__FUw6u .ant-modal-footer {
        padding-bottom: 0px;
    }

    .eventModal_modal__1dsSj .ant-modal-close {
        width: 30px;
        margin-right: 26px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 100%;
        order: 2;
        background-color: var(--color-white);
        margin-bottom: 0;
    }

    .eventModal_modal__1dsSj .ant-modal-close-x {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .eventModal_modal__1dsSj .ant-modal-footer button:first-child {
        display: none;
    }

    .eventModal_modal__1dsSj .ant-modal-footer button:last-child {
        height: 40px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 500;
    }

    .eventModal_modal__1dsSj .eventModal_image__7feQg {
        object-fit: cover;
        width: 510px;
        height: 250px;
        object-position: 50% 50%;
    }

    .eventModal_modal__1dsSj .eventModal_category__38ZSz {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .eventModal_bodyText__3wxu7 {
        font-size: 14px;
        line-height: var(--line-height-content);
    }

    .eventModal_iconsContainer__20YxW {
        margin-bottom: 12px;
    }

    .eventModal_subscription__1yi34 {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
    }

    .eventModal_subscription__1yi34 span {
        font-weight: 500;
    }

    .eventModal_tickIcon__1i9ps {
        background-color: var(--color-white);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: solid var(--color-black) 1px;
        margin-right: 10px;
    }

    .eventModal_iconsContainer__20YxW span {
        font-size: 15px !important;
    }

    .eventModal_iconsContainer__20YxW a:hover span {
        text-decoration: underline;
        text-underline-offset: 2px;
    }

    .eventModal_bodyTitle__5dcNs {
        margin-bottom: 15px;
        font-size: 14px;
    }
    .eventModal_loadingCloseIcon__2HWlN{
        pointer-events: none;
        cursor: not-allowed;
    }

    .eventModal_linksGroup__18VuF {
        align-items: flex-start !important;
    }

    .eventModal_modal__1dsSj .eventModal_subscribeButton__3DJfz {
        margin-top: 1em;
        background-color: var(--color-black);
    }

    .eventModal_modal__1dsSj .eventModal_subscribeButton__3DJfz:focus {
        background-color: var(--color-black);
        border-color: var(--color-black);
    }

    .eventModal_modal__1dsSj .eventModal_subscribeSuccess__8DAte {
        background-color: var(--color-white);
        cursor: unset;
        color: var(--color-black);
        box-shadow: none;
        text-shadow: none;
    }

    .eventModal_modal__1dsSj .eventModal_subscribeSuccess__8DAte:focus {
        background-color: var(--color-white);
        border-color: var(--color-black);
        color: var(--color-black);
        box-shadow: none;
        text-shadow: none;
    }      

    [ant-click-animating-without-extra-node='true']::after{
        display:none;
    }
}

@media (min-width: 576px) and (max-width: 600px) { 
    .eventModal_modal__1dsSj .ant-modal-body,
    .eventModal_modal__1dsSj .ant-modal-footer,
    .eventModal_modal__1dsSj .ant-modal-header {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .eventModal_modal__1dsSj .eventModal_image__7feQg {
        width: 530px;
    }
}
.login_page__2XvX4{
    background-image: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);
    height: 100vh;
}

.login_card__N9dRD{
    width: 300px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 80px !important;
    border-radius: 4px !important;
}

.login_loginButton__32b0q{
    height: 40px !important;
    background-color: #2F2F2F !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    color: white !important;
    display: inline-flex !important;
    justify-content: center;
}

.login_loginButton__32b0q > span{
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    align-items: center;
    display: inherit !important;
    align-self: center;
}
.login_loginButton__32b0q > svg {
    position: relative;
    top: 5px;
}

.login_microsoftIcon__28qAg{
    width: 18px;
}

.login_lockIcon__3j9WB{
    width: 23px;
    fill: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8px;
}
.login_circle__33CO0 {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #bdbdbd;
    margin-bottom: 1em !important;
}
.intro_container__3za2B > div {
    margin-bottom: 40px;
}

.intro_container__3za2B {
    margin-bottom: 40px;
}

.intro_description__N9cgP {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
    width: 100%;
}

.intro_background__3p_ln {
    background-image: url(/static/media/plus.46befb7b.jpg);
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.intro_loading__28JS_{
    background: none !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .intro_description__N9cgP {
       font-size: 15px;
       font-weight: 400 !important;
       line-height: 20px
    }
 }

@media (min-width: 768px) {
    .intro_description__N9cgP {
       font-size: 18px;
       font-weight: 400 !important;
       line-height: 25px;
    }
 }

@media (min-width: 1024px) {
    .intro_description__N9cgP {
        display: block;
        width: 80%;
    }

    .intro_background__3p_ln {
        background-image: unset !important;
    }

    .intro_container__3za2B > div {
        margin-bottom: 100px;
    }
}

.filtersDesktop_filtersDropdown__3oFXG > .ant-dropdown-menu {
    right: -20px !important;
}

.filtersDesktop_menuFilterContainer__31yvF, .filtersDesktop_menuOrderContainer__je2-H {
    padding: 20px !important;
    top: 15px;
}

.filtersDesktop_menuFilterContainer__31yvF > .ant-dropdown-menu-item:first-child, .filtersDesktop_menuOrderContainer__je2-H > .ant-dropdown-menu-item {
    margin-bottom: 20px;
}

.filtersDesktop_menuFilterContainer__31yvF > .ant-dropdown-menu-item, .filtersDesktop_menuOrderContainer__je2-H > .ant-dropdown-menu-item {
    padding: 0;
}
 
.filtersDesktop_menuOrderContainer__je2-H > .ant-dropdown-menu-item:last-child {
    margin-bottom: 0;
}

.filtersDesktop_menuFilterContainer__31yvF > .ant-dropdown-menu-item > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}


div > .filtersDesktop_selectGroup__BEBk2 {
    display: inline-flex;
    flex-direction: column;
}

.filtersDesktop_selectGroup__BEBk2 > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.filtersDesktop_selectGroup__BEBk2 > label > span, .filtersDesktop_radioGroup__1hpFe > div > div > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}

.filtersDesktop_radioGroup__1hpFe:hover {
    background: unset;
    opacity: unset;
}

.filtersDesktop_filterContainer__87DIJ {
    display: flex;
    align-items: center;
}

.filtersDesktop_filterContainer__87DIJ > div > .filtersDesktop_divider__2Bour {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.filtersDesktop_menuOrderContainer__je2-H .ant-space-item {
    width: 100%;
}

.filtersDesktop_pointer__17VQ4 {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.filtersDesktop_chevron__319uE {
    margin-left: 15px;
    width: 14px;
}

.filtersDesktop_control__1RuUw {
    display: flex;
    margin-right: 20px;
}

.filtersDesktop_control__1RuUw > span {
    display: flex;
}

.filtersDesktop_clearButton__3kc4- {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
}

.filtersDesktop_menuFilterContainer__31yvF > .ant-dropdown-menu-item:hover, .filtersDesktop_menuOrderContainer__je2-H > .ant-dropdown-menu-item:hover {
    background-color: unset
}

.filtersDesktop_dropdown__1UeFa {
    padding-right: 20px;
}

.filtersDesktop_slider__27U3C .ant-slider-step,
.filtersDesktop_slider__27U3C .ant-slider-rail,
.filtersDesktop_slider__27U3C .ant-slider-track {
    height: 2px;
}

.filtersDesktop_slider__27U3C .ant-slider-dot {
    display: none;
}

.filtersDesktop_slider__27U3C .ant-slider-handle {
    background-color: var(--color-black) !important;
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.filtersDesktop_slider__27U3C .ant-slider-mark-text {
    margin-top: 5px;
}

.filtersDesktop_tooltip__bW3Dj {
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--color-black) !important;
    box-shadow: none !important;
    padding: 0 !important;
}
.tag_tagIcon__3TnWT g path {
    stroke: var(--color-black) !important;
}
.cardPlus_cardCover__14ZMu {
    background-position: center 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #ECECEC;
    height: 200px;
}

 .cardPlus_cardBodyContainer__hJ2s4 {
    justify-content: space-between !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.cardPlus_bookButtonContainer__31ByB {
    align-self: center !important;
}

.cardPlus_specialConditionsText__3OWgE {
    font-family: "DomaineDisplay";
    font-weight: 600 !important;
    color: var(--color-success) !important;
    line-height: 20px;
    font-size: 18px !important;
}

@media (min-width: 576px) {
    .cardPlus_cardCover__14ZMu {
        height: 170px !important;
    }

    .cardPlus_specialConditionsText__3OWgE {
        font-size: 16px !important;
    }
}

@media (min-width: 768px) {
    .cardPlus_cardCover__14ZMu {
        height: 150px !important;
    }
} 

@media (min-width: 1184px) {
    .cardPlus_cardCover__14ZMu {
        height: 200px !important;
    }
} 

.cardPlus_container__2KWmx {
    border: 1px solid var(--color-white) !important;
    cursor: pointer;
}

.cardPlus_container__2KWmx > div:nth-child(2) {
    border-bottom: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
}

.cardPlus_title__2IPn5 {
    font-family: "DomaineDisplay";
    font-weight: 600 !important;
    color: var(--color-success) !important;
    margin-bottom: unset !important;
    font-size: 32px !important;
    line-height: 40px;
}

.cardPlus_titleIcon__1YY9X {
    align-self: center;
    margin-right: 10px;
    margin-top: 4px;
}

.cardPlus_titleContainer__3QGMB {
    height: 36px;
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
}

.cardPlus_description__Whxcc {
    font-family: "DomaineDisplay";
    font-weight: 500 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 20px !important;

}

.cardPlus_icon__3zq4k {
    margin-top: .5rem;
    stroke: var(--color-black);
    cursor: pointer;
}

.cardPlus_leftIcon__mc3wW {
    padding-left: 0 !important;
}

.cardPlus_icons__2BnHP > svg:last-child {
    margin-left: 12px;
}

@media (hover: hover) {

    .cardPlus_container__2KWmx:hover .cardPlus_cardCover__14ZMu {
        border-bottom: 1px solid black !important;
    }

    .cardPlus_container__2KWmx:hover {
        background-color: var(--color-black);
    }
    
    .cardPlus_container__2KWmx:hover .cardPlus_description__Whxcc {
        color: var(--color-white) !important;
    }

    .cardPlus_container__2KWmx:hover g {
        stroke: var(--color-white) !important;
    }

    .cardPlus_container__2KWmx:hover g path {
        stroke: var(--color-white) !important;
    }
    
    .cardPlus_container__2KWmx:hover button {
        color: var(--color-black) !important;
        background-color: var(--color-white) !important;
        border: var(--color-white) 1px solid !important;
        transition: none !important;
    }
    
    .cardPlus_container__2KWmx button:hover {
        background-color: var(--color-white) !important;
        color: var(--color-black) !important;
        transition: none !important;
    }

    .cardPlus_container__2KWmx:hover .cardPlus_icon__3zq4k {
        stroke: var(--color-white);
    }

    .cardPlus_container__2KWmx:hover > div:nth-child(2) {
        border-bottom: 1px solid var(--color-black);
        border-left: 1px solid var(--color-black);
        border-right: 1px solid var(--color-black);
    }
}
.cardList_seeMoreButton__2eZ7g:hover {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}

.cardList_mobileScrollEndMessage__3sWJG {
    justify-content: center !important;
    font-weight: 600 !important;
    font-family: "DomaineDisplay";
}

.cardList_cardListContainerLoading__1tQqn {
    pointer-events: none;
    opacity: 0.6;
}

.cardList_cardListContainer__1fSVQ {
    margin: 2rem auto 40px auto !important;
}

.cardList_searchInputContainer__39u5P {
    width: 100%;
    margin-top: 7px;
}

.cardList_searchInputContainer__39u5P > .cardList_searchInput__2RNCa {
    height: 50px;
    padding-right: unset;
    padding-left: 15px;
}

.cardList_searchInput__2RNCa input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 20px !important;
}

.cardList_searchInput__2RNCa input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.cardList_searchInput__2RNCa input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.cardList_searchInput__2RNCa .ant-input-prefix {
    margin-right: unset;
}

.cardList_searchInputIcon__5pbAa {
    width: 20px;
    height: 20px;
    margin-right: 18px;
    margin-left: 10px;
}


.cardList_filter__1N1Sb {
    margin-top: 30px !important;
}

.cardList_filter__1N1Sb > div:nth-child(2) span{
    font-family: "GT-America" !important;
    font-weight: 300 !important;
    font-size: 12px !important;
}

.cardList_dropdownContainer__1zSMb {
    margin-bottom: 18px;
}

.cardList_overlay__3lVEa > div {
    padding: 0;
    margin-top: -4px;
}

.cardList_overlay__3lVEa *:hover {
    background-color: var(--color-white) !important;
}

.cardList_overlay__3lVEa [ant-click-animating-without-extra-node='true']::after,
.cardList_overlay__3lVEa .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow:none;
    opacity: 1;
    animation: none;
    animation-fill-mode: none;
    pointer-events: none;
}

.cardList_dropdownRow__1OsvO {
    border-bottom: solid 1px var(--color-black);
}

.cardList_dropdownRow__1OsvO h1 {
    margin-bottom: 6px;
    font-weight: 500 !important;
}

.cardList_checkboxGroup__3Yhph > label {
    margin: 9px 0;
}

.cardList_clearButton__25L0u {
    padding-left: 0 !important;
    font-weight: 600 !important;
}

.cardList_labelContainer__3KH1u {
    display: flex;
    align-items: center;
}

.cardList_labelContainer__3KH1u svg {
    width: 20px;
    height: 20px;
}

.cardList_labelContainer__3KH1u:hover {
    cursor: pointer;
}

.cardList_label__hvG9T {
    margin-left: .5rem;
}

.cardList_removeIcon__1Mgo_{
    margin-right: .5rem;
}

.cardList_clearAll__3tJqT > span:nth-child(2){
    margin-left: .5rem;
}

.cardList_results__1anHp {
    margin-right: .5rem;
}

.cardList_display__lDkE8 {
    display: block !important;
}

.cardList_listLarge__Vvlli {
    display: none !important;
}

.cardList_listLarge__Vvlli h1 {
    margin-top: 40px;
}

.cardList_listSmall__3lFmh {
    margin-top: 0 !important;
}

.cardList_listSmall__3lFmh > div > div:nth-child(1) {
    display: none;
}

.cardList_listSmall__3lFmh > div > div:nth-child(2) {
    margin-top: 0 !important;
}

@media (min-width: 710px) {

    .cardList_suffixContainer__YBZjp {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .cardList_pagination__3zJcX > ul {
        display: flex;
        justify-content: flex-end;
        margin-right: -15px !important;
    }
}

@media (min-width: 1024px) {
    .cardList_filter__1N1Sb {
        display: none !important;
    }

    .cardList_listLarge__Vvlli {
        display: block !important;
    }

    .cardList_listSmall__3lFmh {
        display: none !important;
    }

    .cardList_cardListContainer__1fSVQ {
        margin-top: 4rem !important;
    }
}
.categoriesList_container__243no > div {
    position: relative;
}

.categoriesList_container__243no > div > div:nth-child(2) {
    margin-top: 20px;
}

.categoriesList_divider__FhF6i {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-medium-gray);
    margin-top: 15px;
    margin-left: -90px;
}

.categoriesList_labelContainer__2Y7LC {
    display: flex;
    align-items: center;
}

.categoriesList_labelContainer__2Y7LC svg {
    width: 20px;
    height: 20px;
}

.categoriesList_labelContainer__2Y7LC:hover {
    cursor: pointer;
}

.categoriesList_labelContainer__2Y7LC span {
    color: var(--color-black) !important;
}

.categoriesList_mainLabel__1GpNL h1 {
    margin-bottom: 0 !important;
}

.categoriesList_mainLabelText__3I-sZ {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
}

.categoriesList_resultsContainer__3dDAW {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.categoriesList_resultsContainer__3dDAW > span {
    color: var(--color-tertiary) !important;
}

.categoriesList_bar__2DHh3 {
    margin-right: .5rem;
}

.categoriesList_removeIcon__-0QXK {
    margin-right: .5rem;
}

@media (min-width: 1350px) {
    .categoriesList_divider__FhF6i {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}
.backgroundBanner_background__6R4LW{
    background-image: url(/static/media/plus.46befb7b.jpg);
    background-position: center;
    background-size: cover;
}

.backgroundBanner_cutoutLeft__2zp_t{
    width: 1000px !important;
    position: absolute !important;
    left: -500px !important;
}

.backgroundBanner_cutoutRight__QtMWl{
    left: unset !important;
    right: 0px !important;
    position: absolute !important;
    width: 200px !important;
    top: -274px !important;
}

.backgroundBanner_loading__T-lsX{
    background: none !important;
}
.plus-plmj_banner__2nS5U {
    height: 300px;
    width: 100vw;
    position: absolute;
    padding: 0 !important;
    max-width: none !important;
    overflow: hidden;
}

.plus-plmj_banner__2nS5U img {
    position: absolute;
    width: 800px;
    height: 600px;
    left: -140px;
    top: -225px;
}

.plus-plmj_cardModal__3Ngix{
    max-width: 500px !important;
}
.plus-plmj_modal__SVz_R * {
    border: none !important;
    box-shadow: none !important;
}

.plus-plmj_modal__SVz_R .plus-plmj_body__7ZgUm {
    font-family: 'GT-America';
    font-weight: 300;
}

.plus-plmj_modal__SVz_R>div:nth-child(2)>div>div {
    font-size: 18px;
    font-weight: 600;
}

.plus-plmj_cardModal__3Ngix>div:nth-child(2)>div:nth-child(3) {
    padding-top: 0;
}

.plus-plmj_modal__SVz_R>div>div>button {
    font-weight: 600;
    width: 80px;
    height: 50px;
}

.plus-plmj_modal__SVz_R>div>div>button:nth-child(1):hover {
    color: var(--color-black);
    opacity: .6;
}

.plus-plmj_modal__SVz_R>div>div>button:nth-child(2) {
    background-color: var(--color-black);
}

.plus-plmj_modal__SVz_R>div>div>button:nth-child(2):hover {
    opacity: .6;
}

.plus-plmj_container__2dZjz {
    padding-bottom: 60px;
}

.plus-plmj_cardModal__3Ngix>div>div:last-child {
    height: unset;
}

.plus-plmj_filterIconMobile__1-4n0 {
    position: fixed !important;
    background-color: var(--color-black);
    bottom: 25px;
    right: 25px;
    width: 60px !important;
    height: 60px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
}

.plus-plmj_filterIconMobile__1-4n0 > svg > g line, 
.plus-plmj_filterIconMobile__1-4n0 > svg > g circle {
    stroke: var(--color-white);
}

.plus-plmj_activeFilters__2187L {
    width: .75rem;
    height: .75rem; 
    background-color: var(--color-call-action);
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50%;
}

@media only screen and (min-width: 376px){
    .plus-plmj_banner__2nS5U {
        background-position: -75px;
    }
}

@media only screen and (min-width: 447px) {
    .plus-plmj_banner__2nS5U img {
        left: -75px;
    }
}

@media only screen and (min-width: 481px) {
    .plus-plmj_banner__2nS5U img {
        top: -150px
    }
}

@media only screen and (min-width: 525px) {
    .plus-plmj_banner__2nS5U img {
        top: -175px
    }
}

@media only screen and (min-width: 622px) {
    .plus-plmj_banner__2nS5U img {
        top: -250px;
        width: 1200px
    }
}

@media (min-width: 769px) {
    .plus-plmj_banner__2nS5U img {
        left: -1vw;
        top: 0;
        width: 110vw;
        height: 400px;
        top: -50px;
    }
}

@media (min-width: 839px) {
    .plus-plmj_banner__2nS5U img {
        top: -50px;
    }
}

@media (min-width: 928px) {
    .plus-plmj_banner__2nS5U img {
        height: 350px;
        left: -5vw;
    }
}

@media (min-width: 1024px) {
    .plus-plmj_banner__2nS5U img {
        left: 0px;
        width: 100%;
        height: 1000px;
        top: -375px
    }

    .plus-plmj_filterIconMobile__1-4n0 {
        display: none;
    }
}

@media (min-width: 1200px) {
    .plus-plmj_banner__2nS5U {
        height: 400px;
    }
    .plus-plmj_banner__2nS5U img {
        height: 800px;
        width: 1300px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1300px) {
    .plus-plmj_banner__2nS5U img {
        height: 800px;
        width: 1400px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1400px) {
    .plus-plmj_banner__2nS5U img {
        height: 800px;
        width: 1500px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1500px) {
    .plus-plmj_banner__2nS5U {
        height: 375px;
    }
    .plus-plmj_banner__2nS5U img {
        height: 700px;
        width: 1600px;
        top: -200px;
        left: -20px;
    }
}

@media (min-width: 1600px) {
    .plus-plmj_banner__2nS5U {
        height: 375px;
    }
    .plus-plmj_banner__2nS5U img {
        height: 800px;
        width: 1700px;
        top: -290px;
        left: -20px;
    }
}

@media (min-width: 1700px) {
    .plus-plmj_banner__2nS5U {
        height: 375px;
    }
    .plus-plmj_banner__2nS5U img {
        height: 900px;
        width: 1900px;
        top: -350px;
        left: -20px;
    }
}

@media (min-width: 1880px) {
    .plus-plmj_banner__2nS5U {
        height: 375px;
    }
    .plus-plmj_banner__2nS5U img {
        height: 1200px;
        width: 2000px;
        top: -500px;
        left: -20px;
    }
}

@media (min-width: 1981px) {
    .plus-plmj_banner__2nS5U img {
        height: calc(100vw/1.7);
        width: calc(100vw + 200px);
        top: calc((100vw/3.5)*-1);
        left: calc(100vw/100*-3)
    }
}

@media (max-device-width: 419px) {
    .plus-plmj_cardModal__3Ngix {
        height: 95%;
    }

    .plus-plmj_cardModal__3Ngix .ant-modal-content {
        height: 100%;
    }

    .plus-plmj_cardModal__3Ngix .ant-modal-header {
        height: 54px;
    }

    .plus-plmj_cardModal__3Ngix .ant-modal-close,
    .plus-plmj_cardModal__3Ngix .ant-modal-header {
        border-bottom: solid 1px var(--color-medium-gray);
    }

    .plus-plmj_cardModal__3Ngix .ant-modal-body {
        background-color: var(--color-white);
        padding-bottom: 70px;
    }

    .plus-plmj_hasInstructions___-PzI .ant-modal-body {
        padding-bottom: 30px;
    }

    .plus-plmj_cardModal__3Ngix .ant-row-end {
        position: absolute;
        bottom: 0;
    }
}
.statistic_statistic__2WItZ {
    font-family: "DomaineDisplay";
    font-weight: 600;
    color: var(--color-black);
    font-size: 16px;
}

.modalBody_container__3WJ8Q {
    width: 100%;
}

.modalBody_container__3WJ8Q > div:not(:first-child) {
    margin-top: 15px;
}

.modalBody_descriptionContainer__ytbXL {
    margin-top: 24px !important;
}

.modalBody_title__Gl9qv {
    margin-top: 1rem;
}

.modalBody_icon__237x8 {
    justify-content: center;
    display: flex !important;
    align-items: flex-start;
    margin-top: 4px;
}

.modalBody_icon__237x8 svg {
    stroke: var(--color-black);
    width: 20px;
    height: 20px;
}

.modalBody_info__1FdUa {
    font-family: "GT-America";
    font-weight: 400;
    color: var(--color-tertiary) !important;
}

.modalBody_clickableInfo__1PFJS {
    cursor: pointer;
}

.modalBody_discount__1ySek {
    margin-top: calc(3rem + 1px) !important;
}

.modalBody_discount__1ySek > div:last-child span {
    font-size: 40px !important;
    color: var(--color-success) !important;
    line-height: 28px;
}

.modalBody_discountText__2286M > div:last-child span {
    display: block;
    font-size: 20px !important;
}

.modalBody_description__1o0P1 {
    font-family: "GT-America";
    font-weight: 300;
    color: var(--color-secondary) !important;
}

.modalBody_discount__1ySek > .modalBody_statisticText__3HlQ2 {
    font-size: 30px !important;
}

.modalBody_linkInfo__-Bg6O {
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@media (min-width: 420px){
    .modalBody_container__3WJ8Q {
        width: 48%;
    }

    .modalBody_container__3WJ8Q:nth-child(2) {
        margin-left: 4%;
    }

    .modalBody_discount__1ySek {
        margin-top: .3rem !important;
        align-items: flex-start;
    }

    .modalBody_discount__1ySek > div:nth-child(2) {
        padding-left: .5rem;
    }

    .modalBody_discountText__2286M {
        margin-top: 0 !important;
    }

    .modalBody_icon__237x8 {
        margin-top: 0.25rem;
    }
}
.modalFooter_buttonCancel__LncC0 and .modalFooter_buttonAccept__1K-UP {
    font-weight: 600;
    width: 80px;
    height: 50px;
}

.modalFooter_buttonAccept__1K-UP {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    height: 50px;
    margin-right: 0 !important;
}

.modalFooter_instructionsTitle__1Y-6C {
    font-family: 'GT-America';
    font-size: 14px;
    font-weight: 400 !important;
}

.modalFooter_instructions__24L8D, .modalFooter_instructions__24L8D * {
    font-family: "GT-America";
    font-weight: 300 !important;
    font-size: 14px !important;
    color: var(--color-secondary) !important;
    text-align: left;
}

.modalFooter_buttonContainer__37cPK {
    height: 50px;
    z-index: 1;
}

.modalFooter_divider__2ibiY {
    border-top: 0.5px solid #000000 !important;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width: 420px) {
    .modalFooter_buttonAccept__1K-UP and .modalFooter_buttonCancel__LncC0 {
        background-color: var(--color-black) !important;
        position: fixed;
        bottom: 0;
        z-index: 1000;
        height: 50px;
    }
    .modalFooter_buttonAccept__1K-UP {
        right: 0;
    }
    .modalFooter_buttonCancel__LncC0 {
        left: 0;
    }
    .modalFooter_buttonContainer__37cPK {
        width: 100%;
        height: 0px;
    }
    .modalFooter_instructions__24L8D {
        margin-bottom: 80px;
    }
    .modalFooter_divider__2ibiY {
        margin-right: 24px;
        margin-top: unset;
    }
}
.filtersMobile_drawer__1h5RV {
    display: flex;
    justify-content: center;
}

.filtersMobile_drawer__1h5RV > div:nth-child(2) {
    max-width: 500px;
}

.filtersMobile_drawer__1h5RV .ant-drawer-close {
    padding: 14px;
}

.filtersMobile_drawer__1h5RV .ant-drawer-content-wrapper {
    max-height: 700px;
}

.filtersMobile_headerTitle__3bopk {
    display: flex;
    align-items: center;
}

.filtersMobile_headerTitle__3bopk > svg {
    margin-right: 10px;
}

.filtersMobile_titleRadio__1z5qV {
    display: block;
}

.filtersMobile_radioGroup__22QWX > div {
    margin-top: .75rem;
}

.filtersMobile_titleCheckbox__mF21W {
    display: block;
}

.filtersMobile_checkboxGroup__4sit3 > label {
    margin: 9px 0;
}

.filtersMobile_tooltip__2HPUT {
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--color-black) !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.filtersMobile_slider__LM-x4 .ant-slider-step,
.filtersMobile_slider__LM-x4 .ant-slider-rail,
.filtersMobile_slider__LM-x4 .ant-slider-track {
    height: 2px;
}

.filtersMobile_slider__LM-x4 .ant-slider-dot {
    display: none;
}

.filtersMobile_slider__LM-x4 .ant-slider-handle {
    background-color: var(--color-black) !important;
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.filtersMobile_slider__LM-x4 .ant-slider-mark-text {
    margin-top: 5px;
}

.filtersMobile_buttonsContainer__1tJLI {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
}

.filtersMobile_buttonsContainer__1tJLI > div {
    width: 100%;
    display: flex;
}

.filtersMobile_buttonsContainer__1tJLI button {
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    height: 100%;
}

.filtersMobile_tertiary__3jjDo > span {
    color: var(--color-tertiary) !important;
}

@media (min-device-width: 500px) {
    .filtersMobile_drawer__1h5RV {
        display: flex;
        align-items: center;
    }

    .filtersMobile_drawer__1h5RV .ant-drawer-content-wrapper {
        position: static !important;
    }
}

@media (min-width: 1024px) {
    .filtersMobile_drawer__1h5RV {
        display: none;
    }
}
.intro_description__PjUAI {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .intro_description__PjUAI {
        font-size: 15px;
        font-weight: 400 !important;
        line-height: 20px
    }
 }

@media (min-width: 768px) {
    .intro_description__PjUAI {
        display: block;
        width: 80%;
        font-size: 18px;
        font-weight: 400 !important;
        line-height: 25px;
    }
}

.who-is-who_background__3b1zm{
    background-image: url(/static/media/whoIsWhoTeams.9123a361.jpg);
    background-size: cover;
    background-position: top;
}

.who-is-who_container__3vhBl {
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 50%;
    background: rgb(227,227,227);
    background: linear-gradient(0deg, rgba(227,227,227,1) 66%, rgba(0,0,0,0) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e3e3e3",endColorstr="#000000",GradientType=1);
    /* background-image: url("../../assets/images/svg/who-is-who-background.svg"); */
}

.who-is-who_intro__2mHVm {
    margin-bottom: 40px !important;
}

.who-is-who_searchBar__2_y_4 {
    margin-bottom: 40px !important;
}

.who-is-who_searchBar__2_y_4 svg {
    margin-left: 13px;
    margin-right: .5rem;
}

.who-is-who_filtersContainer__uLSlv {
    margin-bottom: 50px !important;
}

.who-is-who_paginationWho__20n2x > ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.who-is-who_paginationWho__20n2x > ul > li:nth-child(2) {
    width: auto;
}

.who-is-who_cutout__3r3om{
    height: 1000px;
    position: absolute;
    left: -100px;
    top: 200px;
    max-width: 100%;
}
.searchBar_container__2tohv .ant-input-affix-wrapper {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding-top: .75rem;
    padding-bottom: .75rem;
}

.searchBar_container__2tohv .ant-input-affix-wrapper:focus,
.searchBar_container__2tohv .ant-input-affix-wrapper:hover {
    box-shadow: none !important;
    border: none !important;
}

.searchBar_container__2tohv input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 14px;
}

@media (min-device-width: 768px) {
    .searchBar_container__2tohv input {
        font-size: 15px !important;
    }
}

@media (min-device-width: 1024px) {
    .searchBar_container__2tohv input {
        font-size: 18px !important;
    }
}
.filters_title__3ozI4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 18px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
}

.filters_titleUnselected__3pCmB {
    color: var(--color-light-gray-2) !important;
}

.filters_container__FmMY1 > div {
    margin: .5rem 0;
}

.filters_container__FmMY1 p {
    margin: 0;
    font-family: "DomaineDisplay";
    padding: 0.5rem 0 0.25rem 0;
}

.filters_dropdownRow__1P9d1 {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
}

.filters_dropdownRow__1P9d1 > div:first-child {
    max-width: 90%;
    flex: 1 1;
}

.filters_dropdownRowDisabled__BOK_S {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.filters_overlay__cyJkJ {
    width: -webkit-min-content;
    width: min-content;
    max-height: 300px;
}



.filters_overlay__cyJkJ > div {
    background-color: var(--color-white);
    margin-top: -4px;
    flex-direction: column;
    border: none !important;
    overflow: auto;
    max-height: 300px;
}

.filters_overlay__cyJkJ > div > label > span:first-child {
    display: none;
} 

.filters_overlay__cyJkJ label {
    border-bottom: 1px solid var(--color-light-gray);
    height: 52px;
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
}

.filters_overlay__cyJkJ label span {
    border: none !important;
}

.filters_overlay__cyJkJ label > span {
    line-height: 20px;
    width: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .filters_overlay__cyJkJ label:hover {
        background-color: var(--color-selection);
    }
  }
.cardWho_cardWithoutImage__2ud2e {
    background-color: var(--color-gray) !important;
    width: 100%;
    height: 100%;
}

.cardWho_cardWithImage__2z7xg {
    display: block;
    width: auto;
    height: auto;
}

.cardWho_cardWithImage__2z7xg .ant-card-body {
    position: absolute;
    background-color: #323232;
    opacity: 30%;
    top: 0;
    width: 100%;
    height: 100%;
}
.userDetail_container__3j04V {
    background-color: var(--color-black);
    padding: 15px;
    max-width: 100%;
}

.userDetail_container__3j04V > div {
    width: 100%;
}

.userDetail_title__3iuXe {
    color: var(--color-white) !important;
    font-size: 18px !important;
    line-height: 26px !important;
}
.userList_userListContainer__3nKE0 {
    margin: 0 auto 60px auto !important;
}

.userList_userListContainerLoading__3C94v {
    pointer-events: none;
    opacity: 0.6;
}

.userList_details__3AwKA {
    overflow: hidden;
    z-index: 11;
    position: absolute;
    width: 100%;
    max-height: 0;
    opacity: 0;
    transition: ease-in-out max-height 0.6s, cubic-bezier(0.215, 0.610, 0.355, 1) opacity 0.3s;
}

.userList_card__1A2J- .ant-card-body{
    transition: ease-in-out opacity 0.3s;
}

.userList_card__1A2J-:hover .ant-card-body{
    opacity: 0;
}

.userList_card__1A2J-:hover .userList_details__3AwKA {
    max-height: 800px;
    opacity: 1;
};


.userList_closeIcon__25DQr path {
    stroke: var(--color-white);
}

.userList_teamsContainer__RBLOO > div{
    height: 50px;
}

.userList_teamsContainer__RBLOO > div:last-child {
    display: flex;
    justify-content: flex-end;
}

.userList_officeContainer__3kCkw {
    margin-top: 10px;
    margin-bottom: 15px;
}

.userList_officeContainer__3kCkw > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.userList_officeContainer__3kCkw > div > .userList_department__2B8oB {
    margin: 0;
    color: var(--color-light-gray-2) !important;
    display: flex;
    flex-direction: column;
}

.userList_closeIcon__25DQr path {
    stroke: var(--color-white);
}

.userList_name__3fUrB {
    color: var(--color-white) !important;
}

.userList_jobTitle__rjzBc {
    color: var(--color-light-gray-2) !important;
    text-transform: uppercase;
}

.userList_department__2B8oB {
    font-family: "GT-America" !important;
    font-size: 12px;
    margin: 5px 0;
    overflow: hidden;
    display: flex;
    align-items: flex-center;
}

.userList_department__2B8oB > .userList_label__1FOn8 {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
    display: block;
    line-height: 170%;
    margin-right: 5px;
}

.userList_department__2B8oB > a {
    text-overflow: ellipsis;
    white-space:  nowrap;
    overflow: hidden;
}

.userList_department__2B8oB > .userList_comma__FmUAW:nth-child(2){
    display: none;
}

.userList_infoText__1XnxJ {
    max-width: 100%;
    display: block;
    color: var(--color-light-gray-2) !important;
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
}

.userList_link__2AhRb {
    -webkit-text-decoration: underline white 1px;
            text-decoration: underline white 1px;
    text-underline-offset: 2px;
    cursor: pointer;
}

.userList_divider__dUzh2 {
    border-top: 1px solid var(--color-light-gray-2) !important;
    margin: 12px 0 !important;
}

.userList_teamsIconLink__3u8s1 {
    display: flex;
    /* align-items: center; */
}

@media (min-device-width: 768px) {
    .userList_teamsContainer__RBLOO {
        position: static;
        width: calc(100% + 40px);
        text-transform: capitalize;
        bottom: 0;
        margin-left: -20px;
        margin-bottom: -20px;
        margin-top: 8px;
        padding: 9px 20px 14px 20px;
        background-color: var(--color-call-action) !important;
        cursor: pointer;
    }

    .userList_teamsContainer__RBLOO > div{
        height: 25px;
    }
    
    .userList_link__2AhRb {
        text-decoration: unset;
        text-underline-offset: unset;
    }

    .userList_link__2AhRb:hover {
        color: var(--color-white) !important;
        -webkit-text-decoration: underline white 1px;
                text-decoration: underline white 1px;
        text-underline-offset: 2px;
        cursor: pointer;
    }
}

@media (min-device-width: 1024px) and (max-device-width: 1089px) {
    .userList_microsoftLink__2Ipzp{
        font-size: 12px !important;
    }

    
}
.filtersList_container__3ELsV > div {
    position: relative;
    margin-bottom: 60px;
}

.filtersList_container__3ELsV > div > div:nth-child(2) {
    margin-top: 15px;
}

.filtersList_divider__eB9_I {
    position: absolute;
    width: 100vw;
    left: -20px;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 15px;
}

.filtersList_labelContainer__1MMTX {
    display: flex;
    align-items: center;
}

.filtersList_labelContainer__1MMTX svg {
    width: 20px;
    height: 20px;
}

.filtersList_labelContainer__1MMTX:hover {
    cursor: pointer;
}

.filtersList_labelContainer__1MMTX span {
    color: var(--color-black) !important;
}

.filtersList_mainLabel__39IQq h1 {
    margin-bottom: 0 !important;
}

.filtersList_resultsContainer__2pg22 {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.filtersList_resultsContainer__2pg22 > span {
    color: var(--color-tertiary) !important;
}

.filtersList_bar__3QjO3 {
    margin-right: 10px;
}

.filtersList_value__29rtu {
    margin-right: 10px;
}


.filtersList_removeIcon__3O3gX {
    margin-right: 10px;
    width: 6px !important;
    height: 6px !important;
}

@media screen and (min-width: 481px) {
    .filtersList_divider__eB9_I{
        left:-50px;
    }
}

@media screen and (min-width: 769px) {
    .filtersList_divider__eB9_I{
        left:-90px;
    }
}

@media screen and (min-width: 1350px){
    .filtersList_divider__eB9_I{
        left: calc((100vw - var(--main-container-max-width)) / -2)
    }
}
:root  {
    --color-black: #000;
    --color-white: #fff;

    --color-danger: #f66168;
    --color-disabled: #d5d5d5;
    --color-success: #8AD6AF;
    --color-warning: #FAC362;
    --color-call-action: #A0C6D2;

    --color-secondary: #666666;
    --color-tertiary: #9A9A9A;

    --color-selection: #f0f0f0;
    --color-light-gray: #f2f2f2;
    --color-light-gray-2: #9E9E9E;
    --color-medium-gray: #d5d5d5;
    --color-gray: #808080;
    --color-dark-gray: #484848;

    --color-mark: #feffe6;
    --antd-wave-shadow-color: #000;
}
@font-face {
    font-family: "DomaineDisplay";
    font-weight: 400;
    src: url(/static/media/DomaineDisplayWeb-Regular.db08da3e.woff);
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 500;
    src: url(/static/media/DomaineDisplayWeb-Medium.afa42e1e.woff);
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 600;
    src: url(/static/media/DomaineDisplayWeb-Semibold.eef8e40f.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 300;
    src: url(/static/media/GT-America-Extended-Light.1a54cec1.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 400;
    src: url(/static/media/GT-America-Extended-Regular.fe3fe671.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 500;
    src: url(/static/media/GT-America-Extended-Medium.0444e71f.woff);
}

@font-face {
    font-family: "GT-America";
    font-weight: 600;
    src: url(/static/media/GT-America-Extended-Bold.17b18696.woff);
}

:root {
    --main-container-max-width: 1170px;
    --header-height: 58px;
    --internal-padding: 0.2rem;
    --regular-padding: 0.5rem;
    --font-size-title-page: 40px;
    --font-size-subtitle: 33px;
    --font-size-lead: 21px;
    --font-size-title: 21px;
    --font-size-body: 15px;
    --font-size-page-description: 15px;
    --font-size-content: 15px;
    --line-height-title: 48px;
    --line-height-subtitle: 40px;
    --line-height-lead: 29px;
    --line-height-content: 24px;
    --line-height-page-description: 21px;
    --line-height-body-and-title: 1.4;
}

@media (min-width: 992px) {
    :root {
        --font-size-page-description: 18px;
        --line-height-page-description: 26px;
    }
}
html,
body,
div#root {
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "GT-America";
    font-weight: 300;
    margin: 0;
    min-height: 100%;
}

h1 {
    font-family: "DomaineDisplay";
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
    margin-bottom: 25px;
}
h2 {
    font-family: "DomaineDisplay";
    font-size: 30px;
    line-height: 38px;

    font-weight: 500;
    margin-bottom: 25px;
}
h3 {
    font-family: "DomaineDisplay";
    font-size: 26px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 25px;
}
h4 {
    font-family: "DomaineDisplay";
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 25px;
}
h5 {
    font-family: "DomaineDisplay";
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 25px;
}
h6 {
    font-family: "DomaineDisplay";
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 25px;
}
hr {
    margin: 15px auto 40px;
    border-top: 1px solid var(--color-medium-gray);
}
strong {
    font-weight: 500;
}

a {
    color: var(--color-secondary);
    -webkit-text-decoration: underline 1px solid var(--color-secondary);
            text-decoration: underline 1px solid var(--color-secondary);
    text-underline-offset: 4px;
}

a:hover {
    color: var(--color-black);
    -webkit-text-decoration: underline 1px solid var(--color-black);
            text-decoration: underline 1px solid var(--color-black);
}

.noDecoration {
    text-decoration: none;
}

.noDecoration:hover {
    text-decoration: none;
}

.noDecoration:hover > h1 {
    color: var(--color-black);
    -webkit-text-decoration: underline 1px solid var(--color-black);
            text-decoration: underline 1px solid var(--color-black);
}

p {
    font-family: "GT-America";
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: var(--color-secondary);
    margin-bottom: 25px;
}

li {
    font-family: "GT-America";
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    margin-bottom: 10px;
}

button {
    padding: 10px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 25px;
}

.ql-align-right {
    text-align: right;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.darkTextHeader {
    filter: invert(0);
}

.lightTextHeader {
    filter: invert(1);
}

#mobileCarouselArrowRight {
    right: 0 !important;
    top: -65px !important;
}

#mobileCarouselArrowLeft {
    left: unset !important;
    right: 75px !important;
    top: -65px !important;
}

#desktopCarouselArrowLeft {
    left: -50px !important;
}

#desktopCarouselArrowRight {
    right: -50px !important;
}

@media (min-width: 768px) {
    h1 {
        font-size: 54px;
        line-height: 64px;
    }

    h2 {
        font-size: 36px;
        line-height: 44px;
    }

    p {
        font-size: 18px;
        line-height: 26px;
    }
}

/* Workaround to change the default ant color, customizing webpack breaks the PWA */
/* More info here: https://ant.design/docs/react/customize-theme */

.ant-typography a {
    color: black !important
}

.ant-input-affix-wrapper-focused {
    border-color: black !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: black !important;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    border-color: #0d0c0c;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
}

.ant-spin-dot-item {
    background-color: black !important
}

.ant-spin {
    color: black !important
}
.sectionTitle_title__gaYlH {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    border-top: 3px solid black;
    padding-top: 30px;
    margin-top: -32px;
    font-size: 36px !important;
    line-height: 44px !important;
}

.sectionTitle_overline__2TkyG{
    border-top: thin solid var(--color-medium-gray);
    max-width: 100% !important;
}

.sectionTitle_wrapper__28-gh{
    margin-top: 30px !important;
}
.backgroundBanner_background__1_I0b{
    background-image: url(/static/media/whoIsWhoTeams.9123a361.jpg);
    background-size: 240%;
}

.backgroundBanner_cutoutLeft__3LD_m{
    width: 1000px !important;
    position: absolute !important;
    left: -500px !important;
}

.backgroundBanner_loading__3pVhY{
    background: none !important;
}
.departmentsList_title__1Vrnm {
    font-weight: 400 !important;
}


.departmentsList_loading__1AXrl {
    pointer-events: none;
    opacity: 0.6;
}

.departmentsList_titleUnselected__3zPYi {
    color: var(--color-light-gray-2) !important;
}

.departmentsList_subTitle__1JIfd {
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    text-underline-offset: 13px;
    font-weight: normal !important;
}

.departmentsList_description__1Ug9g > span{
    color: var(--color-black) !important;
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    text-align: start;
}

.departmentsList_description2__3ZjVa {
    margin-top: 20px;
}

.departmentsList_description2__3ZjVa > span{
    font-family: "GT-America";
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.departmentsList_descriptionsContainer__34zty{
    margin-top: 70px !important;
}

.departmentsList_departmentContainer__3hCQS{
    border-bottom: solid 1px var(--color-black);
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.departmentsList_departmentContainer__3hCQS * {
    z-index: 1;
}
.departmentsList_departmentContainer__3hCQS::before {
    content: "";
    width: 0px;
    height: 100%;
    background-color: var(--color-black);
    position: absolute;
    left: 0;
    top: 0;
    transition: width .2s ease-in-out;
    z-index: 0;
}

.departmentsList_departmentContainer__3hCQS:hover::before {
    width: 100%;
}

.departmentsList_selectedDepartment__2QXif{
    background-color: var(--color-black);
}
.departmentsList_selectedDepartment__2QXif h1{
    color: var(--color-white) !important;
}

.departmentsList_departmentContainer__3hCQS:hover h1 {
    color: var(--color-white) !important;
}

.departmentsList_departmentContainer__3hCQS > svg {
    display: none;
    margin-right: 15px;
}

.departmentsList_departmentContainer__3hCQS:hover svg {
    display: block !important;
}

.departmentsList_departmentContainer__3hCQS > svg > path {
    fill: white;
}


.departmentsList_departmentContainer__3hCQS > h1 {
    margin-bottom: 0;
    margin-left: 10px;
}

.departmentsList_department__Kl1Js {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.departmentsList_departmentRow__1dFh8 {
    margin-bottom: 40px !important;
    margin-top: 80px !important;
}

.departmentsList_departmentRow__1dFh8 > div:not(:nth-child(3n + 1)) {
    margin-left: calc(300%/24/2);
}

.departmentsList_buttonContainer__115sA{
    justify-content: right;
}

.departmentsList_seeMoreButton__2kPkB {
    background-color: rgba(0,0,0,0) !important;
}

.departmentsList_seeMoreButton__2kPkB:hover {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}

.departmentsList_subTitleGreyed__2Eh6R{
    text-align: center;
    color: var(--color-tertiary) !important;
    font-weight: normal !important;
}

.departmentsList_container__2GfvZ>div {
    margin: .5rem 0;
}

.departmentsList_container__2GfvZ p {
    margin: 0;
    font-family: "DomaineDisplay";
    padding: 0.5rem 0 0.25rem 0;
}

.departmentsList_dropdownRow__3VNtp {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 50px;
}

.departmentsList_dropdownRow__3VNtp > div >h1 {
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 600 !important;
}

.departmentsList_dropdownRow__3VNtp>div:first-child {
    max-width: 90%;
    flex: 1 1;
}

.departmentsList_dropdownRow__3VNtp h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.departmentsList_dropdownRowDisabled__2zMux {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.departmentsList_dropdownRow2__2r56G h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5em;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
}
.departmentsList_dropdownRow2__2r56G {
    border-bottom: solid 1px var(--color-black);
    border-top: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 50px;
}

.departmentsList_dropdownRow2__2r56G>div:first-child {
    max-width: 90%;
    flex: 1 1;
}

.departmentsList_overlay__3peLw {
    width: auto !important;
}

.departmentsList_overlay__3peLw>div {
    background-color: var(--color-white);
    margin-top: -4px;
    display: flex;
    flex-direction: column;
    border: none !important;
}

.departmentsList_overlay__3peLw>div>label>span:first-child {
    display: none;
}

.departmentsList_overlay__3peLw label {
    border: none !important;
    height: 42px;
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
}

.departmentsList_overlay__3peLw label span {
    border: none !important;
}

.departmentsList_overlay__3peLw label>span {
    width: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .departmentsList_overlay__3peLw label:hover {
        background-color: var(--color-selection);
    }
}

.departmentsList_menuItem__2C2LB p, .departmentsList_department__Kl1Js {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

@media (min-width: 480px){
    .departmentsList_menuItem__2C2LB p, .departmentsList_department__Kl1Js {
       font-size: 15px !important;
    }
    
}

@media (min-device-width: 768px) {
    .departmentsList_dropdownRow__3VNtp h1 {
        font-size: 18px !important;
    }

    .departmentsList_description__1Ug9g > span{
        color: var(--color-black) !important;
    }
    
    .departmentsList_description2__3ZjVa > span{
        font-family: "GT-America";
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
    }

    .departmentsList_description2__3ZjVa {
        margin-top: unset;
    }

    .departmentsList_descriptionsContainer__34zty {
        margin-top: 75px
    }
}

@media screen and (min-device-width: 1024px) {
    .departmentsList_dropdownRow__3VNtp h1 {
        font-size: 24px !important;
    }
}



.intro_description__1mCMK {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .intro_description__1mCMK {
        font-size: 15px;
        font-weight: 400 !important;
        line-height: 20px
    }
 }

@media (min-width: 768px) {
    .intro_description__1mCMK {
        display: block;
        width: 80%;
        font-size: 18px;
        font-weight: 400 !important;
        line-height: 25px;

    }
}

.teamsDescription_description__3WRji > span *, .teamsDescription_description__3WRji > span {
    color: var(--color-black) !important;
    font-size: var(--font-size-lead) !important;
    line-height: var(--line-height-lead) !important;
    font-weight: 400;
    text-align: start;
    font-family: "DomaineDisplay";
}

.teamsDescription_description2__2LC42 {
    margin-top: 20px;
}

.teamsDescription_description2__2LC42 > span{
    font-family: "GT-America";
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 300 !important;
}

.teamsDescription_descriptionsContainer__2W_EQ{
    margin-top: 80px !important;
}

@media (min-device-width: 768px) {

    .teamsDescription_description__3WRji > span{
        color: var(--color-black) !important;
    }
    
    .teamsDescription_description2__2LC42 > span{
        font-family: "GT-America";
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
    }

    .teamsDescription_description2__2LC42 {
        margin-top: unset;
    }

    .teamsDescription_descriptionsContainer__2W_EQ {
        margin-top: 75px
    }
}
.subTitleButton_subTitle__17H3c, .subTitleButton_subTitle__17H3c p{
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 400 !important;
    font-family: 'DomaineDisplay';
    margin-top: -15px;
    cursor: pointer;
    -webkit-text-decoration-color: transparent;
            text-decoration-color: transparent;
    transition: ease-in-out text-decoration-color 300ms, color 300ms;
}

.subTitleButton_selected__EK36l, .subTitleButton_selected__EK36l p{
    color: var(--color-black);
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 13px;
    -webkit-text-decoration-color: var(--color-black);
            text-decoration-color: var(--color-black);
}

.subTitleButton_notSelected__19WF1, .subTitleButton_notSelected__19WF1 p{
    color: var(--color-tertiary);
}

.subTitleButton_notSelected__19WF1:hover, .subTitleButton_notSelected__19WF1:hover p {
    color: var(--color-black);
    text-decoration: underline;
    -webkit-text-decoration-skip-ink: none;
            text-decoration-skip-ink: none;
    text-decoration-thickness: 1px;
    text-underline-offset: 13px;
    -webkit-text-decoration-color: var(--color-tertiary);
            text-decoration-color: var(--color-tertiary);
}

.subTitleButton_disabled__33wUH, .subTitleButton_disabled__33wUH p{
    opacity: 0.5;
    cursor:not-allowed;
}
.twoTabs_dropdownRow__2aQMR {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 150px;
}

.twoTabs_dropdownRow__2aQMR > div >h1 {
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 600 !important;
}

.twoTabs_dropdownRow__2aQMR>div:first-child {
    max-width: 90%;
    flex: 1 1;
}

.twoTabs_dropdownRow__2aQMR h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.twoTabs_dropdownIcon__21Jc0 {
    margin-bottom: 0.5em;
}

.twoTabs_tabs__12pwr {
    text-align: center;
}

@media (min-device-width: 768px) {
    .twoTabs_dropdownRow__2aQMR h1 {
        font-size: 18px !important;
    }
}

@media screen and (min-device-width: 1024px) {
    .twoTabs_dropdownRow__2aQMR h1 {
        font-size: 24px !important;
    }
}
.twoTextColumns_description__3BGed>span *,
.twoTextColumns_description__3BGed>span {
    color: var(--color-black) !important;
    font-size: var(--font-size-lead) !important;
    line-height: var(--line-height-lead) !important;
    font-weight: 400;
    text-align: start;
    font-family: "DomaineDisplay";
    color: #444444 !important;
}

.twoTextColumns_description2__hOo8Y {
    margin-top: 20px;
}

.twoTextColumns_description2__hOo8Y>span,
.twoTextColumns_description2__hOo8Y>span * {
    font-family: "GT-America";
    font-size: 16px !important;
    line-height: 24px !important;
    font-weight: 300 !important;
}

.twoTextColumns_descriptionsContainer__1CpGE {
    margin-top: 80px !important;
}

@media (min-device-width: 768px) {

    .twoTextColumns_description__3BGed>span {
        color: var(--color-black) !important;
    }

    .twoTextColumns_description2__hOo8Y>span,
    .twoTextColumns_description2__hOo8Y>span * {
        font-family: "GT-America";
        font-size: var(--font-size-content) !important;
        line-height: var(--line-height-content) !important;
        font-weight: 300 !important
    }

    .twoTextColumns_description2__hOo8Y {
        margin-top: unset;
    }

    .twoTextColumns_descriptionsContainer__1CpGE {
        margin-top: 75px
    }
}
.userList_userListContainer__3TZS7 {
    margin-top: 32px !important;
}

.userList_card__2CaZj {
    background: unset;
    border: unset;
}

.userList_card__2CaZj > div {
    padding : unset
}

.userList_name__YpMtN {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 10px !important;
}

.userList_name__YpMtN:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.userList_titleContainer__3gu44 {
    border-bottom: 1px solid var(--color-black);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.userList_titleContainer__3gu44 > {
    margin-top: 60px;
}

.userList_titleContainer__3gu44 span {
    text-transform: uppercase;
}

.userList_usersContainer__24N75 > div:not(:first-child) {
    margin-top: 40px;
}

.userList_cardContainer__3eBF9{
    margin-top: 15px !important;
    margin-bottom: 40px !important;
}

.userList_infoContainer__im5nj {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userList_infoContainer__im5nj > div:first-child {
    max-height: 80px;
    max-width: 70px;
}

.userList_infoContainer__im5nj > div:nth-child(2) {
    width: 100%;
}

.userList_label__38Cg1 {
    text-transform: uppercase;
}

.userList_info__1pwq8 {
    width: 100% !important;
    margin-top: 0px;
}

.userList_chevron__1VsHX {
    transform: rotate(-90deg);
}

.userList_teamsContainer__25lk1 {
    background-color: var(--color-call-action);
    padding: 9px 15px;
    margin-top: 15px;
    border-bottom: 2px solid var(--color-black);
    z-index: 1;
}

.userList_microsoftLink__16Gf_ {
    text-transform: capitalize;
}

.userList_chatIcon__1_6pe {
    display: flex;
    justify-content: flex-end;
}

.userList_chatIcon__1_6pe > a {
    display: flex;
    align-items: center;
}

.userList_emailContainer__O7n7f {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userList_emailContainer__O7n7f a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.userList_thumbnail__3c3LD {
    display: none;
    height: 80px;
    width: 70px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
}

.userList_imgPlaceholder__2A3CY {
    background-color: var(--color-disabled);
    opacity: .5;
}

.userList_seeMoreButton__nYD5I{
    right: 0;
    position: absolute;
}

@media (min-device-width: 576px) {
    .userList_userListContainer__3TZS7 {
        margin-top: 80px !important;
    }

    .userList_usersContainer__24N75 > div:not(:first-child) {
        margin-top: 0;
    }

    .userList_usersContainer__24N75 > div {
        margin-top: 20px !important;
    }
}

@media (min-device-width: 796px) {
    .userList_infoContainer__im5nj > div:first-child {
        margin-right: 20px;
    }

    .userList_infoContainer__im5nj > div:nth-child(2) {
        width: calc(100% - 90px);
    }

    .userList_thumbnail__3c3LD {
        display: block;
    }
}

@media (min-device-width: 992px) {
    .userList_usersContainer__24N75 > div:not(:nth-child(3n + 1)) {
        margin-left: calc(300% / 24 / 2)
    }
    .userList_infoContainer__im5nj {
        padding-bottom: 15px;
    }

    .userList_teamsContainer__25lk1 {
        position: absolute;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        overflow: hidden;
        width: 100%;
        margin-top: 0;
        transition: all 0.2s ease-out;
    }

    .userList_teamsContainer__25lk1 > div {
        margin: 9px 0;
    }

    .userList_teamsContainer__25lk1 > div:nth-child(2) a {
        margin-right: 15px;
    }

    .userList_teamsContainer__25lk1 > div:first-child a {
        margin-left: 15px;
    }

    .userList_cardContainer__3eBF9:hover .userList_teamsContainer__25lk1,
    .userList_teamsContainer__25lk1:hover {
        max-height: 50px;
        border-bottom: 2px solid var(--color-black);
        transition: all .2s ease-in;
    }

    .userList_chevron__1VsHX {
        display: none;
    }

    .userList_card__2CaZj:hover .userList_chevron__1VsHX {
        display: inline;
        cursor: pointer;
    }
}
.teams_container__3MdVI {
    padding-bottom: 60px;
}

.teams_spinner__gez12 {
    margin-top: 72px !important;
}

/* BANNER */
.teams_banner__1wVGd {
    height: 300px;
    width: 100vw;
    position: absolute;
    padding: 0 !important;
    max-width: none !important;
    overflow: hidden;
}

.teams_banner__1wVGd img {
    position: absolute;
    width: 800px;
    height: 600px;
    left: -140px;
    top: -225px;
}

.teams_sectionLine__1OaeI{
    margin-top: 100px !important;
}

@media only screen and (min-width: 376px){
    .teams_banner__1wVGd {
        background-position: -75px;
    }
}

@media only screen and (min-width: 447px) {
    .teams_banner__1wVGd img {
        left: -75px;
    }
}

@media only screen and (min-width: 481px) {
    .teams_banner__1wVGd img {
        top: -150px
    }
}

@media only screen and (min-width: 525px) {
    .teams_banner__1wVGd img {
        top: -175px
    }
}

@media only screen and (min-width: 622px) {
    .teams_banner__1wVGd img {
        top: -250px;
        width: 1200px
    }
}

@media (min-width: 769px) {
    .teams_banner__1wVGd img {
        left: -1vw;
        top: 0;
        width: 110vw;
        height: 400px;
        top: -50px;
    }
}

@media (min-width: 839px) {
    .teams_banner__1wVGd img {
        top: -50px;
    }
}

@media (min-width: 928px) {
    .teams_banner__1wVGd img {
        height: 350px;
        left: -5vw;
    }
}

@media (min-width: 1024px) {
    .teams_banner__1wVGd img {
        left: 0px;
        width: 100%;
        height: 1000px;
        top: -375px
    }

    .teams_filterIconMobile__1UVMs {
        display: none;
    }
}

@media (min-width: 1200px) {
    .teams_banner__1wVGd {
        height: 400px;
    }
    .teams_banner__1wVGd img {
        height: 800px;
        width: 1300px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1300px) {
    .teams_banner__1wVGd img {
        height: 800px;
        width: 1400px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1400px) {
    .teams_banner__1wVGd img {
        height: 800px;
        width: 1500px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1500px) {
    .teams_banner__1wVGd {
        height: 375px;
    }
    .teams_banner__1wVGd img {
        height: 700px;
        width: 1600px;
        top: -200px;
        left: -20px;
    }
}

@media (min-width: 1600px) {
    .teams_banner__1wVGd {
        height: 375px;
    }
    .teams_banner__1wVGd img {
        height: 800px;
        width: 1700px;
        top: -290px;
        left: -20px;
    }
}

@media (min-width: 1700px) {
    .teams_banner__1wVGd {
        height: 375px;
    }
    .teams_banner__1wVGd img {
        height: 900px;
        width: 1900px;
        top: -350px;
        left: -20px;
    }
}

@media (min-width: 1880px) {
    .teams_banner__1wVGd {
        height: 375px;
    }
    .teams_banner__1wVGd img {
        height: 1200px;
        width: 2000px;
        top: -500px;
        left: -20px;
    }
}

@media (min-width: 1981px) {
    .teams_banner__1wVGd img {
        height: calc(100vw/1.7);
        width: calc(100vw + 200px);
        top: calc((100vw/3.5)*-1);
        left: calc(100vw/100*-3)
    }
}

.highlightedEvents_sectionTitle__-EnFy {
    margin-top: 30px;
}

.highlightedEvents_sectionTitle__-EnFy > h1{
    font-weight: 400;
    margin: 0;
    font-size: 36px !important;
    line-height: 44px;
}

.highlightedEvents_divider__2kFcI {
    margin: 15px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.highlightedEvents_seeMore__2sf0t button {
    padding-left: 22px;
    padding-right: 22px;
}

.highlightedEvents_carousel__1QAJN .slick-disabled svg path {
    stroke: var(--color-light-gray-2);
}

.highlightedEvents_arrowNext__3Flt9 {
    transform: rotate(180deg);
}

.highlightedEvents_containerLoading__YXxIE {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.highlightedEvents_listContainer__18CbY {
    width: 100%;
}

@media (min-width: 576px) {
    .highlightedEvents_cardContainer__1Ge6u:nth-child(2n + 1) {
        margin-left: calc(100%/12);   
    }
}

@media (min-width: 768px) {
    .highlightedEvents_sectionTitle__-EnFy {
        margin-top: 60px;
    }

    .highlightedEvents_divider__2kFcI {
        width: 100vw;
        margin-left: -50px;
    }

    .highlightedEvents_cardContainer__1Ge6u {
        margin-left: 0 !important;   
    }

    .highlightedEvents_cardContainer__1Ge6u:nth-child(2n + 1) {
        margin-left: 0;   
    }

    .highlightedEvents_cardContainer__1Ge6u:not(:first-child) {
        margin-left: calc(300%/24/2);   
    }

    .highlightedEvents_carousel__1QAJN .slick-prev {
        left: -35px;
    }
    
    .highlightedEvents_carousel__1QAJN .slick-next {
        right: -35px;
    }

    .highlightedEvents_listContainer__18CbY > div:not(:first-child) {
        margin-left: calc(((100% / 24) * 3) / 2 ) !important;
    }
}

@media screen and (min-width: 769px) {
    .highlightedEvents_divider__2kFcI {
        margin-left: -90px;
    }

    .highlightedEvents_carousel__1QAJN .slick-prev {
        left: -50px;
    }
    
    .highlightedEvents_carousel__1QAJN .slick-next {
        right:  calc(-50px + 1% );
    }
}

@media (min-width: 1200px) {
    .highlightedEvents_listContainer__18CbY > div:not(:first-child) {
        margin-left: 0;
        margin-left: calc(((100% / 24) * 4) / 3 ) !important;
    }
}

@media screen and (min-width: 1350px) {
    .highlightedEvents_divider__2kFcI {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}




.highlightCard_subscription__1AOTw {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.highlightCard_subscription__1AOTw > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.highlightCard_subscription__1AOTw > span {
    margin-left: 10px;
    font-size: 13px !important;
    font-weight: 500;
}

.highlightCard_subscription__1AOTw button {
    display: none;
    border-radius: 0 !important;
    transition: unset !important;
}

.highlightCard_infoContainer__1k6N9 {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.highlightCard_infoContainer__1k6N9 > h1 {
    margin: 5px 0 0 0 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.highlightCard_infoContainer__1k6N9 .highlightCard_category__2kHaR span {
    text-transform: uppercase;
}

.highlightCard_infoContainer__1k6N9 .highlightCard_category__2kHaR {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.highlightCard_infoContainer__1k6N9 .highlightCard_category__2kHaR div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.highlightCard_infoContainer__1k6N9 > span:nth-child(3) {
    margin-top: 10px;
    font-weight: 300;
    color: var(--color-secondary) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.highlightCard_infoContainer__1k6N9 > span:last-child{
    margin-top: 10px;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.highlightCard_card__VK0pL {
    width: 100%;
    border: none;
    cursor: pointer;
}

.highlightCard_card__VK0pL > .ant-card-body {
    padding: 0;
}

.highlightCard_card__VK0pL > .ant-card-cover,
.highlightCard_card__VK0pL > .ant-card-cover > img {
    height: 150px;
}

.highlightCard_bodyContainer__3-ncB {
    flex-flow: column;
}

.highlightCard_cover__1fzWz {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.highlightCard_upperTick__IyI0Q {
    position: absolute;
    right: 12px;
    top: 10px;
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.highlightCard_upperTick__IyI0Q svg{
    stroke-width: 2px;
}

@media (min-width: 768px) {

    .highlightCard_subscription__1AOTw button {
        display: block;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 0;
    }

    .highlightCard_userAdded__NsJig {
        height: 40px;
        min-height: 40px;
    }
}

@media (hover: hover) and (min-width: 768px) {
    .highlightCard_subscription__1AOTw button:hover {
        background-color: var(--color-black) !important;
        color: var(--color-white) !important;
    }
  }
.intro_intro__Nzoa_ {
    margin-top: 58px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    background-color: transparent;
    max-width: none !important;
    position: relative;
    background-image: url(/static/media/agenda.acae30cd.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.intro_intro__Nzoa_ > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro_intro__Nzoa_ h1{
    margin: 0px !important;
    margin-bottom: 20px !important;
}

.intro_intro__Nzoa_ > div:last-child {
    display: flex;
    flex-direction: column;
}

.intro_loading__1vvny{
    background-image: none !important;
}

.intro_pagination__2yWmm .intro_phoneBottomReachedMessage__3I6ob {
    display: none;
}

.intro_intro__Nzoa_ > div > span {
    font-family: "GT-America";
    font-size: var(--font-size-page-description) !important;
    line-height: var(--line-height-page-description) !important;
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
}

.intro_iconContainer__3HHSY {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.intro_iconContainer__3HHSY > svg:first-child {
    margin-left: -135px;
}

.intro_iconContainer__3HHSY > svg:last-child {
    display: none;
}

.intro_intro__Nzoa_ {
    margin-bottom: 40px !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .intro_intro__Nzoa_ > div > span {
       font-size: 15px !important;
       font-weight: 400 !important;
       line-height: 20px !important;
    }
 }

@media (min-width: 481px) {
    .intro_iconContainer__3HHSY > svg:first-child {
        margin-left: -70px;
    }
}

@media (min-width: 768px) {
    .intro_intro__Nzoa_ > div > h1 {
        -webkit-margin-before: unset !important;
                margin-block-start: unset !important;
    }

    .intro_iconContainer__3HHSY > svg:first-child {
        margin-left: -50px;
    }

    .intro_intro__Nzoa_ > div > span {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 25px !important;
      }
}

@media (min-width: 769px) {
    .intro_intro__Nzoa_ {
        padding-top: 145px !important;
        margin-top: 0 !important;
    }
}

@media (min-width: 992px){
    .intro_intro__Nzoa_ > div > span:last-child {
        margin-bottom: 40px;
    }
}

@media (min-width: 1200px) {
    .intro_iconContainer__3HHSY > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .intro_iconContainer__3HHSY > svg:first-child {
        margin-left: -20px !important;
    }
}

@media (min-width: 2340px) {
    .intro_intro__Nzoa_ {
        display: block;
    }
}
.upcomingEvents_container__3f657 {
    margin-top: 60px !important;
}

.upcomingEvents_sectionTitle__2fmQJ h1 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 0;
}

.upcomingEvents_divider__25_8e {
    margin: 15px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.upcomingEvents_arrowNext__1onAr {
    transform: rotate(180deg);
}

.upcomingEvents_carousel__1xvja .slick-disabled svg path {
    stroke: var(--color-light-gray-2);
}

.upcomingEvents_containerLoading__D2l5e {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

@media (min-width: 576px) {
    .upcomingEvents_container__3f657 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .upcomingEvents_cardContainer__1-wIj {
        width: 48% !important;
        max-width: 48% !important;
    }
}

@media (min-width: 768px) {
    .upcomingEvents_divider__25_8e {
        width: 100vw;
        margin-left: -50px;
    }

    .upcomingEvents_cardContainer__1-wIj {
        width: 100% !important;
        max-width: none !important;
    }

    .upcomingEvents_cardContainer__1-wIj:nth-child(2n) {
        margin-left: 15px;
    }

    .upcomingEvents_carousel__1xvja .slick-prev {
        left: -35px;
    }
    
    .upcomingEvents_carousel__1xvja .slick-next {
        right: -35px;
    }

    .upcomingEvents_container__3f657 {
        margin-top: 120px !important;
    }

    .upcomingEvents_containerLoading__D2l5e > div:last-child {
        pointer-events: none;
        opacity: 0.6;
        background-color: var(--color-disabled);
    }

    .upcomingEvents_listContainer__3plPO {
        display: flex;
        width: 100%;
    }

    .upcomingEvents_listContainer__3plPO > div:not(:first-child) {
        margin-left: calc((300% / 24) / 2) !important;
    }
}

@media screen and (min-width: 769px) {
    .upcomingEvents_divider__25_8e {
        margin-left: -90px;
    }

    .upcomingEvents_carousel__1xvja .slick-prev {
        left: -50px;
    }
    
    .upcomingEvents_carousel__1xvja .slick-next {
        right:  calc(-50px + 1% );
    }
}

@media screen and (min-width: 992px) {
    .upcomingEvents_cardContainer__1-wIj {
        width: calc(100% - 15px) !important;
        max-width: none !important;
    }

    .upcomingEvents_cardContainer__1-wIj:nth-child(2n) {
        margin-left: 0;
    }

    .upcomingEvents_cardContainer__1-wIj:not(:nth-child(3n + 1)) {
        margin-left: 15px !important;
    }
}

@media (min-width: 1200px) {
    .upcomingEvents_listCardContainer__Nt-vN {
        width: 24%;
        flex: unset;
    }

    .upcomingEvents_listContainer__3plPO > div:not(:first-child) {
        margin-left: 0 !important;
        margin-left: calc(4% / 3) !important;
    }
}

@media screen and (min-width: 1350px) {
    .upcomingEvents_divider__25_8e {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}
.upcomingCard_imageContainer__1QwuF {
    width: 76px;
}

.upcomingCard_title__1odW2 {
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: 100%;
    overflow: hidden;
}

.upcomingCard_card__2Lgr8 img {
    object-fit: cover;
    width: 76px;
    height: 76px;
    object-position: 50% 50%;
}

.upcomingCard_infoContainer__2Zp2E {
    overflow: hidden;
    margin-left: 15px;
    width: calc(100% - 76px - 15px);
}

.upcomingCard_infoContainer__2Zp2E h1 {
    font-size: 16px !important;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
}

.upcomingCard_infoContainer__2Zp2E .upcomingCard_category__26UBi {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.upcomingCard_infoContainer__2Zp2E .upcomingCard_category__26UBi span {
    text-transform: uppercase;
}

.upcomingCard_infoContainer__2Zp2E .upcomingCard_category__26UBi div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.upcomingCard_date___KVwm {
    display: block;
    font-size: 13px !important;
    margin-top: 10px;
}

.upcomingCard_tick__1hLnZ {
    position: absolute;
    left: 26px;
    top: 26px;
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 576px) {
    .upcomingCard_infoContainer__2Zp2E {
        /* 76px - img width */
        /* 15px - margin left width */
        width: calc(100% - 76px - 15px);
    }
}

@media (min-width: 768px) {
    .upcomingCard_cardContainer__1TB5C {
        width: 100%;
    }
}
.allEvents_container__2JRqR {
    margin-top: 100px !important;
    display: block;
}

.allEvents_container__2JRqR > div > div >.ant-dropdown-trigger {
    border-bottom: 2px solid var(--color-black);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.allEvents_dropdownTitle__3yE4b {
    font-weight: 400;
    display: block;
    font-size: 30px;
    font-family: 'DomaineDisplay';
}

.allEvents_overlay__3DisU {
    background-color: var(--color-white);
    padding: 0;
    margin-top: -4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.allEvents_overlay__3DisU > div > label {
    padding: 14px 0;
    width: 100%;
    margin-right: 0;
}

.allEvents_overlay__3DisU > div > label:hover {
    background-color: #F0F0F0;
}

.allEvents_overlay__3DisU label > span {
    padding: 0;
}

.allEvents_overlay__3DisU > div > label > .ant-radio{
    display: none;
}

.allEvents_radioGroup__3me8Z {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: -4px;
    background-color: var(--color-white);
}

.allEvents_radioGroup__3me8Z > * {
    padding-left: 10px !important;
}

.allEvents_eventsList__246rh {
    margin-top: 0px;
}

.allEvents_dayContainer__3bj9O {
    margin-top: 30px;
}

.allEvents_dayContainer__3bj9O > span:first-child {
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-secondary) !important;
    font-size: 14px !important;
    margin-bottom: 5px;
    display: block;
}

.allEvents_card__1kjmv {
    display: flex;
    border: 1px solid rgb(0, 0 ,0 , 20%);
    padding: 15px 0;
    cursor: pointer;
}


.allEvents_card__1kjmv:not(:nth-child(2)) {
    margin-top: -1px;
    border-top: 1px solid rgb(0, 0 ,0 , 0);
}

.allEvents_card__1kjmv:hover {
    border: solid 1px var(--color-black);
}

.allEvents_card__1kjmv > div {
    display: flex;
    align-items: center;
    margin-right: 12px;
    width: 75px;
    justify-content: flex-end;
}

.allEvents_card__1kjmv > span {
    white-space: nowrap;
    max-width: calc(100% - 75px - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.allEvents_card__1kjmv > div > span {
    font-weight: 500;
}

.allEvents_category__2qTNh {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 12px;
}

.allEvents_tick__yhZyz {
    display: flex;
    width: 24px !important;
    height: 24px !important;
    border: solid 1px var(--color-black);
    border-radius: 50%;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 0 !important;
    margin-left: 12px;
}

.allEvents_noResultsContainer__HLQ_m{
    margin-top: 25px;
}

.allEvents_noResults__3SUl5,
.allEvents_seeMore__3hLvp,
.allEvents_listEnd__1G3Nx {
    margin-top: 40px;
}

.allEvents_noResults__3SUl5 span,
.allEvents_listEnd__1G3Nx span {
    color: var(--color-secondary) !important;
}

.allEvents_seeMore__3hLvp button {
    width: 106px;
    height: 32px;
    font-weight: 500;
    font-size: 13px !important;
}

.allEvents_containerLoading__VAFFI {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.allEvents_calendarMenu__AhS23 {
    margin-top: 60px;
}

.allEvents_calendarContainer__1gWo1 {
   margin-top: 30px;
}

@media (min-width: 768px) {
    .allEvents_container__2JRqR {
        display: flex;
        flex-wrap: wrap;
    }
    
    .allEvents_container__2JRqR > div:first-child{
        order: 1;
    }
    
    .allEvents_container__2JRqR > div:nth-child(2){
        order: 3;
    }
    
    .allEvents_container__2JRqR > div:nth-child(3){
        order: 5;
    }
    
    .allEvents_container__2JRqR > div:nth-child(4){
        order: 2;
    }
    
    .allEvents_container__2JRqR > div:nth-child(5){
        order: 4;
    }

    .allEvents_eventsList__246rh {
        height: 607px;
        overflow-y: scroll;
    }

    .allEvents_divider__1v86Y > div {
        margin: 0;
        border-top: #DDD solid 1px;
    }

    .allEvents_calendarMenu__AhS23 {
        margin-top: 0px;
    } 
}
.viewMonth table {
    border: none !important;
    text-decoration: none;
}

.viewMonth thead td {
    border: none !important;
}

.viewMonth thead th {
    border-top: none !important;
    border-bottom: 1px solid var(--color-black) !important;
}

.viewMonth thead th:first-child {
    border-left: none;
}

.viewMonth thead th:last-child {
    border-right: none;
}

.viewMonth .fc-scrollgrid-section > td {
    border-right: none !important;
}

.viewMonth .fc-scrollgrid-sync-table tr td:first-child{
    border-left: none;
}

.viewMonth .fc-scrollgrid-sync-table tr td:last-child{
    border-right: none;
}


.fc-header-toolbar {
    justify-content: flex-start !important; 
}

.fc-prev-button,
.fc-next-button {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
    border: none !important;
    padding: 5px 0 !important;
    transform: scale(1.4);
}

.fc-prev-button:focus,
.fc-next-button:focus {
    box-shadow: none !important;
}


.fc-toolbar-chunk:first-child {
    margin-right: 20px;
}

.fc-toolbar-chunk:last-child {
    margin: auto;
    margin-right: 0;
}

.fc-toolbar-title {
    font-family: 'GT-America' !important;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 15px !important;
}

 
.viewMonth .fc-day {
    height: 40px;
}

.viewMonth .fc-col-header-cell > .fc-scrollgrid-sync-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewMonth .fc-col-header-cell > .fc-scrollgrid-sync-inner > .fc-col-header-cell-cushion {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}


.viewMonth .fc-daygrid-event-harness {
    border-radius: 50%;
    position: absolute !important;
    width: 10px;
    margin-left: 4px;
}

.viewMonth .fc-daygrid-event-harness-abs {
    width: auto;
    margin-left: 0;
}


.viewMonth .fc-daygrid-event-harness * {
    height: 12px !important;
    width: 12px !important;
    border-radius: 50% !important;
    border: none !important;
}

.viewMonth .fc-daygrid-event-harness-abs {
    height: 0 !important;
    width: auto !important;
    border-radius: 4px !important;
}
.display-none {
    display: none !important;
}

.hidden {
    opacity: 0;
}

.fc-daygrid-dot-event {
    background-color: rgba(0, 0, 0, 0) !important;
}

.viewMonth .fc-daygrid-day-events {
    display: flex;
    align-items: flex-end;
    height: 50px !important;
    min-height: 50px !important;
}

.fc-h-event {
    background-color: rgba(0, 0, 0, 0);
}

.viewMonth .internalEvent {
    background-color: var(--color-success);
    border: solid 1px white !important;
}

.viewMonth .externalEvent {
    background-color: var(--color-warning);
    border: solid 1px white !important;
}

.viewMonth .training {
    background-color: #AEA2CD;
    border: solid 1px white !important;
}

.borderInternalEvent {
     border: var(--color-success) 2px solid !important;
}

.borderExternalEvent {
    border: var(--color-warning) 2px solid !important;
}

.borderTraining {
     border: #AEA2CD 2px solid !important;
}

.opacity-half {
    opacity: .5;
}

.single-day-opacity {
    opacity: .5;
}

.viewMonth .fc-daygrid-day-top {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
} 

.range-0 {
    top: 6px !important;
}

.range-1 {
    top: calc(2 * 6px) !important;
}

.range-2 {
    top: calc(3 * 6px) !important;
}

.range-3 {
    top: calc(4 * 6px) !important;
}

.range-4 {
    top: calc(5 * 6px) !important;
}

.range-5 {
    top: calc(6 * 6px) !important;
}

.viewMonth .fc-daygrid-day-number {
    font-size: 12px;
    padding: 0 !important;
    text-decoration: none;
}

.viewMonth .fc-day-today {
    background-color: rgba(0, 0, 0, 0) !important;
}

.fc-day-today a {
    background-color: rgba(0, 0, 0, 0) !important;
}

.viewMonth .fc-day-today .fc-daygrid-day-number {
    background-color: rgb(0, 0, 0) !important;
    height: 20px;
    width: 20px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
}

.loading {
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-disabled);
    opacity: .3;
    top: 0;
}



@media (min-width: 768px) {
    .fc--button {
        display: none !important;
    }

    .fc-icon {
        font-size: 1rem !important;
    }

    .fc-toolbar-chunk {
        display: flex;
    }
}
.fc-scrollgrid {
    border: none !important;
}

.fc-header-toolbar {
    justify-content: flex-start !important; 
}

.fc-prev-button,
.fc-next-button {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
    border: none !important;
    padding: 5px 0 !important;
    transform: scale(1.4);
}

.fc-prev-button:focus,
.fc-next-button:focus {
    box-shadow: none !important;
}

.fc-toolbar-chunk:first-child {
    margin-right: 20px;
}

.fc-toolbar-chunk:last-child {
    margin: auto;
    margin-right: 0;
}

.fc-toolbar-title {
    font-family: 'GT-America' !important;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 15px !important;
}

.internalEvent {
    background-color: var(--color-success);
}

.externalEvent {
    background-color: var(--color-warning);
}

.training {
    background-color: #AEA2CD;
}

.fc-v-event {
    background-color: rgba(0,0,0,0);
}

.fc-event-main {
    padding: 0 !important;
    border-radius: 4px !important;
    position: relative;
}

.fc-timegrid-event {
    border: none;
    border-radius: 4px !important;
}

.fc-col-header-cell-cushion {
    font-weight: 400;
    width: 24px;
    height: 24px;
    text-decoration: none;
}

.viewWeek .fc-day-today .fc-scrollgrid-sync-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fc-day-today a {
    font-weight: 500;

    color: var(--color-white);
    background-color: var(--color-black);
    border-radius: 50%;
}

.fc-scrollgrid-section-header td {
    border: none;
}  

.fc-scrollgrid-section-body > td {
    border-right: none;
}  

.fc-timegrid-slot-label-cushion {
    font-size: 10px;
}

.fc-timegrid-slot-minor {
    border-top: none !important;
}

.fc-timegrid-now-indicator-arrow {
    display: none;
}

.fc-timegrid-now-indicator-line {
    border-color: var(--color-danger) !important;
}

.ant-tooltip-content {
    display: flex;
    align-items: center;
}

.identifier {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
}

.fc-timegrid-event-harness a {
    margin-left: 0 !important;
}

.fc-timegrid-event-harness a * {
    font-weight: 400;
    font-size: 9px;
}


.fc-timegrid-event-harness a * {
    font-weight: 400;
    font-size: 9px;
}

.lockScroll .fc-scroller-liquid-absolute {
    overflow: hidden !important;
}

.loading {
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-disabled);
    opacity: .3;
    top: 0;
}

.fc-daygrid-event-harness-abs > a ,
.fc-daygrid-event-harness > a {
    border: none;
}

.fc-timegrid-axis-cushion {
    text-transform: capitalize;
    font-size: 12px;
}

/* .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 0;
} */

.fc-event-end {
    margin-left: 2px;
}

@media (min-width: 768px) {
    .fc-timegrid-event-harness a * {
        font-size: 10px;
    }
}
.calendarWeek_event__2zITi {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-family: "GT-America" !important;
    font-size: 9px;
    overflow: hidden;
}

.calendarWeek_event__2zITi * {
    display: block;
    font-size: 10px;
    width: 100%;
    height: 100%;
}

.calendarWeek_overlay__3bRVE {
    background-color: var(--color-white);
    height: 32px;
    font-size: 10px;
    border-radius: 2px;
    /* display: flex;
    align-items: center; */
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.calendarWeek_overlay__3bRVE .ant-tooltip-content {
    padding: 6px;
    height: 32px !important;
}

.calendarWeek_overlay__3bRVE .ant-tooltip-inner {
    height: 100% !important;
    min-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendarWeek_overlay__3bRVE .ant-tooltip-inner::before {
    content: "";
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
}

.calendarWeek_identifier__2PBHc {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 6px;
}

.calendarWeek_internalEvent__3nDex  {
    background-color: var(--color-success);
}

.calendarWeek_externalEvent__NGcGy  {
    background-color: var(--color-warning);
}

.calendarWeek_training__2Q-W5 {
    background-color: #AEA2CD;
}

.calendarWeek_tooltipLayer__3Uhwk > * {
    display: block;
}

.calendarWeek_tooltipLayer__3Uhwk {
    z-index: 2;
    display: flex;
    align-items: center;
    background-color: var(--color-white);
    padding: 4px 8px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.calendarWeek_tooltipLayer__3Uhwk > span {
    font-size: 12px;
}

.calendarWeek_hasOpacity__1DhNK {
    opacity: .7;
}
.mouseFollowTooltip_box__2l8J_ > div {
    padding-left: 2px;
}

.mouseFollowTooltip_box__2l8J_ > div > span {
    overflow: hidden;
}

@media (min-width: 992px) {
    .mouseFollowTooltip_box__2l8J_ > div {
        padding-left: 4px;
    }
}
.legend_category__34FxC {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
}

.legend_category__34FxC span {
    text-transform: uppercase;
}

.legend_category__34FxC div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}
.filtersDesktop_filtersDropdown__2UpOG > .ant-dropdown-menu {
    right: -20px !important;
}

.filtersDesktop_menuFilterContainer__31i5b, .filtersDesktop_menuOrderContainer__1rn2r {
    padding: 20px !important;
    top: 15px;
}

.filtersDesktop_menuFilterContainer__31i5b > .ant-dropdown-menu-item:first-child, 
.filtersDesktop_menuOrderContainer__1rn2r > .ant-dropdown-menu-item {
    margin-bottom: 20px;
}

.filtersDesktop_menuFilterContainer__31i5b > .ant-dropdown-menu-item, 
.filtersDesktop_menuOrderContainer__1rn2r > .ant-dropdown-menu-item {
    padding: 0;
}
 
.filtersDesktop_menuOrderContainer__1rn2r > .ant-dropdown-menu-item:last-child {
    margin-bottom: 0;
}

.filtersDesktop_menuFilterContainer__31i5b > .ant-dropdown-menu-item > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}

.filtersDesktop_selectOption__369f4 {
    margin-left: unset !important;
}

div > .filtersDesktop_selectGroup__3Nk8Z {
    display: inline-flex;
    flex-direction: column;
    cursor: auto;
}

.filtersDesktop_selectGroup__3Nk8Z > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.filtersDesktop_selectGroup__3Nk8Z > label > span, .filtersDesktop_radioGroup__1uJju > div > div > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}

.filtersDesktop_radioGroup__1uJju:hover {
    background: unset;
    opacity: unset;
}

.filtersDesktop_filterContainer__2by1Z {
    display: flex;
    align-items: center;
    width: 100%;
}

.filtersDesktop_filterContainer__2by1Z > div > .filtersDesktop_divider__3o1Kv {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.filtersDesktop_menuOrderContainer__1rn2r .ant-space-item {
    width: 100%;
}

.filtersDesktop_pointer__1w7b- {
    cursor: pointer;
    display: block;
    flex: 1 1;
}

.filtersDesktop_pointer__1w7b- > span {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.filtersDesktop_chevron__3vz_s {
    margin-left: 15px;
    width: 14px;
}

.filtersDesktop_control__JE08z {
    display: flex;
    margin-right: 20px;
}

.filtersDesktop_control__JE08z > span {
    display: flex;
}

.filtersDesktop_clearButton__3-Xow {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.filtersDesktop_menuFilterContainer__31i5b > .ant-dropdown-menu-item:hover, 
.filtersDesktop_menuOrderContainer__1rn2r > .ant-dropdown-menu-item:hover {
    background-color: unset
}

.filtersDesktop_dropdown__2mJQo {
    padding-right: 20px;
    width: 100%;
    display: flex;
}

.filtersDesktop_slider__1pEeg .ant-slider-step,
.filtersDesktop_slider__1pEeg .ant-slider-rail,
.filtersDesktop_slider__1pEeg .ant-slider-track {
    height: 2px;
}

.filtersDesktop_slider__1pEeg .ant-slider-dot {
    display: none;
}

.filtersDesktop_slider__1pEeg .ant-slider-handle {
    background-color: var(--color-black);
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.filtersDesktop_slider__1pEeg .ant-slider-mark-text {
    margin-top: 5px;
}

.filtersDesktop_menuFilterContainer__31i5b .ant-checkbox-group,
.filtersDesktop_menuFilterContainer__31i5b .ant-checkbox-wrapper {
    width: 100%;
}

.filtersDesktop_menuFilterContainer__31i5b > div:last-child {
    cursor: auto;
}
.searchBarFilters_listContainer__dATkI {
    width: 100%;
}

.searchBarFilters_sectionTitle__3fcsd {
    margin-top: 30px;
    padding-bottom: 8px;
}

.searchBarFilters_sectionTitle__3fcsd > h1{
    font-weight: 400;
    margin: 0;
}

.searchBarFilters_divider__37Gu9 {
    margin: 8px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.searchBarFilters_seeMore__1ywb1 button {
    padding-left: 22px;
    padding-right: 22px;
}

.searchBarFilters_searchInputRow__3DK_h {
    width: 100%;
    margin-top: 30px !important;
}

.searchBarFilters_renderRow__32Pgt {
    margin-top: 50px !important;
}

.searchBarFilters_arrowNext__1eEHh {
    transform: rotate(180deg);
}

.searchBarFilters_searchInputContainer__1uUMG {
    width: 100%;
}

.searchBarFilters_searchInputContainer__1uUMG > .searchBarFilters_searchInput__JOLvC {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.searchBarFilters_searchInputContainer__1uUMG > .searchBarFilters_searchInput__JOLvC > .ant-input-suffix {
    width: 290px;
}

.searchBarFilters_searchInput__JOLvC input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 22px;
    line-height: 30px;
}

.searchBarFilters_searchInput__JOLvC input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.searchBarFilters_searchInput__JOLvC input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.searchBarFilters_searchInput__JOLvC .ant-input-prefix {
    margin-right: unset;
}

.searchBarFilters_searchInputIcon__3dJbm {
    width: 22px;
    height: 22px;
    margin-right: 18px;
    margin-left: 13px;
}

.searchBarFilters_filter__3Oay5 {
    margin-top: 30px !important;
    width: 100%;
}

.searchBarFilters_filter__3Oay5 > div:nth-child(2) span{
    font-family: "GT-America" !important;
    font-weight: 300 !important;
    font-size: 12px !important;
}

.searchBarFilters_dropdownContainer__3GwcP {
    margin-bottom: 18px;
}

.searchBarFilters_overlay__3kRPv > div {
    padding: 0;
    margin-top: -4px;
}

.searchBarFilters_overlay__3kRPv *:hover {
    background-color: var(--color-white) !important;
}

.searchBarFilters_overlay__3kRPv [ant-click-animating-without-extra-node='true']::after,
.searchBarFilters_overlay__3kRPv .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow:none;
    opacity: 1;
    animation: none;
    animation-fill-mode: none;
    pointer-events: none;
}

.searchBarFilters_dropdownRow__3glXV {
    border-bottom: solid 1px var(--color-black);
}

.searchBarFilters_dropdownRow__3glXV h1 {
    margin-bottom: 6px;
    font-weight: 600;
}

.searchBarFilters_checkboxGroup__27tFw > label {
    margin: 9px 0;
}

.searchBarFilters_clearButton__3duJ6 {
    padding-left: 0 !important;
    font-weight: 600 !important;
}

.searchBarFilters_labelContainer__3qYRj {
    display: flex;
    align-items: center;
}

.searchBarFilters_selectOption__-_qFV {
    margin-left: unset !important;
}

.searchBarFilters_labelContainer__3qYRj svg {
    width: 20px;
    height: 20px;
}

.searchBarFilters_labelContainer__3qYRj:hover {
    cursor: pointer;
}

.searchBarFilters_label__24UuY {
    margin-left: .5rem;
}

.searchBarFilters_removeIcon__1sEny{
    margin-right: .5rem;
}

.searchBarFilters_containerLoading__3ll0g {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.searchBarFilters_clearAll__2uQH- > span:nth-child(2){
    margin-left: .5rem;
}

.searchBarFilters_results__1LNLn {
    margin-right: .5rem;
}

.searchBarFilters_display__313ax {
    display: block !important;
}

.searchBarFilters_listLarge__2X53i {
    display: none !important;
}

.searchBarFilters_listLarge__2X53i h1 {
    margin-top: 1.5rem;
}

.searchBarFilters_listSmall__Tvd16 {
    margin-top: 0 !important;
}

.searchBarFilters_listSmall__Tvd16 > div > div:nth-child(1) {
    display: none;
}

.searchBarFilters_listSmall__Tvd16 > div > div:nth-child(2) {
    margin-top: 0 !important;
}

.searchBarFilters_selectAll__w6wkV{
    margin-top: 9px;
}

.searchBarFilters_dropdownOptionsContainer__383jh > div:first-child {
    padding-top: 9px;
    padding-bottom: 9px;
}

.searchBarFilters_dropdownOptionsContainer__383jh > div:nth-child(2) {
    padding-top: 0;
    padding-bottom: 0;
}

.searchBarFilters_dropdownOptionsContainer__383jh > div > *:not(:last-child) {
    width: 100%;
}

@media (min-width: 576px) {
    .searchBarFilters_cardContainer__24CRb:nth-child(2n) {
        margin-left: calc(100%/12);   
    }
}

@media (min-width: 710px) {

    .searchBarFilters_suffixContainer__1al3Z {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .searchBarFilters_searchInputRow__3DK_h {
        margin-top: 40px !important;
    }

    .searchBarFilters_divider__37Gu9 {
        width: 100vw;
        margin-left: -50px;
    }

    .searchBarFilters_cardContainer__24CRb {
        margin-left: 0 !important;   
    }

    .searchBarFilters_cardContainer__24CRb:nth-child(2n + 1) {
        margin-left: 0;   
    }

    .searchBarFilters_cardContainer__24CRb:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2) !important;   
    }
}
@media (min-width: 992px) {

    .searchBarFilters_cardContainer__24CRb:not(:nth-child(3n + 1)) {
        margin-left: 0 !important;   
    }

    .searchBarFilters_cardContainer__24CRb:not(:nth-child(4n + 1)) {
        margin-left: calc(100%/6/3) !important;   
    }
}

@media (min-width: 1024px) {
    .searchBarFilters_filter__3Oay5 {
        display: none !important;
    }

    .searchBarFilters_listLarge__2X53i {
        display: block !important;
    }

    .searchBarFilters_listSmall__Tvd16 {
        display: none !important;
    }

    .searchBarFilters_cardListContainer__2az4e {
        margin-top: 4rem !important;
    }
}

@media screen and (min-width: 769px) {
    .searchBarFilters_divider__37Gu9 {
        margin-left: -90px;
    }
}

@media screen and (min-width: 1350px) {
    .searchBarFilters_divider__37Gu9 {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}




.button_button__3kfBt {
    width: 100%;
}

.button_button__3kfBt span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.button_buttonContainer__2VVaB {
    margin-bottom: 10px;
    margin-right: unset;
}

.button_buttonContainerMobile__3R2PP {
    margin-right: unset;
}

.button_buttonContainer__2VVaB:last-child {
    margin-bottom: 0;
    margin-right: unset;
}

@media screen and (min-device-width: 768px) {
    .button_buttonContainer__2VVaB {
        margin-right: 40px;
    }

    .button_button__3kfBt {
        width: unset;
    }
}
.intro_introContainer__1S7lw {
  background-color: #ebebeb;
  padding-bottom: 40px !important;
  position: relative;
  margin-bottom: 60px !important;
  background-size: cover;
  background-position: center;
  justify-content: center;
  max-width: 100vw !important;
}

.intro_introContainer__1S7lw > div:first-child {
  max-width: var(--main-container-max-width);
  width: 100%;
}

.intro_backgroundContainer__3nAzH {
  position: absolute;
  width: 100%;
}

.intro_description__39haK {
  font-family: "GT-America";
  font-size: var(--font-size-page-description);
  line-height: var(--line-height-page-description);
  font-weight: 300 !important;
  width: 100%;
}

.intro_description__39haK * {
  font-family: "GT-America";
  font-size: var(--font-size-page-description);
  line-height: var(--line-height-page-description);
  font-weight: 300 !important;
  width: 100%;
  color: inherit;
}

@media screen and (min-width: 768px) {
  .intro_introContainer__1S7lw {
    padding-bottom: 80px !important;
  }
  .intro_description__39haK * {
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-lead) !important;
  }
}

@media screen and (min-width: 992px) {
  .intro_introContainer__1S7lw {
    padding-bottom: 100px !important;
    max-width: none !important;
  }

  .intro_introContainer__1S7lw > div:not(:last-child) {
    max-width: var(--main-container-max-width);
    margin: auto;
  }

  .intro_description__39haK {
    display: block;
    width: 80%;
  }

  .intro_backgroundContainer__3nAzH > img:first-child {
    float: left;
  }

  .intro_backgroundContainer__3nAzH > img:nth-child(2) {
    float: right;
  }
}
.menu_menuContainer__2PIqz {
    border-bottom: solid 1px var(--color-black);
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.menu_menuContainer__2PIqz:hover h1 {
    color: var(--color-white) !important;
}

.menu_menuContainer__2PIqz > h1 {
    margin-bottom: 0;
    margin-left: 10px;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.menu_menuRow__2VAEN{
    margin-top: 40px !important;
    margin-bottom: 40px !important;
}

.menu_menuRow__2VAEN > div:not(:nth-child(3n + 1)) {
    margin-left: 0;
}

@media (min-width: 480px){
    .menu_menuContainer__2PIqz h1{
       font-size: 15px !important;
       font-weight: 500 !important;
       line-height: 22px !important;
    }
}
@media screen and (min-device-width: 768px) {
    .menu_menuContainer__2PIqz * {
        z-index: 1;
    }
    
    .menu_menuContainer__2PIqz::before {
        content: "";
        width: 0px;
        height: 100%;
        background-color: var(--color-black);
        position: absolute;
        left: 0;
        top: 0;
        transition: width .2s ease-in-out;
        z-index: 0;
    }
    
    .menu_menuContainer__2PIqz:hover::before {
        width: 100%;
    }
}

@media screen and (min-device-width: 1024px) { 
    .menu_menuRow__2VAEN > div:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2);
    }
}

.h1_title__1EeNB {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    color: var(--color-black) !important;
    font-size: 54px !important;
    line-height: 64px !important;
    margin-bottom: 25px !important;
}
.title_wrapper__gcIuI p {
    color: #444444 !important;
    font-family: "DomaineDisplay" !important;
    font-size: var(--font-size-title-page) !important;
    line-height: var(--line-height-title) !important;
}
.subtitle_wrapper__LHp_j p {
    color: #444444 !important;
    font-family: "DomaineDisplay" !important;
    font-size: var(--font-size-subtitle);
    line-height: var(--line-height-subtitle);
}
.paragraph_wrapper__9s5_2 p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 768px) {
  .paragraph_wrapper__9s5_2 p {
    color: var(--color-secondary);
    font-size: var(--font-size-body) !important;
    line-height:var(--line-height-body-and-title)!important;
  }
}

blockquote {
  padding: 10px 0px 10px 25px !important;
  margin: 0 0 25px;
  font-size: 20px;
  line-height: 28px;
  border-left: 5px solid var(--color-medium-gray);
  display: block;
  -webkit-margin-before: 10px;
          margin-block-start: 10px;
  -webkit-margin-after: 10px;
          margin-block-end: 10px;
  -webkit-margin-start: 40px;
          margin-inline-start: 40px;
  -webkit-margin-end: 40px;
          margin-inline-end: 40px;
}

.quote_wrapper__15Qlc{
  margin-bottom: 25px !important;
}

@media (min-width: 768px) {
  .quote_wrapper__15Qlc p{
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}
  @media (min-width: 768px) {
  .quote_wrapper__15Qlc h1{
    font-size: var(--font-size-title) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}
mark {
  background-color: #fcf8e3;
  padding: 0.2em !important;
  color: #000;
  margin-bottom: 25px;
}

.highlight_wrapper__1jLT3 p{
  margin-bottom: 0px;
}
.text_pageBody__2o91y {
    overflow-x: auto;
    font-family: "GT America" !important;
}

.text_pageBody__2o91y p {
    margin-bottom: 0 !important;
}

.text_pageBody__2o91y p img {
    object-fit: cover;
    width: 100%;
}

.text_pageBody__2o91y strong {
    color: var(--color-black);
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.text_pageBody__2o91y a {
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.text_pageBody__2o91y li {
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.text_pageBody__2o91y p {
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.text_pageBody__2o91y h1 {
    font-weight: 400;
    font-size: var(--font-size-title-page);
    line-height: var(--line-height-title);
}

.text_pageBody__2o91y h2 {
    font-weight: 400;
    font-size: var(--font-size-subtitle);
    line-height: var(--line-height-subtitle);
}

.text_pageBody__2o91y h3 {
    font-weight: 400;
    font-size: var(--font-size-lead);
    line-height: var(--line-height-lead);
}

.text_pageBody__2o91y * {
    color: #444444 !important;
}

@media screen and (min-device-width: 1024px) {
    .text_pageBody__2o91y p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
}
.image_wrapper__2HNG6{
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.image_wrapper__2HNG6 img{
  height: auto;
}
.textText_wrapper__3cQBX > div {
    margin: 0 !important;
    width: 100% !important;
}

.textText_wrapper__3cQBX > div > div:first-child {
    padding-left: 0 !important;
}

.textText_wrapper__3cQBX > div > div:last-child {
    padding-right: 0 !important;
}

.textText_wrapper__3cQBX h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.textText_wrapper__3cQBX p img {
  object-fit: cover;
  width: 100%;
}

.textText_wrapper__3cQBX p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}


@media screen and (min-device-width: 768px) {
  .textText_wrapper__3cQBX p {
    color: var(--color-secondary);
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-body-and-title)!important;
  }

  .textText_wrapper__3cQBX h1 {
    color: var(--color-secondary);
    font-size: var(--font-size-title) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}

@media screen and (min-device-width: 1024px) {
  .textText_wrapper__3cQBX p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }
}

.imageText_wrapper__3P2v6 {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.imageText_wrapper__3P2v6 h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.imageText_wrapper__3P2v6 p img {
  object-fit: cover;
  width: 100%;
}

.imageText_wrapper__3P2v6 img {
  width: 100%;
}

.imageText_wrapper__3P2v6 p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 768px) {

  .imageText_wrapper__3P2v6 h1 {
   
    font-size: var(--font-size-title) !important;
    line-height: var(--line-height-body-and-title)!important;
  
  }
  .imageText_wrapper__3P2v6 p {
    color: var(--color-secondary);
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}


@media screen and (min-device-width: 1024px) {
  .imageText_wrapper__3P2v6 p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }


}

.textImage_wrapper__1mzfk {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.textImage_wrapper__1mzfk h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.textImage_wrapper__1mzfk p img {
  object-fit: cover;
  width: 100%;
}

.textImage_wrapper__1mzfk img {
  width: 100%;
}

.textImage_wrapper__1mzfk p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 768px) {

  .textImage_wrapper__1mzfk p {
    color: var(--color-secondary);
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-body-and-title)!important;
  }

  .textImage_wrapper__1mzfk h1 {
    font-size: var(--font-size-title) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}

@media screen and (min-device-width: 1024px) {
  .textImage_wrapper__1mzfk p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

}

.imageImage_wrapper__Y9EE_ {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.imageImage_wrapper__Y9EE_ h1 {
  font-family: "DomaineDisplay";
  font-size: 20px;
}

.imageImage_wrapper__Y9EE_ p img {
  object-fit: cover;
  width: 100%;
}

.imageImage_wrapper__Y9EE_ img {
  width: 100%;
}

.imageImage_wrapper__Y9EE_ p {
  color: var(--color-secondary);
  font-size: 14px;
}

@media screen and (min-device-width: 1024px) {
  .imageImage_wrapper__Y9EE_ p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }

  .imageImage_wrapper__Y9EE_ p {
    color: var(--color-secondary);
    font-size: 18px;
  }

  .imageImage_wrapper__Y9EE_ h1 {
    font-family: "DomaineDisplay";
    font-size: 26px;
  }
}

.separator_wrapper__391U3 hr {
  margin-top: 15px;
  margin-bottom: 40px;
  border: 0;
  border-top: 1px solid var(--color-medium-gray);
  box-sizing: content-box;
  height: 0;
  width: 100%;
}

.template_container__39dtp {
    padding-top: 80px;
    padding-bottom: 60px;
}

.template_subBox__18mhg {
    padding-top: 50px !important;
}

.template_subBoxPrimary__Jxn9d {
    font-size: 20px;
    text-align: left;
    font-family: "DomaineDisplay";
}

.template_subBoxSecondary__2Hgpx {
    font-size: 18px;
    color: var(--color-secondary);
    text-align: left;
}

.template_pageBody__6qWm8 p img {
    object-fit: cover;
    width: 100%;
}

.template_contentContainer__NiOzO{
    position: relative;
    z-index: 1;
}

@media screen and (min-device-width: 1024px) {
    .template_pageBody__6qWm8 p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }

    .template_subBoxPrimary__Jxn9d {
        font-size: 26px;
        text-align: left;
        font-family: "DomaineDisplay";
    }
    
    .template_subBoxSecondary__2Hgpx {
        font-size: 14px;
        color: var(--color-secondary);
        text-align: left;
    }
}
.postCard_cardContainer__1noMz {
    max-width: 270px;
}

.postCard_cardContainer__1noMz .postCard_cover__3C5Lo {
    max-height: 230px;
    border: 1px solid #EBEBEB;
    border-bottom: unset;
}

.postCard_cardContainer__1noMz .ant-card-body {
    padding: 15px;
    border-top: unset;
    cursor: pointer;
    border-bottom: 1px solid #EBEBEB;
    border-left: 1px solid #EBEBEB;
    border-right: 1px solid #EBEBEB;
}

.postCard_cardContainer__1noMz .ant-card-meta-title {
    font-family: "GT-America";
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    height: 52px;
    margin-bottom: 10px !important;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
}

.postCard_cardContainer__1noMz .postCard_lastUpdated__3lQCc {
    font-family: "GT-America";
    font-size: 10px !important;
    font-weight: 300 !important;
    line-height: 18px;
    color: var(--color-secondary);
    margin-top: unset;
}

.postCard_cardContainer__1noMz .postCard_tagTitle__200bq {
    font-family: "GT-America";
    font-size: 12px;
    font-weight: 300 !important;
    line-height: 18px;
    color: var(--color-secondary) !important;
    margin-top: unset;
    margin-bottom: 5px !important;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.postCard_cardContainer__1noMz.postCard_cardThumb__1vzWo .ant-card-cover::before {
    content: "";
    position: absolute;
    left: 0; 
    right: 0;
    top: 0; 
    bottom: 0;
    max-height: 230px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(/static/media/play.7255a1c9.svg);
}

@media screen and (min-width: 769px) {
    .postCard_cardContainer__1noMz {
        max-width: 270px;
    }

    .postCard_cardContainer__1noMz .postCard_cover__3C5Lo {
        max-height: 230px;
    }

    .postCard_cardContainer__1noMz .postCard_lastUpdated__3lQCc {
        font-size: 12px !important;
    }
}

@media screen and (min-width: 992px) {
    .postCard_cardContainer__1noMz {
        max-width: 245px;
    }

    .postCard_cardContainer__1noMz .postCard_cover__3C5Lo, .postCard_cardContainer__1noMz.postCard_cardThumb__1vzWo .ant-card-cover::before {
        max-height: 215px;
    }
}

@media screen and (min-width: 1200px) {
    .postCard_cardContainer__1noMz {
        max-width: 225px;
    }

    .postCard_cardContainer__1noMz .postCard_cover__3C5Lo, .postCard_cardContainer__1noMz.postCard_cardThumb__1vzWo .ant-card-cover::before {
        max-height: 200px;
    }
}

@media screen and (min-width: 1440px) {
    .postCard_cardContainer__1noMz {
        max-width: 270px;
    }

    .postCard_cardContainer__1noMz .postCard_cover__3C5Lo, .postCard_cardContainer__1noMz.postCard_cardThumb__1vzWo .ant-card-cover::before {
        max-height: 230px;
    }
}

@media (hover: hover) {
    .postCard_cardContainer__1noMz {
        cursor: pointer;
    }
    
    .postCard_cardContainer__1noMz:hover .postCard_cover__3C5Lo {
        border-bottom: 1px solid var(--color-black) !important;
    }

    .postCard_cardContainer__1noMz:hover {
        background-color: var(--color-black);
    }
    
    .postCard_cardContainer__1noMz:hover .ant-card-meta-title, .postCard_cardContainer__1noMz:hover .postCard_lastUpdate__3wFp_, .postCard_cardContainer__1noMz:hover .ant-card-body {
        color: var(--color-white) !important;
    }

    .postCard_cardContainer__1noMz.postCard_cardThumb__1vzWo:hover g {
        stroke: var(--color-white) !important;
    }

    .postCard_cardContainer__1noMz.postCard_cardThumb__1vzWo:hover g path {
        stroke: var(--color-white) !important;
    } 

    .postCard_cardContainer__1noMz:hover > div:nth-child(2) {
        border-bottom: 1px solid var(--color-black);
        border-left: 1px solid var(--color-black);
        border-right: 1px solid var(--color-black);
    }
}

.categoryTitle_title__10Mx_ {
    font-family: "GT-America";
    font-weight: 500 !important;
    margin-top: 85px;
    text-transform: uppercase;
    font-size: 12px !important;
    line-height: 18px;
    color: rgb(128, 128, 128)
}

@media (min-width: 769px) {
    .categoryTitle_title__10Mx_ {
        margin-top: 145px;
        font-size: var(--font-size-body)!important;
        line-height: var(--line-height-body-and-title) !important;
    }
}


.filterDesktop_menuFilterContainer__2fhU6 {
    padding: 20px !important;
    top: 15px;
}

.filterDesktop_menuFilterContainer__2fhU6 > .ant-dropdown-menu-item:first-child {
    margin-bottom: 20px;
}

.filterDesktop_menuFilterContainer__2fhU6 > .ant-dropdown-menu-item {
    padding: 0;
}

.filterDesktop_menuFilterContainer__2fhU6 > .ant-dropdown-menu-item > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}


div > .filterDesktop_selectGroup__E373x {
    display: inline-flex;
    flex-direction: column;
}

.filterDesktop_selectGroup__E373x > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.filterDesktop_filterContainer__3rVKR {
    display: flex;
    align-items: center;
}

.filterDesktop_filterContainer__3rVKR > div > .filterDesktop_divider__2bntS {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.filterDesktop_pointer__3KlP_ {
    cursor: pointer;
}

.filterDesktop_filterLabel__2k4MX {
    display: block;
    width: 100%;
}

.filterDesktop_filterLabel__2k4MX > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterDesktop_chevron__1b6U1 {
    margin-left: 15px;
    width: 14px;
}

.filterDesktop_clearButton__3CWJo {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
}

.filterDesktop_menuFilterContainer__2fhU6 > .ant-dropdown-menu-item:hover {
    background-color: unset
}

.filterDesktop_dropdown__1qE3f {
    padding-right: 20px;
    width: 290px;
    display: flex;
}

.filterDesktop_selectGroup__E373x > .ant-checkbox-wrapper {
    margin-left: 0;
}
.filterMobile_dropdown__pMCW8 > span {
    width: 100%;
    display: block;
    border-bottom: 1px solid var(--color-black);
}

.filterMobile_dropdown__pMCW8 > span > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterMobile_label__2K0cE {
    font-weight: 500;
    font-family: "DomaineDisplay";
    font-size: 22px !important;
    margin-bottom: 10px;
}

.filterMobile_dropdownOverlay__RTa4P > div {
    margin-top: -4px;
}

.filterMobile_dropdownOverlay__RTa4P .ant-dropdown-menu-item {
    background-color: var(--color-white);
}

.filterMobile_dropdownOverlay__RTa4P > div .ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.filterMobile_dropdownOverlay__RTa4P > div > div:nth-child(2) .ant-checkbox-wrapper {
    margin: 9px 0;
}

.filterMobile_clearButton__2ZDwE {
    font-weight: 600;
}

.filterMobile_clearAllDisabled__2emfz {
    color: var(--color-medium-gray);
}


.postsGrid_resultsContainer__1BpXw {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.postsGrid_resultsContainer__1BpXw > span {
    color: var(--color-tertiary) !important;
}

.postsGrid_date__PYpQN {
    font-size: 13px !important;
}

.postsGrid_overline__1HU-b{
    text-transform: uppercase;
}

.postsGrid_overlineContainer__8tiNK{
    margin-bottom: 5px !important;
}

.postsGrid_cardContainer__34-Hm {
    margin-bottom: 40px !important;
    max-width: 270px !important;
    margin-left: auto;
    margin-right: auto;
}

.postsGrid_faded__3uY7w {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
}

.postsGrid_itemBody__34WvI, .postsGrid_itemBody__34WvI > * {
    margin-bottom: 10px !important;
    color: var(--color-secondary) !important;
    font-size: 14px;
}

.postsGrid_listWrap__HlG0v{
    margin-top: 45px !important; 
}

.postsGrid_title__1BEgj {
    margin-top: 0px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
}

.postsGrid_image__2lXHi {
    max-width: 100%;
    height: 260px;
    margin-bottom: 5px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.postsGrid_link__GEc4G:hover .postsGrid_title__1BEgj {
    -webkit-text-decoration: underline solid black from-font !important;
            text-decoration: underline solid black from-font !important;
    text-underline-offset: 4px !important;
}

.postsGrid_link__GEc4G:hover .postsGrid_image__2lXHi {
    opacity: 0.6;
}

.postsGrid_pagination__2iXFE {
    width: auto;
    margin-right: -17px;
}

.postsGrid_pagination__2iXFE > ul{
    display: flex;
    justify-content: flex-end;
}

.postsGrid_mainContainer__1dUO3 {
    margin-bottom: 75px !important;
}

.postsGrid_searchInputContainer__1titX {
    width: 100%;
    margin-bottom: 40px;
}

.postsGrid_searchInputContainer__1titX > .postsGrid_searchInput__1olHx {
    height: 50px;
    padding-right: unset;
    padding-left: 15px;
}

.postsGrid_searchInput__1olHx input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 20px;
}

.postsGrid_searchInput__1olHx input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.postsGrid_searchInput__1olHx input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.postsGrid_searchInput__1olHx .ant-input-prefix {
    margin-right: unset;
}

.postsGrid_searchInputIcon__3SeQT {
    width: 20px;
    height: 20px;
    margin-right: 18px;
    margin-left: 13px;
}

.postsGrid_tabMenuContainer__14wrJ {
    margin-top: 30px;
    width: 100%;
    margin-bottom: 20px;
}

.postsGrid_noMarginBottom__VD1n8 {
    margin-bottom: 0;
}

.postsGrid_divider__37TmR {
    position: absolute;
    width: 100vw;
    left: -20px;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 30px;
}

@media screen and (min-width: 481px) {
    .postsGrid_divider__37TmR {
        left: -50px;
    }
}

@media screen and (min-width: 769px) {
    .postsGrid_divider__37TmR {
        left: -90px;
    }

    .postsGrid_cardContainer__34-Hm {
        max-width: 270px !important;
    }

    .postsGrid_image__2lXHi {
        height: 260px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .postsGrid_divider__37TmR {
        left: calc((100vw - var(--main-container-max-width) + 180px) / -2);
    }
}

@media screen and (min-width: 992px) {
    .postsGrid_cardContainer__34-Hm {
        max-width: 245px;
    }

    .postsGrid_image__2lXHi {
        height: 160px;
    }
}

@media (min-width: 1009px) {
    .postsGrid_displayMobile__3CbUI {
        display: none;
    }
    .postsGrid_image__2lXHi {
        height: 200px;
    }
}

@media screen and (min-width: 1200px) {
    .postsGrid_cardContainer__34-Hm {
        max-width: 225px;
    }

    .postsGrid_image__2lXHi {
        height: 260px;
    }
}


@media screen and (min-width: 1350px) {
    .postsGrid_divider__37TmR {
        left: calc((100vw - var(--main-container-max-width)) / -2) ;
    }
}

@media screen and (min-width: 1440px) {
    .postsGrid_cardContainer__34-Hm {
        max-width: 270px;
    }

    .postsGrid_image__2lXHi {
        height: 260px;
    }
}

.video_videoContainerFull__4OEr_ {
    width: 100%;
}

.video_videoContainerHalf__2y1P8 {
    width: 50%;
}

.video_videoContainer__332jk > * {
    width: 100%;
}
.app_main__1S8ss {
    min-height: calc(100vh - 631px);
    margin-bottom: 50px;
    background: var(--color-white);
    overflow-x: hidden;
}

.app_noMargin__In2h6 {
    margin: 0;
}

@media screen and (min-width: 769px) { 
    .app_main__1S8ss {
        min-height: calc(100vh - 194px);
        background: var(--color-white);
    }
}
.eventList_listContainer__1Auex {
    width: 100%;
}

.eventList_sectionTitle__XyeHW {
    margin-top: 30px;
    padding-bottom: 8px;
}

.eventList_sectionTitle__XyeHW > h1{
    font-weight: 400;
    margin: 0;
}

.eventList_divider__Tx4RG {
    margin: 8px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.eventList_seeMore__1wS8E button {
    padding-left: 22px;
    padding-right: 22px;
}

.eventList_searchInputRow__2CSlI{
    width: 100%;
}

.eventList_renderRow__38KOP{
    margin-top: 50px !important;
}

.eventList_arrowNext__Zj6D5 {
    transform: rotate(180deg);
}

.eventList_searchInputContainer__1imET {
    width: 100%;
}

.eventList_searchInputContainer__1imET > .eventList_searchInput__3aS9o {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.eventList_searchInput__3aS9o input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 26px;
    letter-spacing: 0px;
    line-height: 26px;
}

.eventList_searchInput__3aS9o input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
}

.eventList_searchInput__3aS9o input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
}

.eventList_searchInput__3aS9o .ant-input-prefix {
    margin-right: unset;
}

.eventList_searchInputIcon__1sehg {
    width: 24px;
    height: 24px;
    margin-right: 18px;
}

.eventList_filter__-xdCu {
    margin-top: 30px !important;
    width: 100%;
}

.eventList_filter__-xdCu > div:nth-child(2) span{
    font-family: "GT-America" !important;
    font-weight: 300 !important;
    font-size: 12px !important;
}

.eventList_dropdownContainer__32xBr {
    margin-bottom: 18px;
}

.eventList_overlay__1F7jm > div {
    padding: 0;
    margin-top: -4px;
}

.eventList_overlay__1F7jm *:hover {
    background-color: var(--color-white) !important;
}

.eventList_overlay__1F7jm [ant-click-animating-without-extra-node='true']::after,
.eventList_overlay__1F7jm .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow:none;
    opacity: 1;
    animation: none;
    animation-fill-mode: none;
    pointer-events: none;
}

.eventList_dropdownRow__2xGiw {
    border-bottom: solid 1px var(--color-black);
}

.eventList_dropdownRow__2xGiw h1 {
    margin-bottom: 6px;
    font-weight: 600;
}

.eventList_checkboxGroup__1-aZD > label {
    margin: 9px 0;
}

.eventList_clearButton__1RisV {
    padding-left: 0 !important;
    font-weight: 600 !important;
}

.eventList_labelContainer__3rbhx {
    display: flex;
    align-items: center;
}

.eventList_selectOption__eDqWj {
    margin-left: unset !important;
}

.eventList_labelContainer__3rbhx svg {
    width: 20px;
    height: 20px;
}

.eventList_labelContainer__3rbhx:hover {
    cursor: pointer;
}

.eventList_label__3uHkP {
    margin-left: .5rem;
}

.eventList_removeIcon__1VUxI{
    margin-right: .5rem;
}

.eventList_containerLoading__36vbZ {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.eventList_clearAll__2-rb3 > span:nth-child(2){
    margin-left: .5rem;
}

.eventList_results__1qFiu {
    margin-right: .5rem;
}

.eventList_display__1g1W3 {
    display: block !important;
}

.eventList_listLarge__2zbaX {
    display: none !important;
}

.eventList_listLarge__2zbaX h1 {
    margin-top: 1.5rem;
}

.eventList_listSmall__2oxBx {
    margin-top: 0 !important;
}

.eventList_listSmall__2oxBx > div > div:nth-child(1) {
    display: none;
}

.eventList_listSmall__2oxBx > div > div:nth-child(2) {
    margin-top: 0 !important;
}

@media (min-width: 576px) {
    .eventList_cardContainer__1UESB:nth-child(2n) {
        margin-left: calc(100%/12);   
    }
}

@media (min-width: 710px) {

    .eventList_suffixContainer__3qXnp {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) {
    .eventList_divider__Tx4RG {
        width: 100vw;
        margin-left: -50px;
    }

    .eventList_cardContainer__1UESB {
        margin-left: 0 !important;   
    }

    .eventList_cardContainer__1UESB:nth-child(2n + 1) {
        margin-left: 0;   
    }

    .eventList_cardContainer__1UESB:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2) !important;   
    }
}
@media (min-width: 992px) {

    .eventList_cardContainer__1UESB:not(:nth-child(3n + 1)) {
        margin-left: 0 !important;   
    }

    .eventList_cardContainer__1UESB:not(:nth-child(4n + 1)) {
        margin-left: calc(100%/6/3) !important;   
    }
}

@media (min-width: 1024px) {
    .eventList_filter__-xdCu {
        display: none !important;
    }

    .eventList_listLarge__2zbaX {
        display: block !important;
    }

    .eventList_listSmall__2oxBx {
        display: none !important;
    }

    .eventList_cardListContainer__hqQ2z {
        margin-top: 4rem !important;
    }
}

@media screen and (min-width: 769px) {
    .eventList_divider__Tx4RG {
        margin-left: -90px;
    }
}

@media screen and (min-width: 1350px) {
    .eventList_divider__Tx4RG {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}




.intro_intro__1tsWn {
    margin-top: 64px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    background-color: #E9F4F6;
    max-width: none !important;
    position: relative;
}

.intro_intro__1tsWn > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro_intro__1tsWn > div:last-child {
    margin-top: 25px;
    display: flex;
}

.intro_intro__1tsWn > div > h1 {
    font-weight: 400;
    line-height:  50px;
}

.intro_intro__1tsWn > div > span {
    font-size: 15px !important;
    color: var(--color-secondary) !important;
    line-height: 19.5px;
}

.intro_iconContainer__hH5rh {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.intro_iconContainer__hH5rh > svg:first-child {
    margin-left: -90px;
}

.intro_iconContainer__hH5rh > svg:last-child {
    display: none;
}

@media (min-width: 481px) {
    .intro_iconContainer__hH5rh > svg:first-child {
        margin-left: -70px;
    }
}

@media (min-width: 768px) {

    .intro_intro__1tsWn > div > span {
        font-size: 24px !important;
        line-height: 31.2px;
        font-weight: 400 !important;
    }

    .intro_iconContainer__hH5rh > svg:first-child {
        margin-left: -50px;
    }
}

@media (min-width: 769px) {
    .intro_intro__1tsWn {
        padding-top: 119px !important;
        margin-top: 0 !important;
    }
}

@media (min-width: 992px) {
    .intro_intro__1tsWn > div:last-child {
        margin-top: 50px;
    }
}

@media (min-width: 1200px) {
    .intro_iconContainer__hH5rh > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .intro_iconContainer__hH5rh > svg:first-child {
        margin-left: -10px !important;
    }
}
.eventTypeList_container__18TcY > div {
    position: relative;
}

.eventTypeList_container__18TcY > div > div:nth-child(2) {
    margin-top: 30px;
}

.eventTypeList_container__18TcY{
    width: 100%;
}

.eventTypeList_divider__3tyA_ {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 20px;
    margin-left: -90px;
}

.eventTypeList_labelContainer__DGzXe {
    display: flex;
    align-items: center;
}

.eventTypeList_labelContainer__DGzXe svg {
    width: 20px;
    height: 20px;
}

.eventTypeList_labelContainer__DGzXe:hover {
    cursor: pointer;
}

.eventTypeList_labelContainer__DGzXe span {
    color: var(--color-black) !important;
}

.eventTypeList_mainLabel__24f2p h1 {
    margin-bottom: 0 !important;
}

.eventTypeList_mainLabelText__yd_NI {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
}

.eventTypeList_resultsContainer__2D-OF {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.eventTypeList_resultsContainer__2D-OF > span {
    color: var(--color-tertiary) !important;
}

.eventTypeList_bar__bgEv_ {
    margin-right: .5rem;
}

.eventTypeList_removeIcon__AMryf {
    margin-right: .5rem;
}

@media (min-width: 1350px) {
    .eventTypeList_divider__3tyA_ {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}
.searchBarFilters_listContainer__1fnYh {
    width: 100%;
}

.searchBarFilters_sectionTitle__2bxiL {
    margin-top: 30px;
    padding-bottom: 8px;
}

.searchBarFilters_sectionTitle__2bxiL > h1{
    font-weight: 400;
    margin: 0;
}

.searchBarFilters_divider__3f_7D {
    margin: 8px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.searchBarFilters_seeMore__2806F button {
    padding-left: 22px;
    padding-right: 22px;
}

.searchBarFilters_searchInputRow__boFC6{
    width: 100%;
    margin-top: 30px !important;
}

.searchBarFilters_renderRow__uzfzi{
    margin-top: 50px !important;
}

.searchBarFilters_arrowNext__maZ1x {
    transform: rotate(180deg);
}

.searchBarFilters_searchInputContainer__3cXJs {
    width: 100%;
}

.searchBarFilters_searchInputContainer__3cXJs > .searchBarFilters_searchInput__1fx4j {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.searchBarFilters_searchInputContainer__3cXJs > .searchBarFilters_searchInput__1fx4j > .ant-input-suffix {
    width: 290px;
}

.searchBarFilters_searchInput__1fx4j input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 26px;
    letter-spacing: 0px;
    line-height: 26px;
}

.searchBarFilters_searchInput__1fx4j input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
}

.searchBarFilters_searchInput__1fx4j input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
}

.searchBarFilters_searchInput__1fx4j .ant-input-prefix {
    margin-right: unset;
}

.searchBarFilters_searchInputIcon__1KYy5 {
    width: 24px;
    height: 24px;
    margin-right: 18px;
}

.searchBarFilters_filter__3hsaK {
    margin-top: 30px !important;
    width: 100%;
}

.searchBarFilters_filter__3hsaK > div:nth-child(2) span{
    font-family: "GT-America" !important;
    font-weight: 300 !important;
    font-size: 12px !important;
}

.searchBarFilters_dropdownContainer__3EbNR {
    margin-bottom: 18px;
}

.searchBarFilters_overlay__61uRO > div {
    padding: 0;
    margin-top: -4px;
}

.searchBarFilters_overlay__61uRO *:hover {
    background-color: var(--color-white) !important;
}

.searchBarFilters_overlay__61uRO [ant-click-animating-without-extra-node='true']::after,
.searchBarFilters_overlay__61uRO .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow:none;
    opacity: 1;
    animation: none;
    animation-fill-mode: none;
    pointer-events: none;
}

.searchBarFilters_dropdownRow__3gPZe {
    border-bottom: solid 1px var(--color-black);
}

.searchBarFilters_dropdownRow__3gPZe h1 {
    margin-bottom: 6px;
    font-weight: 600;
}

.searchBarFilters_checkboxGroup__27ndD {
    width: 100%;
}

.searchBarFilters_checkboxGroup__27ndD > label {
    margin: 9px 0;
}

.searchBarFilters_clearButton__3gq93 {
    padding-left: 0 !important;
    font-weight: 600 !important;
}

.searchBarFilters_labelContainer__11hzZ {
    display: flex;
    align-items: center;
}

.searchBarFilters_selectOption__116hi {
    margin-left: unset !important;
}

.searchBarFilters_labelContainer__11hzZ svg {
    width: 20px;
    height: 20px;
}

.searchBarFilters_labelContainer__11hzZ:hover {
    cursor: pointer;
}

.searchBarFilters_label__1pQ1V {
    margin-left: .5rem;
}

.searchBarFilters_removeIcon__Mrudo{
    margin-right: .5rem;
}

.searchBarFilters_containerLoading__3t4Fn {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

.searchBarFilters_clearAll__CYJVE > span:nth-child(2){
    margin-left: .5rem;
}

.searchBarFilters_results__1KVwd {
    margin-right: .5rem;
}

.searchBarFilters_display__Ns5-y {
    display: block !important;
}

.searchBarFilters_listLarge__3_oIu {
    display: none !important;
}

.searchBarFilters_listLarge__3_oIu h1 {
    margin-top: 1.5rem;
}

.searchBarFilters_listSmall__2G6Xg {
    margin-top: 0 !important;
}

.searchBarFilters_listSmall__2G6Xg > div > div:nth-child(1) {
    display: none;
}

.searchBarFilters_listSmall__2G6Xg > div > div:nth-child(2) {
    margin-top: 0 !important;
}

.searchBarFilters_selectAll__35y8-{
    margin-top: 9px;
    width: 100%;
}

.searchBarFilters_dropdownOptionsContainer__3GcJh > div:first-child {
    padding-top: 9px;
    padding-bottom: 9px;
}

.searchBarFilters_dropdownOptionsContainer__3GcJh > div:nth-child(2) {
    padding-top: 0;
    padding-bottom: 0;
}

@media (min-width: 576px) {
    .searchBarFilters_cardContainer__2JJn9:nth-child(2n) {
        margin-left: calc(100%/12);   
    }
}

@media (min-width: 710px) {

    .searchBarFilters_suffixContainer__1lN7v {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .searchBarFilters_searchInputRow__boFC6 {
        margin-top: 48px !important;
    }

    .searchBarFilters_divider__3f_7D {
        width: 100vw;
        margin-left: -50px;
    }

    .searchBarFilters_cardContainer__2JJn9 {
        margin-left: 0 !important;   
    }

    .searchBarFilters_cardContainer__2JJn9:nth-child(2n + 1) {
        margin-left: 0;   
    }

    .searchBarFilters_cardContainer__2JJn9:not(:nth-child(3n + 1)) {
        margin-left: calc(300%/24/2) !important;   
    }
}
@media (min-width: 992px) {

    .searchBarFilters_cardContainer__2JJn9:not(:nth-child(3n + 1)) {
        margin-left: 0 !important;   
    }

    .searchBarFilters_cardContainer__2JJn9:not(:nth-child(4n + 1)) {
        margin-left: calc(100%/6/3) !important;   
    }
}

@media (min-width: 1024px) {
    .searchBarFilters_filter__3hsaK {
        display: none !important;
    }

    .searchBarFilters_listLarge__3_oIu {
        display: block !important;
    }

    .searchBarFilters_listSmall__2G6Xg {
        display: none !important;
    }

    .searchBarFilters_cardListContainer__XNT8Y {
        margin-top: 4rem !important;
    }
}

@media screen and (min-width: 769px) {
    .searchBarFilters_divider__3f_7D {
        margin-left: -90px;
    }
}

@media screen and (min-width: 1350px) {
    .searchBarFilters_divider__3f_7D {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}




.intro_title__1bidQ {
    margin-top: 10px;
    margin-bottom: 25px !important;
    font-size: 30px !important;
    line-height: 38px !important;
    font-family: "DomaineDisplay";
    text-align: left;
}

.intro_lastUpdate__1mzzC {
    margin-top: 0px !important;
    margin-bottom: 30px;
    color: #666666 !important;
}

.intro_introContainer__3o2aR {
    margin-bottom: 40px;
}

.intro_postImage__2axJU img {
    object-fit: cover;
    max-height: 600px;
    width: inherit;
}

@media (min-width: 768px) {
    .intro_title__1bidQ {
        font-size: var(--font-size-title-page) !important;
        line-height: var(--line-height-title) !important;
    }

    .intro_postImage__2axJU img {
        width: unset;
    }
}
.post_loadingContainer__1gMUl {
    padding-top: 80px;
    padding-bottom: 60px;
}

.post_bodyContainer__9FxPd {
    margin-bottom: 50px;
    text-align: justify;
}

.post_separator__k3vxL {
    padding-bottom: 15px !important;
    margin-bottom: 1px !important;
}

.post_separator__k3vxL hr {
    position: absolute;
    left: 0;
    border-top: 1px solid var(--color-black);
}

.post_introContainer__3Bg46 {
    margin-bottom: 20px;
}

.post_linkSeparator__2hPUh hr {
    margin-top: unset;
    border-top: 1px solid var(--color-black);
}

.post_linkSeparator__2hPUh {
    margin-bottom: 10px !important;
}

@media screen and (min-width: 769px) { 
    .post_linkList__2iW5I {
        margin-bottom: 50px;
    }

    .post_linksContainer__1Tf2M {
        margin: auto;
        max-width: 270px;
    }
}

@media screen and (min-width: 1024px) {
    .post_pageBody__1Z53Q p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
    
    .post_pageBody__1Z53Q p {
        color: var(--color-secondary);
        font-size: 18px;
    }

    .post_pageBody__1Z53Q h1 {
        font-family: "DomaineDisplay";
        font-size: 26px;
    }
}


.lead_lead__3CTlV {
    font-size: 18px;
    line-height: 26px;
    color: var(--color-black);
    margin-bottom: 20px;
    font-family: "DomaineDisplay";
    margin-bottom: 0 !important;
}
 
 @media (min-width: 480px) and (max-width: 767px) {
    .lead_lead__3CTlV {
       font-size: 15px;
       font-weight: 400;
       line-height: 20px
    }
 }

 @media (min-width: 768px) {
    .lead_lead__3CTlV {
       font-size: var( --font-size-lead) !important;
       font-weight: 400;
       line-height: var(--line-height-lead) !important;
    }
 }
.sectionTitle_title__dx1__ {
    font-size: 26px;
    color: var(--color-black);
    margin-bottom: 20px;
    font-family: "DomaineDisplay";
}

@media (min-width: 1024px) {
    .sectionTitle_title__dx1__ {
        font-size: 30px;
    }
}

.relatedContent_sectionTitle__2TpBR {
    padding-top: 25px;
    font-size: 26px;
    line-height: 32px;
}

.relatedContent_arrowNext__24HP2 {
    transform: rotate(180deg);
}

.relatedContent_listItem__OtGiJ {
    margin: 0 auto;
}

.relatedContent_listRowContainer__2_8ic {
    width: 100%;
}

.relatedContent_sectionRow__3sFhb { 
    margin-bottom: unset;
}

@media screen and (min-width: 769px) {
    .relatedContent_sectionTitle__2TpBR {
        border-top: 1px solid black;
        padding-top: 50px;
        font-size: 40px;
    }

    .relatedContent_carousel__TR-Ov .slick-arrow.slick-prev {
        left: -45px;
    }
    
    .relatedContent_sectionRow__3sFhb { 
        margin-bottom: 40px;
    }
}

.usefulLinks_icon__22Vyw path {
    stroke: var(--color-black);
}

.usefulLinks_link__1uhW3:hover {
    opacity: 0.5;
}

.usefulLinks_linkText__1ZjP- {
    width: 270px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;   
}

@media screen and (min-width: 769px) {
    .usefulLinks_linkText__1ZjP- {
        width: 150px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;   
    }
}

@media screen and (min-width: 900px) {
    .usefulLinks_linkText__1ZjP- {
        width: 210px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;   
    }
}

@media screen and (min-width: 1024px) {
    .usefulLinks_linkText__1ZjP- {
        width: 230px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;   
    }
}
.linkTitle_linkTitle__oAhKs {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 14px;
    color: var(--color-black);
    text-decoration: underline;
}

@media (min-width: 1024px) {
    .linkTitle_linkTitle__oAhKs {
        font-size: 16px;
        line-height: 24px;
    }
}
.intro_intro__2JIDF {
    margin-top: 58px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    background-color: transparent;
    max-width: none !important;
    position: relative;
    background-image: url(/static/media/broadcast.df813589.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.intro_intro__2JIDF > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro_intro__2JIDF h1{
    margin: 0px !important;
    margin-bottom: 20px !important;
}

.intro_intro__2JIDF > div:last-child {
    display: flex;
    flex-direction: column;
}

.intro_pagination__1z4cP .intro_phoneBottomReachedMessage__BhKtt {
    display: none;
}

.intro_loading__3TPxm {
    background: none !important;
}

.intro_intro__2JIDF > div > span {
    font-family: "GT-America";
    font-size: 18px !important;
    line-height: 26px !important;
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
}

.intro_iconContainer__2o0mF {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.intro_iconContainer__2o0mF > svg:first-child {
    display: block;
    margin-left: -290px;
}

.intro_iconContainer__2o0mF > svg:last-child {
    display: none;
}

.intro_intro__2JIDF {
    margin-bottom: 40px !important;
}

.intro_descriptionContainer__3HB7z > .intro_description__H5p0t {
    font-family: "GT-America";
    font-size: var(--font-size-page-description) !important;
    line-height: var(--line-height-page-description) !important;
    font-weight: 300 !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .intro_descriptionContainer__3HB7z > .intro_description__H5p0t {
       font-size: 15px !important;
       font-weight: 400 !important;
       line-height: 20px !important;
    }
 }

@media (min-width: 768px) {
    .intro_intro__2JIDF > div > h1 {
        -webkit-margin-before: unset !important;
                margin-block-start: unset !important;
    }

    .intro_iconContainer__2o0mF > svg:first-child {
        margin-left: -210px;
    }

    .intro_descriptionContainer__3HB7z > .intro_description__H5p0t {
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 25px !important;
      }
}

@media (min-width: 769px) {
    .intro_intro__2JIDF {
        padding-top: 145px !important;
        margin-top: 0 !important;
    }
}

@media (min-width: 992px){
    .intro_intro__2JIDF > div > span:last-child {
        margin-bottom: 40px;
    }
}

@media (min-width: 1200px) {
    .intro_iconContainer__2o0mF > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .intro_iconContainer__2o0mF > svg:first-child {
        margin-left: -150px !important;
    }
}

@media (min-width: 2340px) {
    .intro_intro__2JIDF {
        display: block;
    }
}
.list_searchInputContainer__2JB9_ {
    width: 100%;
    margin-bottom: 40px;
}

.list_searchInputContainer__2JB9_ > .list_searchInput__1hECF {
    height: 50px;
    padding-right: unset;
    padding-left: 15px;
}

.list_searchInput__1hECF input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 20px;    
}

.list_searchInput__1hECF input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.list_searchInput__1hECF input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.list_searchInput__1hECF .ant-input-prefix {
    margin-right: unset;
}

.list_searchInputIcon__34Wbo {
    width: 20px !important;
    height: 20px !important;
    margin-right: 18px;
    margin-left: 13px;
}

.list_cardListContainer__4rTz1 {
    margin: 2rem auto 40px auto !important;
}

.list_pagination__DcCz2 {
    display: none;
}

.list_resultsContainer__16nec {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.list_resultsContainer__16nec > span {
    color: var(--color-tertiary) !important;
}

.list_tabMenuContainer__2RxqU {
    width: 100%;
    margin-bottom: 20px;
}

.list_noMarginBottom__1ErDE {
    margin-bottom: 0;
}

.list_divider__15Gn1 {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-medium-gray);
    margin-top: 20px;
    margin-left: -90px;
}

.list_divider__15Gn1 {
    display: none;
}

.list_listItem__16lJr {
    margin: 0 auto;
}


@media (min-width: 768px) {
    .list_divider__15Gn1 {
        display: block;
    }

    .list_tabMenuContainer__2RxqU {
        width: 100%;
        margin-bottom: 40px;
    }
}


@media (min-width: 1009px) {
    .list_displayMobile__3ShvD {
        display: none;
    }
}

@media (min-width: 1024px) {
    .list_cardListContainer__4rTz1 {
        margin-top: 4rem !important;
    }
}

@media (min-width: 1350px) {
    .list_divider__15Gn1 {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}

.filterDesktop_filtersDropdown__3kGOj > .ant-dropdown-menu {
    right: -20px !important;
}

.filterDesktop_menuFilterContainer__13KHw, .filterDesktop_menuOrderContainer__1Pr0z {
    padding: 20px !important;
    top: 15px;
}

.filterDesktop_menuFilterContainer__13KHw > .ant-dropdown-menu-item:first-child, .filterDesktop_menuOrderContainer__1Pr0z > .ant-dropdown-menu-item {
    margin-bottom: 20px;
}

.filterDesktop_menuFilterContainer__13KHw > .ant-dropdown-menu-item, .filterDesktop_menuOrderContainer__1Pr0z > .ant-dropdown-menu-item {
    padding: 0;
}
 
.filterDesktop_menuOrderContainer__1Pr0z > .ant-dropdown-menu-item:last-child {
    margin-bottom: 0;
}

.filterDesktop_menuFilterContainer__13KHw > .ant-dropdown-menu-item > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}


div > .filterDesktop_selectGroup__1EjQO {
    display: inline-flex;
    flex-direction: column;
}

.filterDesktop_selectGroup__1EjQO > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.filterDesktop_selectGroup__1EjQO > label > span, .filterDesktop_radioGroup__1QPzI > div > div > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}

.filterDesktop_radioGroup__1QPzI:hover {
    background: unset;
    opacity: unset;
}

.filterDesktop_filterContainer__3oQ8U {
    display: flex;
    align-items: center;
}

.filterDesktop_filterContainer__3oQ8U > div > .filterDesktop_divider__2hiaF {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.filterDesktop_menuOrderContainer__1Pr0z .ant-space-item {
    width: 100%;
}

.filterDesktop_pointer__249jl {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.filterDesktop_filterLabel__2tved {
    display: block;
    width: 100%;
}

.filterDesktop_filterLabel__2tved > span {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterDesktop_chevron__24t7S {
    margin-left: 15px;
    width: 14px;
}

.filterDesktop_control__xOg2w {
    display: flex;
    margin-right: 20px;
}

.filterDesktop_control__xOg2w > span {
    display: flex;
}

.filterDesktop_clearButton__1LyEG {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
}

.filterDesktop_menuFilterContainer__13KHw > .ant-dropdown-menu-item:hover, .filterDesktop_menuOrderContainer__1Pr0z > .ant-dropdown-menu-item:hover {
    background-color: unset
}

.filterDesktop_dropdown__2TXO- {
    padding-right: 20px;
    width: 290px;
    display: flex;
}

.filterDesktop_slider__2cltK .ant-slider-step,
.filterDesktop_slider__2cltK .ant-slider-rail,
.filterDesktop_slider__2cltK .ant-slider-track {
    height: 2px;
}

.filterDesktop_slider__2cltK .ant-slider-dot {
    display: none;
}

.filterDesktop_slider__2cltK .ant-slider-handle {
    background-color: var(--color-black);
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.filterDesktop_slider__2cltK .ant-slider-mark-text {
    margin-top: 5px;
}

.filterDesktop_selectGroup__1EjQO > .ant-checkbox-wrapper {
    margin-left: 0;
}
.dropdownFiltersMobile_dropdown__3jvg7 > span {
    width: 100%;
    display: block;
    border-bottom: 1px solid var(--color-black);
}

.dropdownFiltersMobile_dropdown__3jvg7 > span > span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdownFiltersMobile_label__1cvNk {
    font-weight: 500;
    font-family: "DomaineDisplay";
    font-size: 26px !important;
}

.dropdownFiltersMobile_dropdownOverlay__1qP_n > div {
    margin-top: -4px;
}

.dropdownFiltersMobile_dropdownOverlay__1qP_n .ant-dropdown-menu-item {
    background-color: var(--color-white);
}

.dropdownFiltersMobile_dropdownOverlay__1qP_n > div .ant-checkbox-group {
    display: flex;
    flex-direction: column;
}

.dropdownFiltersMobile_dropdownOverlay__1qP_n > div > div:nth-child(2) .ant-checkbox-wrapper {
    margin: 9px 0;
}

.dropdownFiltersMobile_clearButton__3T1Ec {
    font-weight: 600;
}

.dropdownFiltersMobile_clearAllDisabled__3TGTF {
    color: var(--color-medium-gray);
}
.tabMenu_dropdownRow__3Dnc9 {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 32px;
}

.tabMenu_dropdownRow__3Dnc9 > div > h1 {
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 500 !important;
}

.tabMenu_dropdownRow__3Dnc9 > div:first-child {
    max-width: 90%;
    flex: 1 1;
}

.tabMenu_dropdownRow__3Dnc9 h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
}

@media (min-device-width: 768px) {
    .tabMenu_dropdownRow__3Dnc9 h1 {
        font-size: 18px !important;
    }

    .tabMenu_tabTitle__zQ_mf {
        font-size: 30px;
        font-weight: 500 !important;
    }
}

@media screen and (min-device-width: 1024px) {
    .tabMenu_dropdownRow__3Dnc9 h1 {
        font-size: 24px !important;
    }
}
.broadcast_filterIconMobile__2AOQ0 {
    position: fixed !important;
    background-color: var(--color-black);
    bottom: 25px;
    right: 25px;
    width: 60px !important;
    height: 60px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
}

.broadcast_filterIconMobile__2AOQ0 > svg > g line, 
.broadcast_filterIconMobile__2AOQ0 > svg > g circle {
    stroke: var(--color-white);
}

.broadcast_activeFilters__3Tao2 {
    width: .75rem;
    height: .75rem; 
    background-color: var(--color-call-action);
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50%;
}

@media (min-width: 1024px) {
    .broadcast_filterIconMobile__2AOQ0 {
        display: none;
    }
}
.filterMobile_drawer__2aQtQ {
    display: flex;
    justify-content: center;
}

.filterMobile_drawer__2aQtQ > div:nth-child(2) {
    max-width: 500px;
}

.filterMobile_drawer__2aQtQ .ant-drawer-close {
    padding: 14px;
}

.filterMobile_drawer__2aQtQ .ant-drawer-content-wrapper {
    max-height: 700px;
}

.filterMobile_headerTitle__1LMqJ {
    display: flex;
    align-items: center;
}

.filterMobile_headerTitle__1LMqJ > svg {
    margin-right: 10px;
}

.filterMobile_titleRadio__28XTr {
    display: block;
}

.filterMobile_radioGroup__3a-Yc > div {
    margin-top: .75rem;
}

.filterMobile_titleCheckbox__3zy8X {
    display: block;
}

.filterMobile_checkboxGroup__1eQ8W > label {
    margin: 9px 0;
}

.ant-tooltip {
    padding: 0 !important;
}

.ant-tooltip-inner  {
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 0 !important;
    box-shadow: none !important;
    color: var(--color-black) !important;
}

.ant-tooltip-arrow {
    display: none !important;
}

.filterMobile_slider__2K0L9 .ant-slider-step,
.filterMobile_slider__2K0L9 .ant-slider-rail,
.filterMobile_slider__2K0L9 .ant-slider-track {
    height: 2px;
}

.filterMobile_slider__2K0L9 .ant-slider-dot {
    display: none;
}

.filterMobile_slider__2K0L9 .ant-slider-handle {
    background-color: var(--color-black);
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.filterMobile_slider__2K0L9 .ant-slider-mark-text {
    margin-top: 5px;
}

.filterMobile_buttonsContainer__1Md4G {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
}

.filterMobile_buttonsContainer__1Md4G > div {
    width: 100%;
    display: flex;
}

.filterMobile_buttonsContainer__1Md4G button {
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    height: 100%;
}

.filterMobile_tertiary__1enWE > span {
    color: var(--color-tertiary) !important;
}

@media (min-device-width: 500px) {
    .filterMobile_drawer__2aQtQ {
        display: flex;
        align-items: center;
    }

    .filterMobile_drawer__2aQtQ .ant-drawer-content-wrapper {
        position: static !important;
    }
}

@media (min-width: 1024px) {
    .filterMobile_drawer__2aQtQ {
        display: none;
    }
}
.intro_title__1Qa7- {
    margin-top: 10px;
    margin-bottom: 25px !important;
    font-size: 30px !important;
    line-height: 38px !important;
}

.intro_lastUpdate__1XKvB { 
    margin-top: 0px !important; 
    margin-bottom: 30px; 
}

.intro_introContainer__2AliU {
    margin-bottom: 40px;
}

.intro_videoContainer__GFof9 {
    width: 100%;
}

.intro_videoContainer__GFof9 > * {
    width: 100%;
}

.intro_wrapper__1F6oy {
    position: relative;
    padding-top: 56.25%;
}

.intro_player__2OnRr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.intro_spotifyFrame__3U7q- {
    height: 360px;
}

html:not(:root) body .intro_embedVideoMain__3TLBI a {
    font-size: 16px !important;
    line-height: 24px !important;
}

@media (min-width: 1024px) {
    .intro_title__1Qa7- {
        font-size: 40px !important;
        line-height: 48px !important;
    }

    .intro_postImage__17U3h img {
        object-fit: cover;
        width: 100%;
    }
}
.shareButton_shareButtonContainer__20Em8:hover {
    cursor: pointer;
    opacity: 0.5;
}

.shareButton_shareButtonContainer__20Em8 > div:first-child {
    margin-right: 10px;
}

.video_loadingContainer__27DVU {
    padding-top: 80px;
    padding-bottom: 60px;
}

.video_separator__1uk-d {
    padding-bottom: 15px !important;
    margin-bottom: 1px !important;
}

.video_separator__1uk-d hr {
    position: absolute;
    left: 0;
    border-top: 1px solid var(--color-black);
}

.video_introContainer__7EDfz {
    margin-bottom: 20px;
}

.video_linkSeparator__H0i8F hr {
    margin-top: unset;
    border-top: 1px solid var(--color-black);
}

.video_linkSeparator__H0i8F {
    margin-bottom: 10px !important;
}

@media screen and (min-width: 769px) { 
    .video_linkList__1DN0Y {
        margin-bottom: 50px;
    }

    .video_linksContainer__3TBhn {
        margin: auto;
        max-width: 270px;
    }
}

@media screen and (min-width: 1024px) {
    .video_pageBody__UBs45 p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
    
    .video_pageBody__UBs45 p {
        color: var(--color-secondary);
        font-size: 18px;
    }

    .video_pageBody__UBs45 h1 {
        font-family: "DomaineDisplay";
        font-size: 26px;
    }
}

.intro_intro__2hG4u {
    margin-top: 58px !important;
    padding-top: 40px !important;
    padding-bottom: 60px !important;
    max-width: none !important;
    position: relative;
    background-color: #F1F1F1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.intro_intro__2hG4u > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro_intro__2hG4u h1{
    margin: 0px !important;
    /* margin-bottom: 20px !important; */
}

.intro_intro__2hG4u > div:last-child {
    display: flex;
    flex-direction: column;
}

.intro_introHeader__297Qx {
    margin-bottom: 40px !important;
}

.intro_intro__2hG4u > div > span {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
}

.intro_iconContainer__1SJSr {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.intro_iconContainer__1SJSr > svg:first-child {
    height: 100%;
    width: 16%;
    display: block;
    margin-left: -290px;
}

.intro_iconContainer__1SJSr > svg:last-child {
    display: none;
}

.intro_intro__2hG4u {
    margin-bottom: 60px !important;
}

@media (min-width: 768px) {
    .intro_intro__2hG4u > div > h1 {
        -webkit-margin-before: unset !important;
                margin-block-start: unset !important;
    }

    .intro_iconContainer__1SJSr > svg:first-child {
        margin-left: -210px;
    }
}

@media screen and (min-width: 481px) {
    .intro_introHeader__297Qx {
        margin-bottom: 60px !important;
    }
}

@media (min-width: 769px) {
    .intro_intro__2hG4u {
        padding-top: 145px !important;
        margin-top: 0px !important;
    }
}

@media (min-width: 992px){
    .intro_intro__2hG4u > div > span:last-child {
        margin-bottom: 40px;
    }
}

@media (min-width: 1200px) {
    .intro_iconContainer__1SJSr > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .intro_iconContainer__1SJSr > svg:first-child {
        margin-left: -150px !important;
    }
}

@media (min-width: 2340px) {
    .intro_intro__2hG4u {
        display: block;
    }
}
.core_sectionHeader__3o87j {
    font-size: 22px;
    color: black;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D5D5D5;
}

.core_sectionItemsContainer__1L9bL {
    margin-bottom: 25px;
}

@media screen and (min-width: 768px) {

    .core_sectionHeader__3o87j {
        font-size: 26px !important;
    }

}
.application_applicationItem__37lck {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.application_applicationItem__37lck:hover .application_applicationItemTitle__3L_5k {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.application_applicationItem__37lck:last-child {
    margin-bottom: unset;
}

.application_applicationItemTitle__3L_5k {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.application_noResultsMessage__1FrGF {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.application_highlight__PFrwG {
    background-color: black;
    color: white;
    padding: unset !important;
}

.application_button__2wtc8 {
    margin-bottom: unset;
}

.application_button__2wtc8:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.broadcast_broadcastItem__2oq95 {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.broadcast_broadcastItem__2oq95:hover .broadcast_broadcastItemTitle__1oOyF {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.broadcast_broadcastItem__2oq95:last-child {
    margin-bottom: unset;
}

.broadcast_broadcastItemTitle__1oOyF {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.broadcast_broadcastItemCategory__2Wg1f {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300 !important;
    font-size: 12px !important;
    margin-top: unset;
    line-height: unset;
}

.broadcast_noResultsMessage__3HiWN {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.broadcast_highlight__29ywI {
    background-color: black;
    color: white;
    padding: unset !important;
}

.broadcast_button__2KF8H {
    margin-bottom: unset;
}

.broadcast_button__2KF8H:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.category_categoryItem__1wNTx {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.category_categoryItem__1wNTx:last-child {
    margin-bottom: unset;
}

.category_categoryItem__1wNTx:hover{
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.category_categoryItemTitle__3aYaf {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.category_highlight__e_AE2 {
    background-color: black;
    color: white;
    padding: unset !important;
}

.category_noResults__1Xws7 {
    margin-bottom: unset !important;
}

.category_loading__2Fz-c {
    opacity: 0.5;
    cursor:not-allowed;
}

.category_noResultsMessage__1ncu8 {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.category_button__2AQTT {
    margin-bottom: unset;
}

.category_button__2AQTT:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.event_eventItemCategory__26o3J {
    display: flex;
    align-items: center;
    margin-top: unset;
    line-height: unset;
}

.event_eventItemCategory__26o3J div:first-child {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.event_eventItemCategory__26o3J span {
    text-transform: uppercase;
    color: var(--color-secondary) !important;
}

.event_eventItem__3Dy05 {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.event_eventItem__3Dy05:hover .event_eventItemTitle__3zvYS {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.event_eventItemTitle__3zvYS {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.event_eventItem__3Dy05:last-child {
    margin-bottom: unset;
}

.event_noResultsMessage__324A_ {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.event_highlight__-LPV_ {
    background-color: black;
    color: white;
    padding: unset !important;
}

.event_button__3Djw6 {
    margin-bottom: unset;
}

.event_button__3Djw6:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.news_newsItem__3QWbt {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.news_newsItem__3QWbt:hover .news_newsItemTitle__2IHaq {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.news_newsItem__3QWbt:last-child {
    margin-bottom: unset;
}

.news_newsItemCategory__aIrZY {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300 !important;
    font-size: 12px !important;
    margin-top: unset;
    line-height: unset;
}

.news_newsItemTitle__2IHaq {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.news_noResultsMessage__3IJc7 {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.news_highlight__3Dd5D {
    background-color: black;
    color: white;
    padding: unset !important;
}

.news_button__GfQ1x {
    margin-bottom: unset;
}

.news_button__GfQ1x:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.other_otherItem__1rt_W {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.other_otherItem__1rt_W:hover .other_otherItemTitle__8MzcY {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.other_otherItem__1rt_W:last-child {
    margin-bottom: unset;
}

.other_otherItemCategory__2RzTI {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300 !important;
    font-size: 12px !important;
    margin-top: unset;
    line-height: unset;
}

.other_otherItemTitle__8MzcY {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.other_loading__22wbh {
    opacity: 0.5;
    cursor:not-allowed;
}

.other_highlight__xxfFR {
    background-color: black;
    color: white;
    padding: unset !important;
}

.other_noResultsMessage__MGMTk {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.other_button__2wKh1 {
    margin-bottom: unset;
}

.other_button__2wKh1:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.page_pageItem__24Q9V {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.page_pageItem__24Q9V:last-child {
    margin-bottom: unset;
}

.page_pageItemTitle__3LZ1V {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.page_pageItemTitle__3LZ1V:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.page_noResults__lh4xv {
    margin-bottom: unset !important;
}

.page_loading__3VGgy {
    opacity: 0.5;
    cursor:not-allowed;
}

.page_highlight__3issy {
    background-color: black;
    color: white;
    padding: unset !important;
}

.page_noResultsMessage__2lCI- {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.page_button__10mgV {
    margin-bottom: unset;
}

.page_button__10mgV:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.partnership_partnershipItem__38puT {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.partnership_partnershipItem__38puT:hover .partnership_partnershipItemTitle__3UlCN {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.partnership_partnershipItem__38puT:hover .partnership_partnershipItemCategory__2_qhP {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.partnership_partnershipItem__38puT:last-child {
    margin-bottom: unset;
}

.partnership_partnershipItemTitle__3UlCN {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.partnership_partnershipItemCategory__2_qhP {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}

.partnership_noResultsMessage__13aNd {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.partnership_highlight__3ySfO {
    background-color: black;
    color: white;
    padding: unset !important;
}

.partnership_button__13FTf {
    margin-bottom: unset;
}

.partnership_button__13FTf:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.team_teamItem__2Q7_r {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.team_teamItem__2Q7_r:hover .team_teamItemTitle__3HbZ2 {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.team_teamItem__2Q7_r:hover .team_teamItemDepartment__26YYB {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.team_teamItem__2Q7_r:last-child {
    margin-bottom: unset;
}

.team_teamItemTitle__3HbZ2 {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.team_teamItemDepartment__26YYB {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}

.team_highlight__1SrI4 {
    background-color: black;
    color: white;
    padding: unset !important;
}

.team_noResultsMessage__2DBhp {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.team_button__QF5B5 {
    margin-bottom: unset;
}

.team_button__QF5B5:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.user_userItem__1StHk {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.user_userItem__1StHk:hover .user_userItemTitle__2P7HL {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.user_userItem__1StHk:hover .user_userItemDepartment__lGgda {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.user_userItem__1StHk:last-child {
    margin-bottom: unset;
}

.user_userItemTitle__2P7HL {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.user_userItemDepartment__lGgda {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}

.user_noResultsMessage__YsvoK {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.user_highlight__1W2PM {
    background-color: black;
    color: white;
    padding: unset !important;
}

.user_button__10WaR {
    margin-bottom: unset;
}

.user_button__10WaR:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}
.omniResults_searchBar__1SMy9 .ant-input-prefix {
    margin-right: 16px !important;
}

.omniResults_searchBar__1SMy9 .ant-input-affix-wrapper {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.omniResults_searchBar__1SMy9 .ant-input-affix-wrapper:focus,
.omniResults_searchBar__1SMy9 .ant-input-affix-wrapper:hover {
    box-shadow: none !important;
    border: none !important;
}

.omniResults_searchBar__1SMy9 input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 22px;
    color: black;
    background-color: transparent !important;
}

.omniResults_searchBar__1SMy9 input:-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.omniResults_searchBar__1SMy9 input::placeholder {
    color: var(--color-light-gray-2);
}

.omniResults_searchBar__1SMy9 input::-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.omniResults_searchbarDropdownChevron__1hSSa {
    margin-right: 4px;
}

.omniResults_searchbarDropdown__1XfF8 {
    top: 378px !important;
    z-index: 100000 !important;
}

.omniResults_searchbarDropdown__1XfF8 .ant-dropdown-menu {
    padding: 5px 0 !important; 
}

.omniResults_searchbarDropdown__1XfF8 .ant-dropdown-menu-item {
    color: var(--color-secondary);
    background-color: white !important;
    padding: 8px 12px 8px 20px !important; 
}

.omniResults_searchbarDropdown__1XfF8 .ant-dropdown-menu-item > span {
    color: var(--color-secondary);
}

.omniResults_searchbarDropdown__1XfF8 .ant-dropdown-menu-item-selected > span {
    color: black
}

.omniResults_searchbarDropdown__1XfF8 .ant-dropdown-menu-item-active > span {
    color: black
}

.omniResults_searchbarDropdownTitle__3l7vv {
    line-height: 60px;
    font-family: "GT-America";
    text-align: center;
    font-size: 16px !important;
    color: var(--color-light-gray-2);
}

.omniResults_dropdown__2LzO5 .omniResults_menu__47SwY {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-medium-gray);
}

.omniResults_close__6dpuT {
    align-self: center;
    width: 20px;
    height: 20px;
    justify-self: center;
    cursor: default;
    opacity: 0;
    pointer-events: none;
}

.omniResults_close__6dpuT path {
    stroke: var(--color-light-gray-2);
}

.omniResults_close__6dpuT:hover path {
    stroke: black;
}

.omniResults_sectionHeader__OOcZC {
    color: black;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D5D5D5;
}

.omniResults_displayCloseButton__v1gQR {
    cursor: pointer;
    opacity: 1;
    pointer-events: unset;
}

.omniResults_resultsSectionContainer__oeaeU {
    padding-top: 60px;
    padding-bottom: 60px;
}

.omniResults_resultsSectionContainer__oeaeU:first-child {
    padding-top: unset;
}

.omniResults_highlight__gDa4D {
    background-color: black;
    color: white;
    padding: unset !important;
}

.omniResults_sectionItemsContainer__3Qcap {
    margin-bottom: 25px;
}

.omniResults_centerSpinner__3zgiM {
    display: flex;
    justify-content: center;
}

.omniResults_button__OZzC9 {
    margin-bottom: unset;
}

.omniResults_button__OZzC9:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.omniResults_shortQueryMessage__2NaYr {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.omniResults_mobileSearchbarGrid__CCrVN {
    display: grid;
    grid-template-columns: auto 30px;
    align-items: center;
    height: 100%;
    border-bottom: var(--color-secondary) 1px solid;
    padding-bottom: 20px;
}

.omniResults_searchbarDropdownRow__kD9zd {
    border-bottom: #D5D5D5 0.75px solid;
    justify-content: space-between !important;
    cursor: pointer;
}

.omniResults_resultsContainer__JhdYx {
    max-width: none !important;
    padding: 0 !important;
}

.omniResults_resultsContainer__JhdYx > div > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
} 

.omniResults_resultsContainer__JhdYx > div > div {
    padding: 0 20px;
}


.omniResults_searchButton__2sg-t {
    font-family: "GT-America";
    font-size: 14px !important;
    font-weight: 400;
    color: black;
    text-align: end;
    cursor: default;
    opacity: 0;
    margin-right: 25px;
}

.omniResults_searchButton__2sg-t:hover {
    -webkit-text-decoration: underline solid var(--color-black) from-font;
            text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.omniResults_displaySearchButton__2zA_Z {
    cursor: pointer;
    opacity: 1 !important;
}


@media screen and (min-width: 692px) {

    .omniResults_dropdown__2LzO5 .omniResults_menu__47SwY {
        border-left: 1px solid var(--color-medium-gray);
        border-right: 1px solid var(--color-medium-gray);
        border-bottom: 1px solid var(--color-medium-gray);
    }
}

@media screen and (min-width: 768px) {

    .omniResults_searchbarDropdown__1XfF8 {
       top: 304px !important;
    }

    .omniResults_searchbarDropdownTitle__3l7vv {
        line-height: 34px;
    }

    .omniResults_searchbarDropdownRow__kD9zd {
        margin-bottom: unset;
        border-right: var(--color-light-gray-2) 0.75px solid;
        border-bottom: unset;
        padding: 0 25px 0 15px;
    } 

    .omniResults_searchBar__1SMy9 .ant-input-affix-wrapper {
        padding: 0 0 0 25px !important;
    }

    .omniResults_searchbarGrid__3POIr {
        display: grid;
        grid-template-columns: 190px auto -webkit-min-content 30px;
        grid-template-columns: 190px auto min-content 30px;
        align-items: center;
        height: 100%;
        border-bottom: black 2px solid;
        padding-bottom: 20px;
    }

    .omniResults_searchbarGridMaxWidth__1cziP {
        grid-template-columns: 220px auto -webkit-min-content 30px;
        grid-template-columns: 220px auto min-content 30px;
    }

    .omniResults_close__6dpuT {
        justify-self: unset;
    }

    .omniResults_resultsContainer__JhdYx > div > div {
        padding: 0 90px;
    }

}

@media screen and (min-width: 481px) {
    .omniResults_resultsContainer__JhdYx > div > div {
        padding: 0 50px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .omniResults_resultsContainer__JhdYx > div > div {
        padding: 0px 90px;
        max-width: none;
    }
}

@media screen and (min-width: 1350px) {
    .omniResults_resultsContainer__JhdYx > div > div {
        padding: 0px;
        max-width: var(--main-container-max-width);
    }
}
.pageNotFound_container__3dHTI {
    min-height: calc(100vh - 192px - 50px);
}

.pageNotFound_container__3dHTI > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.pageNotFound_container__3dHTI > div > div {
    margin-top: 0;
    margin-bottom: 0;
}

.pageNotFound_notFoundRowTitle__1re_6 {
    margin-top: 58px !important;
}

.pageNotFound_notFoundRowTitle__1re_6 > div {
    margin-top: 90px;
}

.pageNotFound_notFoundRowTitle__1re_6 h1 {
    text-align: center;
    font-size: 40px !important;
    line-height: 46px !important;
    margin-bottom: 30px !important;
}

.pageNotFound_notFoundRowMessage__CNZju > div > span {
    display: block;
    text-align: center !important;
    color: var(--color-gray) !important;
    font-size: 15px !important;
    line-height: 22px !important;
    margin-bottom: 20px;
}

.pageNotFound_homeLink__2B5rj {
    margin-top: 30px !important;
}

.pageNotFound_homeLink__2B5rj > div {
    display: flex;
    justify-content: center;
}

.pageNotFound_homeLink__2B5rj > div > a {
    padding: 5px 18px;
    text-decoration: none;
    border: 2px solid var(--color-black);
    position: relative;
}

.pageNotFound_homeLink__2B5rj span {
    font-size: 12px !important;
    font-weight: 500;
    position: relative;
    z-index: 1;
}

.pageNotFound_homeLink__2B5rj > div > a:hover {
    background-color: var(--color-black);
}

.pageNotFound_homeLink__2B5rj > div > a:hover > span {
    color: var(--color-white) !important;
}

@media (min-width: 574px) {
    .pageNotFound_container__3dHTI {
        min-height: calc(100vh - 170px - 50px);
    }
}

@media (min-width: 768px) {
    .pageNotFound_container__3dHTI {
        min-height: calc(100vh - 158px - 50px);
    }

    .pageNotFound_notFoundRowTitle__1re_6 h1 {
        font-size: 45px !important;
    }
}

@media (min-width: 890px) {
    .pageNotFound_container__3dHTI {
        min-height: calc(100vh - 136px - 50px);
    }
}
.categoriesList_searchInputContainer__2Ik7G {
    width: 100%;
    margin-bottom: 40px;
}

.categoriesList_searchInputContainer__2Ik7G > .categoriesList_searchInput__3SVGs {
    height: 70px;
    padding-right: unset;
    padding-left: 15px;
}

.categoriesList_searchInput__3SVGs input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 22px;
    line-height: 30px;
}

.categoriesList_searchInput__3SVGs input:-ms-input-placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.categoriesList_searchInput__3SVGs input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 22px;
    line-height: 30px;
}

.categoriesList_searchInput__3SVGs .ant-input-prefix {
    margin-right: unset;
}

.categoriesList_searchInputIcon__SlVax {
    width: 22px;
    height: 22px;
    margin-right: 18px;
    margin-left: 13px;
}

.categoriesList_resultsContainer__1McrH {
    display: flex;
    align-items: center;
    margin-right: .5rem;
}

.categoriesList_resultsContainer__1McrH > span {
    color: var(--color-tertiary) !important;
}

.categoriesList_listContainer__e4YNv{
    margin-top: 60px !important;
    margin:auto;
    width:100%;
}

.categoriesList_listItem__2GOeL {
    margin-bottom: 40px !important;
    display: flex !important;
    justify-content: space-between !important;
}

.categoriesList_listItem__2GOeL:hover {
    cursor: pointer;
}

.categoriesList_listItem__2GOeL:hover .categoriesList_listItemText__2RF6r {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.categoriesList_listItemText__2RF6r {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: unset;

}

.categoriesList_listRow__2B5j1 >div >div >div >div{
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.categoriesList_tooltip__3rFaQ{
    margin-top: 6px !important;
}

.categoriesList_tooltip__3rFaQ > div > div:nth-child(2){
    background-color: var(--color-light-gray) !important;
    color: var(--color-black) !important;
    padding: 12px 10px !important;
}

.categoriesList_pagination__3OiHz{
    justify-content: flex-start;
}

.categoriesList_divider__1V-AY {
    position: absolute;
    width: 100vw;
    left: 0;
    height: 1px;
    background-color: var(--color-medium-gray);
    margin-top: 20px;
    margin-left: -20px;
}

@media (min-width: 480px) {

    .categoriesList_divider__1V-AY {
        margin-left: -50px;
    }
}

@media (min-width: 769px) {

    .categoriesList_divider__1V-AY {
        margin-left: -195px;
    }
    
}

@media (min-width: 768px) {

    .categoriesList_pagination__3OiHz > ul {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    
    .categoriesList_pagination__3OiHz > ul > li:nth-child(2) {
        width: auto;
    }
}

@media (min-width: 1350px) {
    .categoriesList_divider__1V-AY {
        margin-left: 0;
        left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}
.intro_intro__2JVCt {
    margin-top: 58px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    background-color: transparent;
    max-width: none !important;
    position: relative;
    background-image: url(/static/media/categoriesMobile.f781af16.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.intro_intro__2JVCt > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro_intro__2JVCt h1{
    margin: 0px !important;
    margin-bottom: 20px !important;
}

.intro_intro__2JVCt > div:last-child {
    display: flex;
    flex-direction: column;
}

.intro_pagination__1f9IW .intro_phoneBottomReachedMessage__QjLp7 {
    display: none;
}

.intro_intro__2JVCt > div > span {
    font-family: "GT-America";
    font-size: var(--font-size-page-description) !important;
    line-height: var(--line-height-page-description) !important;
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
}

.intro_iconContainer__3ZEDc {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.intro_iconContainer__3ZEDc > svg:first-child {
    margin-left: -135px;
}

.intro_iconContainer__3ZEDc > svg:last-child {
    display: none;
}

.intro_intro__2JVCt {
    margin-bottom: 40px !important;
}

.intro_description__ihlrX{
        font-family: "GT-America";
        font-size: var(--font-size-page-description) !important;
        line-height: var(--line-height-page-description) !important;
        font-weight: 300 !important;
}

@media (min-width: 481px) {
    .intro_iconContainer__3ZEDc > svg:first-child {
        margin-left: -70px;
    }
}

@media (min-width: 768px) {
    .intro_intro__2JVCt > div > h1 {
        -webkit-margin-before: unset !important;
                margin-block-start: unset !important;
    }

    .intro_iconContainer__3ZEDc > svg:first-child {
        margin-left: -50px;
    }

    .intro_intro__2JVCt {
        padding-top: 145px !important;
        margin-top: 0 !important;
        background-image: url(/static/media/categories.5e4224b5.svg);
    }
}

@media (min-width: 992px){
    .intro_intro__2JVCt > div > span:last-child {
        margin-bottom: 40px;
    }
}

@media (min-width: 1200px) {
    .intro_iconContainer__3ZEDc > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .intro_iconContainer__3ZEDc > svg:first-child {
        margin-left: -20px !important;
    }
}

@media (min-width: 2340px) {
    .intro_intro__2JVCt {
        display: block;
    }
}
