.filterIconMobile {
    position: fixed !important;
    background-color: var(--color-black);
    bottom: 25px;
    right: 25px;
    width: 60px !important;
    height: 60px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
}

.filterIconMobile > svg > g line, 
.filterIconMobile > svg > g circle {
    stroke: var(--color-white);
}

.activeFilters {
    width: .75rem;
    height: .75rem; 
    background-color: var(--color-call-action);
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50%;
}

@media (min-width: 1024px) {
    .filterIconMobile {
        display: none;
    }
}