.newsItem {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: fit-content;
}

.newsItem:hover .newsItemTitle {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.newsItem:last-child {
    margin-bottom: unset;
}

.newsItemCategory {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300 !important;
    font-size: 12px !important;
    margin-top: unset;
    line-height: unset;
}

.newsItemTitle {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.noResultsMessage {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.highlight {
    background-color: black;
    color: white;
    padding: unset !important;
}

.button {
    margin-bottom: unset;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}