.intro {
    margin-top: 58px !important;
    padding-top: 40px !important;
    padding-bottom: 60px !important;
    max-width: none !important;
    position: relative;
    background-color: #F1F1F1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
}

.intro > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro h1{
    margin: 0px !important;
    /* margin-bottom: 20px !important; */
}

.intro > div:last-child {
    display: flex;
    flex-direction: column;
}

.introHeader {
    margin-bottom: 40px !important;
}

.intro > div > span {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
}

.iconContainer {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.iconContainer > svg:first-child {
    height: 100%;
    width: 16%;
    display: block;
    margin-left: -290px;
}

.iconContainer > svg:last-child {
    display: none;
}

.intro {
    margin-bottom: 60px !important;
}

@media (min-width: 768px) {
    .intro > div > h1 {
        margin-block-start: unset !important;
    }

    .iconContainer > svg:first-child {
        margin-left: -210px;
    }
}

@media screen and (min-width: 481px) {
    .introHeader {
        margin-bottom: 60px !important;
    }
}

@media (min-width: 769px) {
    .intro {
        padding-top: 145px !important;
        margin-top: 0px !important;
    }
}

@media (min-width: 992px){
    .intro > div > span:last-child {
        margin-bottom: 40px;
    }
}

@media (min-width: 1200px) {
    .iconContainer > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .iconContainer > svg:first-child {
        margin-left: -150px !important;
    }
}

@media (min-width: 2340px) {
    .intro {
        display: block;
    }
}