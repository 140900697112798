.searchBar :global(.ant-input-prefix) {
    margin-right: 16px !important;
}

.searchBar :global(.ant-input-affix-wrapper) {
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-radius: 0;
}

.searchBar :global(.ant-input-affix-wrapper):focus,
.searchBar :global(.ant-input-affix-wrapper):hover {
    box-shadow: none !important;
    border: none !important;
}

.searchBar input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 22px;
    color: black;
    background-color: transparent !important;
}

.searchBar input::placeholder {
    color: var(--color-light-gray-2);
}

.searchBar input::-ms-input-placeholder {
    color: var(--color-light-gray-2);
}

.searchbarDropdownChevron {
    margin-right: 4px;
}

.searchbarDropdown {
    top: 378px !important;
    z-index: 100000 !important;
}

.searchbarDropdown :global(.ant-dropdown-menu) {
    padding: 5px 0 !important; 
}

.searchbarDropdown :global(.ant-dropdown-menu-item) {
    color: var(--color-secondary);
    background-color: white !important;
    padding: 8px 12px 8px 20px !important; 
}

.searchbarDropdown :global(.ant-dropdown-menu-item) > span {
    color: var(--color-secondary);
}

.searchbarDropdown :global(.ant-dropdown-menu-item-selected) > span {
    color: black
}

.searchbarDropdown :global(.ant-dropdown-menu-item-active) > span {
    color: black
}

.searchbarDropdownTitle {
    line-height: 60px;
    font-family: "GT-America";
    text-align: center;
    font-size: 16px !important;
    color: var(--color-light-gray-2);
}

.dropdown .menu {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-medium-gray);
}

.close {
    align-self: center;
    width: 20px;
    height: 20px;
    justify-self: center;
    cursor: default;
    opacity: 0;
    pointer-events: none;
}

.close path {
    stroke: var(--color-light-gray-2);
}

.close:hover path {
    stroke: black;
}

.sectionHeader {
    color: black;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 25px;
    border-bottom: 1px solid #D5D5D5;
}

.displayCloseButton {
    cursor: pointer;
    opacity: 1;
    pointer-events: unset;
}

.resultsSectionContainer {
    padding-top: 60px;
    padding-bottom: 60px;
}

.resultsSectionContainer:first-child {
    padding-top: unset;
}

.highlight {
    background-color: black;
    color: white;
    padding: unset !important;
}

.sectionItemsContainer {
    margin-bottom: 25px;
}

.centerSpinner {
    display: flex;
    justify-content: center;
}

.button {
    margin-bottom: unset;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.shortQueryMessage {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.mobileSearchbarGrid {
    display: grid;
    grid-template-columns: auto 30px;
    align-items: center;
    height: 100%;
    border-bottom: var(--color-secondary) 1px solid;
    padding-bottom: 20px;
}

.searchbarDropdownRow {
    border-bottom: #D5D5D5 0.75px solid;
    justify-content: space-between !important;
    cursor: pointer;
}

.resultsContainer {
    max-width: none !important;
    padding: 0 !important;
}

.resultsContainer > div > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
} 

.resultsContainer > div > div {
    padding: 0 20px;
}


.searchButton {
    font-family: "GT-America";
    font-size: 14px !important;
    font-weight: 400;
    color: black;
    text-align: end;
    cursor: default;
    opacity: 0;
    margin-right: 25px;
}

.searchButton:hover {
    text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.displaySearchButton {
    cursor: pointer;
    opacity: 1 !important;
}


@media screen and (min-width: 692px) {

    .dropdown .menu {
        border-left: 1px solid var(--color-medium-gray);
        border-right: 1px solid var(--color-medium-gray);
        border-bottom: 1px solid var(--color-medium-gray);
    }
}

@media screen and (min-width: 768px) {

    .searchbarDropdown {
       top: 304px !important;
    }

    .searchbarDropdownTitle {
        line-height: 34px;
    }

    .searchbarDropdownRow {
        margin-bottom: unset;
        border-right: var(--color-light-gray-2) 0.75px solid;
        border-bottom: unset;
        padding: 0 25px 0 15px;
    } 

    .searchBar :global(.ant-input-affix-wrapper) {
        padding: 0 0 0 25px !important;
    }

    .searchbarGrid {
        display: grid;
        grid-template-columns: 190px auto min-content 30px;
        align-items: center;
        height: 100%;
        border-bottom: black 2px solid;
        padding-bottom: 20px;
    }

    .searchbarGridMaxWidth {
        grid-template-columns: 220px auto min-content 30px;
    }

    .close {
        justify-self: unset;
    }

    .resultsContainer > div > div {
        padding: 0 90px;
    }

}

@media screen and (min-width: 481px) {
    .resultsContainer > div > div {
        padding: 0 50px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .resultsContainer > div > div {
        padding: 0px 90px;
        max-width: none;
    }
}

@media screen and (min-width: 1350px) {
    .resultsContainer > div > div {
        padding: 0px;
        max-width: var(--main-container-max-width);
    }
}