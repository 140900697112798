.viewMonth table {
    border: none !important;
    text-decoration: none;
}

.viewMonth thead td {
    border: none !important;
}

.viewMonth thead th {
    border-top: none !important;
    border-bottom: 1px solid var(--color-black) !important;
}

.viewMonth thead th:first-child {
    border-left: none;
}

.viewMonth thead th:last-child {
    border-right: none;
}

.viewMonth .fc-scrollgrid-section > td {
    border-right: none !important;
}

.viewMonth .fc-scrollgrid-sync-table tr td:first-child{
    border-left: none;
}

.viewMonth .fc-scrollgrid-sync-table tr td:last-child{
    border-right: none;
}


.fc-header-toolbar {
    justify-content: flex-start !important; 
}

.fc-prev-button,
.fc-next-button {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
    border: none !important;
    padding: 5px 0 !important;
    transform: scale(1.4);
}

.fc-prev-button:focus,
.fc-next-button:focus {
    box-shadow: none !important;
}


.fc-toolbar-chunk:first-child {
    margin-right: 20px;
}

.fc-toolbar-chunk:last-child {
    margin: auto;
    margin-right: 0;
}

.fc-toolbar-title {
    font-family: 'GT-America' !important;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 15px !important;
}

 
.viewMonth .fc-day {
    height: 40px;
}

.viewMonth .fc-col-header-cell > .fc-scrollgrid-sync-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewMonth .fc-col-header-cell > .fc-scrollgrid-sync-inner > .fc-col-header-cell-cushion {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
}


.viewMonth .fc-daygrid-event-harness {
    border-radius: 50%;
    position: absolute !important;
    width: 10px;
    margin-left: 4px;
}

.viewMonth .fc-daygrid-event-harness-abs {
    width: auto;
    margin-left: 0;
}


.viewMonth .fc-daygrid-event-harness * {
    height: 12px !important;
    width: 12px !important;
    border-radius: 50% !important;
    border: none !important;
}

.viewMonth .fc-daygrid-event-harness-abs {
    height: 0 !important;
    width: auto !important;
    border-radius: 4px !important;
}
.display-none {
    display: none !important;
}

.hidden {
    opacity: 0;
}

.fc-daygrid-dot-event {
    background-color: rgba(0, 0, 0, 0) !important;
}

.viewMonth .fc-daygrid-day-events {
    display: flex;
    align-items: flex-end;
    height: 50px !important;
    min-height: 50px !important;
}

.fc-h-event {
    background-color: rgba(0, 0, 0, 0);
}

.viewMonth .internalEvent {
    background-color: var(--color-success);
    border: solid 1px white !important;
}

.viewMonth .externalEvent {
    background-color: var(--color-warning);
    border: solid 1px white !important;
}

.viewMonth .training {
    background-color: #AEA2CD;
    border: solid 1px white !important;
}

.borderInternalEvent {
     border: var(--color-success) 2px solid !important;
}

.borderExternalEvent {
    border: var(--color-warning) 2px solid !important;
}

.borderTraining {
     border: #AEA2CD 2px solid !important;
}

.opacity-half {
    opacity: .5;
}

.single-day-opacity {
    opacity: .5;
}

.viewMonth .fc-daygrid-day-top {
    height: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
} 

.range-0 {
    top: 6px !important;
}

.range-1 {
    top: calc(2 * 6px) !important;
}

.range-2 {
    top: calc(3 * 6px) !important;
}

.range-3 {
    top: calc(4 * 6px) !important;
}

.range-4 {
    top: calc(5 * 6px) !important;
}

.range-5 {
    top: calc(6 * 6px) !important;
}

.viewMonth .fc-daygrid-day-number {
    font-size: 12px;
    padding: 0 !important;
    text-decoration: none;
}

.viewMonth .fc-day-today {
    background-color: rgba(0, 0, 0, 0) !important;
}

.fc-day-today a {
    background-color: rgba(0, 0, 0, 0) !important;
}

.viewMonth .fc-day-today .fc-daygrid-day-number {
    background-color: rgb(0, 0, 0) !important;
    height: 20px;
    width: 20px;
    color: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 500;
}

.loading {
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-disabled);
    opacity: .3;
    top: 0;
}



@media (min-width: 768px) {
    .fc--button {
        display: none !important;
    }

    .fc-icon {
        font-size: 1rem !important;
    }

    .fc-toolbar-chunk {
        display: flex;
    }
}