.title {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    border-top: 3px solid black;
    padding-top: 30px;
    margin-top: -32px;
    font-size: 36px !important;
    line-height: 44px !important;
}

.overline{
    border-top: thin solid var(--color-medium-gray);
    max-width: 100% !important;
}

.wrapper{
    margin-top: 30px !important;
}