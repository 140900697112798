.box > div {
    padding-left: 2px;
}

.box > div > span {
    overflow: hidden;
}

@media (min-width: 992px) {
    .box > div {
        padding-left: 4px;
    }
}