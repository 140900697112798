.userItem {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: fit-content;
}

.userItem:hover .userItemTitle {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.userItem:hover .userItemDepartment {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.userItem:last-child {
    margin-bottom: unset;
}

.userItemTitle {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.userItemDepartment {
    font-family: "GT-America";
    color: var(--color-secondary);
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
}

.noResultsMessage {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.highlight {
    background-color: black;
    color: white;
    padding: unset !important;
}

.button {
    margin-bottom: unset;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}