.title {
    font-weight: 400 !important;
}


.loading {
    pointer-events: none;
    opacity: 0.6;
}

.titleUnselected {
    color: var(--color-light-gray-2) !important;
}

.subTitle {
    text-align: center;
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-skip-ink: none;
    text-underline-offset: 13px;
    font-weight: normal !important;
}

.description > span{
    color: var(--color-black) !important;
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    text-align: start;
}

.description2 {
    margin-top: 20px;
}

.description2 > span{
    font-family: "GT-America";
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
}

.descriptionsContainer{
    margin-top: 70px !important;
}

.departmentContainer{
    border-bottom: solid 1px var(--color-black);
    padding: 12px 0;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.departmentContainer * {
    z-index: 1;
}
.departmentContainer::before {
    content: "";
    width: 0px;
    height: 100%;
    background-color: var(--color-black);
    position: absolute;
    left: 0;
    top: 0;
    transition: width .2s ease-in-out;
    z-index: 0;
}

.departmentContainer:hover::before {
    width: 100%;
}

.selectedDepartment{
    background-color: var(--color-black);
}
.selectedDepartment h1{
    color: var(--color-white) !important;
}

.departmentContainer:hover h1 {
    color: var(--color-white) !important;
}

.departmentContainer > svg {
    display: none;
    margin-right: 15px;
}

.departmentContainer:hover svg {
    display: block !important;
}

.departmentContainer > svg > path {
    fill: white;
}


.departmentContainer > h1 {
    margin-bottom: 0;
    margin-left: 10px;
}

.department {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
    width: 90%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis
}

.departmentRow {
    margin-bottom: 40px !important;
    margin-top: 80px !important;
}

.departmentRow > div:not(:nth-child(3n + 1)) {
    margin-left: calc(300%/24/2);
}

.buttonContainer{
    justify-content: right;
}

.seeMoreButton {
    background-color: rgba(0,0,0,0) !important;
}

.seeMoreButton:hover {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}

.subTitleGreyed{
    text-align: center;
    color: var(--color-tertiary) !important;
    font-weight: normal !important;
}

.container>div {
    margin: .5rem 0;
}

.container p {
    margin: 0;
    font-family: "DomaineDisplay";
    padding: 0.5rem 0 0.25rem 0;
}

.dropdownRow {
    border-bottom: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 50px;
}

.dropdownRow > div >h1 {
    font-size: 26px !important;
    line-height: 34px;
    font-weight: 600 !important;
}

.dropdownRow>div:first-child {
    max-width: 90%;
    flex: 1;
}

.dropdownRow h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dropdownRowDisabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
}

.dropdownRow2 h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.5em;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important;
}
.dropdownRow2 {
    border-bottom: solid 1px var(--color-black);
    border-top: solid 1px var(--color-black);
    cursor: pointer;
    margin-top: 50px;
}

.dropdownRow2>div:first-child {
    max-width: 90%;
    flex: 1;
}

.overlay {
    width: auto !important;
}

.overlay>div {
    background-color: var(--color-white);
    margin-top: -4px;
    display: flex;
    flex-direction: column;
    border: none !important;
}

.overlay>div>label>span:first-child {
    display: none;
}

.overlay label {
    border: none !important;
    height: 42px;
    display: flex;
    align-items: center;
    box-shadow: none;
    width: 100%;
}

.overlay label span {
    border: none !important;
}

.overlay label>span {
    width: 100%;
}

@media (hover: hover) and (pointer: fine) {
    .overlay label:hover {
        background-color: var(--color-selection);
    }
}

.menuItem p, .department {
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 22px !important; 
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

@media (min-width: 480px){
    .menuItem p, .department {
       font-size: 15px !important;
    }
    
}

@media (min-device-width: 768px) {
    .dropdownRow h1 {
        font-size: 18px !important;
    }

    .description > span{
        color: var(--color-black) !important;
    }
    
    .description2 > span{
        font-family: "GT-America";
        font-size: 18px;
        line-height: 26px;
        font-weight: 300;
    }

    .description2 {
        margin-top: unset;
    }

    .descriptionsContainer {
        margin-top: 75px
    }
}

@media screen and (min-device-width: 1024px) {
    .dropdownRow h1 {
        font-size: 24px !important;
    }
}


