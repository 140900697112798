.filtersDropdown > :global(.ant-dropdown-menu) {
    right: -20px !important;
}

.menuFilterContainer, .menuOrderContainer {
    padding: 20px !important;
    top: 15px;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item):first-child, .menuOrderContainer > :global(.ant-dropdown-menu-item) {
    margin-bottom: 20px;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item), .menuOrderContainer > :global(.ant-dropdown-menu-item) {
    padding: 0;
}
 
.menuOrderContainer > :global(.ant-dropdown-menu-item):last-child {
    margin-bottom: 0;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item) > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}


div > .selectGroup {
    display: inline-flex;
    flex-direction: column;
}

.selectGroup > label {
    margin-bottom: 20px;
    margin-right: 0;
}

.selectGroup > label > span, .radioGroup > div > div > label > span {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 14px;
    color: var(--color-light-gray-2);
}

.radioGroup:hover {
    background: unset;
    opacity: unset;
}

.filterContainer {
    display: flex;
    align-items: center;
}

.filterContainer > div > .divider {
    border-left: 0.01rem solid var(--color-black);
    margin-left: 0px;
    margin-right: 20px;
    height: 1.5rem;
}

.menuOrderContainer :global(.ant-space-item) {
    width: 100%;
}

.pointer {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.chevron {
    margin-left: 15px;
    width: 14px;
}

.control {
    display: flex;
    margin-right: 20px;
}

.control > span {
    display: flex;
}

.clearButton {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 14px;
}

.menuFilterContainer > :global(.ant-dropdown-menu-item):hover, .menuOrderContainer > :global(.ant-dropdown-menu-item):hover {
    background-color: unset
}

.dropdown {
    padding-right: 20px;
}

.slider :global(.ant-slider-step),
.slider :global(.ant-slider-rail),
.slider :global(.ant-slider-track) {
    height: 2px;
}

.slider :global(.ant-slider-dot) {
    display: none;
}

.slider :global(.ant-slider-handle) {
    background-color: var(--color-black) !important;
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.slider :global(.ant-slider-mark-text) {
    margin-top: 5px;
}

.tooltip {
    background-color: rgba(0, 0, 0, 0) !important;
    color: var(--color-black) !important;
    box-shadow: none !important;
    padding: 0 !important;
}