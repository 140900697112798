.agendaContainer {
    margin-top: 60px;
}

.subtitle > * {
    display: flex;
    align-items: center;
}

.subtitle h1 {
    margin: 0 !important;
}

.subtitle:hover h1{
    text-decoration: underline solid var(--color-black) from-font;
    text-underline-offset: 4px;
}

.chevron {
    transform: rotate(180deg);
    margin-left: 30px;
    margin-top: 6px;
}

.categoryContainer {
    display: flex !important;
    align-items: center;
}

.nextEvent, .noEventsContainer {
    display: block !important;
    cursor: pointer;
    margin-top: 60px;
    padding-right: 10px;
    text-align: justify;

}

.nextEventTitle{
    line-height: 32px !important;
}

.nextEvent:hover .nextEventTitle{
    text-decoration: underline solid var(--color-black);
    text-underline-offset: 4px;
}

.nextEvent:hover .bookButton{
    border: 1px solid var(--color-white) !important;
    filter: invert(1);
}

.nextEvent h1 {
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.noEventsContainer {
    cursor: auto;
}

.noEventsContainer > span {
    color: var(--color-tertiary) !important;
}

.categoryContainer > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.categoryContainer > span {
    margin: 0 0 0 10px;
    text-transform: uppercase;
}

.titleContainer > h1 {
    margin: 5px 0 0 0;
}

.dateContainer {
    margin-top: 20px;
}

.training {
    color: #A298C3 !important;
}

.internalEvent {
    color: var(--color-success) !important;
}

.externalEvent {
    color: var(--color-warning) !important;
}

.bookButton {
    width: 100%;
    margin-top: 20px;
    height: 44px !important;
}

.eventRow {
    margin-top: 40px !important;
}

.dateList {
    text-transform: uppercase;
}

.dateList > span {
    font-size: 13px !important;
    color: var(--color-secondary) !important;
}

.seeMore {
    margin-top: 40px !important;
}

.button {
    height: 32px;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.highlightEventDate {
    display: block;
    width: 100%;
}
.subscriptionRow {
    width: 100%;
    margin-top: 20px;
    height: 44px !important;
}

.subscription {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.subscription span {
    font-weight: 700;
}

.tickIcon {
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid var(--color-black) 1px;
    margin-right: 10px;
}

.mainTitle{
    font-weight: 500 !important;
}

.nextEventTitle{
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    margin-bottom: 10px !important;
}

@media screen and (min-width: 576px) {
    .seeMore {
        display: flex !important;
        justify-content: flex-end;
    }

    .bookButton {
        max-width: 106px;
    }
}

@media screen and (min-width: 992px) {
    .agendaContainer {
        margin-top: 0px;
    }

    .bookButton {
        max-width: unset;
    }

    .eventRow:first-child{
        margin-top: 0 !important;
    }

    .eventRow:not(:first-child) {
        margin-top: 20px !important;
    }

    .eventRow > div:nth-child(2) {
        margin-top: 10px;
    }

    .divider {
        margin: 30px 0 25px 0;
        border-color: rgba(0, 0, 0, .3);
    }
    
}