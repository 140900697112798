.spin {
    animation: icon-spin 1.5s infinite linear;
    stroke: var(--color-green);
    fill: black;
}

.fullHeight{
    align-self: center;
    height: 1.5rem !important;
    width: 1.5rem !important;
    flex: 1;
}

.large {
    font-size: 22px !important;
}
 
 
 @-webkit-keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
 }
 
 @keyframes icon-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
 }
