.fc-scrollgrid {
    border: none !important;
}

.fc-header-toolbar {
    justify-content: flex-start !important; 
}

.fc-prev-button,
.fc-next-button {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
    border: none !important;
    padding: 5px 0 !important;
    transform: scale(1.4);
}

.fc-prev-button:focus,
.fc-next-button:focus {
    box-shadow: none !important;
}

.fc-toolbar-chunk:first-child {
    margin-right: 20px;
}

.fc-toolbar-chunk:last-child {
    margin: auto;
    margin-right: 0;
}

.fc-toolbar-title {
    font-family: 'GT-America' !important;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 15px !important;
}

.internalEvent {
    background-color: var(--color-success);
}

.externalEvent {
    background-color: var(--color-warning);
}

.training {
    background-color: #AEA2CD;
}

.fc-v-event {
    background-color: rgba(0,0,0,0);
}

.fc-event-main {
    padding: 0 !important;
    border-radius: 4px !important;
    position: relative;
}

.fc-timegrid-event {
    border: none;
    border-radius: 4px !important;
}

.fc-col-header-cell-cushion {
    font-weight: 400;
    width: 24px;
    height: 24px;
    text-decoration: none;
}

.viewWeek .fc-day-today .fc-scrollgrid-sync-inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fc-day-today a {
    font-weight: 500;

    color: var(--color-white);
    background-color: var(--color-black);
    border-radius: 50%;
}

.fc-scrollgrid-section-header td {
    border: none;
}  

.fc-scrollgrid-section-body > td {
    border-right: none;
}  

.fc-timegrid-slot-label-cushion {
    font-size: 10px;
}

.fc-timegrid-slot-minor {
    border-top: none !important;
}

.fc-timegrid-now-indicator-arrow {
    display: none;
}

.fc-timegrid-now-indicator-line {
    border-color: var(--color-danger) !important;
}

.ant-tooltip-content {
    display: flex;
    align-items: center;
}

.identifier {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 8px;
}

.fc-timegrid-event-harness a {
    margin-left: 0 !important;
}

.fc-timegrid-event-harness a * {
    font-weight: 400;
    font-size: 9px;
}


.fc-timegrid-event-harness a * {
    font-weight: 400;
    font-size: 9px;
}

.lockScroll .fc-scroller-liquid-absolute {
    overflow: hidden !important;
}

.loading {
    z-index: 1000;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-disabled);
    opacity: .3;
    top: 0;
}

.fc-daygrid-event-harness-abs > a ,
.fc-daygrid-event-harness > a {
    border: none;
}

.fc-timegrid-axis-cushion {
    text-transform: capitalize;
    font-size: 12px;
}

/* .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 0;
} */

.fc-event-end {
    margin-left: 2px;
}

@media (min-width: 768px) {
    .fc-timegrid-event-harness a * {
        font-size: 10px;
    }
}