.buttonCancel and .buttonAccept {
    font-weight: 600;
    width: 80px;
    height: 50px;
}

.buttonAccept {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    height: 50px;
    margin-right: 0 !important;
}

.instructionsTitle {
    font-family: 'GT-America';
    font-size: 14px;
    font-weight: 400 !important;
}

.instructions, .instructions * {
    font-family: "GT-America";
    font-weight: 300 !important;
    font-size: 14px !important;
    color: var(--color-secondary) !important;
    text-align: left;
}

.buttonContainer {
    height: 50px;
    z-index: 1;
}

.divider {
    border-top: 0.5px solid #000000 !important;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width: 420px) {
    .buttonAccept and .buttonCancel {
        background-color: var(--color-black) !important;
        position: fixed;
        bottom: 0;
        z-index: 1000;
        height: 50px;
    }
    .buttonAccept {
        right: 0;
    }
    .buttonCancel {
        left: 0;
    }
    .buttonContainer {
        width: 100%;
        height: 0px;
    }
    .instructions {
        margin-bottom: 80px;
    }
    .divider {
        margin-right: 24px;
        margin-top: unset;
    }
}