.container {
    margin-top: 60px !important;
}

.sectionTitle h1 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 0;
}

.divider {
    margin: 15px 0 0 0;
    border-top: solid var(--color-black) 1px;
}

.arrowNext {
    transform: rotate(180deg);
}

.carousel :global(.slick-disabled) svg path {
    stroke: var(--color-light-gray-2);
}

.containerLoading {
    pointer-events: none;
    opacity: 0.6;
    background-color: rgba(0, 0, 0, .05);
}

@media (min-width: 576px) {
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .cardContainer {
        width: 48% !important;
        max-width: 48% !important;
    }
}

@media (min-width: 768px) {
    .divider {
        width: 100vw;
        margin-left: -50px;
    }

    .cardContainer {
        width: 100% !important;
        max-width: none !important;
    }

    .cardContainer:nth-child(2n) {
        margin-left: 15px;
    }

    .carousel :global(.slick-prev) {
        left: -35px;
    }
    
    .carousel :global(.slick-next) {
        right: -35px;
    }

    .container {
        margin-top: 120px !important;
    }

    .containerLoading > div:last-child {
        pointer-events: none;
        opacity: 0.6;
        background-color: var(--color-disabled);
    }

    .listContainer {
        display: flex;
        width: 100%;
    }

    .listContainer > div:not(:first-child) {
        margin-left: calc((300% / 24) / 2) !important;
    }
}

@media screen and (min-width: 769px) {
    .divider {
        margin-left: -90px;
    }

    .carousel :global(.slick-prev) {
        left: -50px;
    }
    
    .carousel :global(.slick-next) {
        right:  calc(-50px + 1% );
    }
}

@media screen and (min-width: 992px) {
    .cardContainer {
        width: calc(100% - 15px) !important;
        max-width: none !important;
    }

    .cardContainer:nth-child(2n) {
        margin-left: 0;
    }

    .cardContainer:not(:nth-child(3n + 1)) {
        margin-left: 15px !important;
    }
}

@media (min-width: 1200px) {
    .listCardContainer {
        width: 24%;
        flex: unset;
    }

    .listContainer > div:not(:first-child) {
        margin-left: 0 !important;
        margin-left: calc(4% / 3) !important;
    }
}

@media screen and (min-width: 1350px) {
    .divider {
        margin-left: calc((100vw - var(--main-container-max-width)) / -2);
    }
}