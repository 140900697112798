.container > div {
    margin-bottom: 40px;
}

.container {
    margin-bottom: 40px;
}

.description {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
    width: 100%;
}

.background {
    background-image: url("../../../../assets/images/jpeg/mainPages/plus.jpg");
    position: relative;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.loading{
    background: none !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .description {
       font-size: 15px;
       font-weight: 400 !important;
       line-height: 20px
    }
 }

@media (min-width: 768px) {
    .description {
       font-size: 18px;
       font-weight: 400 !important;
       line-height: 25px;
    }
 }

@media (min-width: 1024px) {
    .description {
        display: block;
        width: 80%;
    }

    .background {
        background-image: unset !important;
    }

    .container > div {
        margin-bottom: 100px;
    }
}
