.container {
    min-height: calc(100vh - 192px - 50px);
}

.container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.container > div > div {
    margin-top: 0;
    margin-bottom: 0;
}

.notFoundRowTitle {
    margin-top: 58px !important;
}

.notFoundRowTitle > div {
    margin-top: 90px;
}

.notFoundRowTitle h1 {
    text-align: center;
    font-size: 40px !important;
    line-height: 46px !important;
    margin-bottom: 30px !important;
}

.notFoundRowMessage > div > span {
    display: block;
    text-align: center !important;
    color: var(--color-gray) !important;
    font-size: 15px !important;
    line-height: 22px !important;
    margin-bottom: 20px;
}

.homeLink {
    margin-top: 30px !important;
}

.homeLink > div {
    display: flex;
    justify-content: center;
}

.homeLink > div > a {
    padding: 5px 18px;
    text-decoration: none;
    border: 2px solid var(--color-black);
    position: relative;
}

.homeLink span {
    font-size: 12px !important;
    font-weight: 500;
    position: relative;
    z-index: 1;
}

.homeLink > div > a:hover {
    background-color: var(--color-black);
}

.homeLink > div > a:hover > span {
    color: var(--color-white) !important;
}

@media (min-width: 574px) {
    .container {
        min-height: calc(100vh - 170px - 50px);
    }
}

@media (min-width: 768px) {
    .container {
        min-height: calc(100vh - 158px - 50px);
    }

    .notFoundRowTitle h1 {
        font-size: 45px !important;
    }
}

@media (min-width: 890px) {
    .container {
        min-height: calc(100vh - 136px - 50px);
    }
}