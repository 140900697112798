.container {
    margin-bottom: 80px !important;
}

.container > div > div > .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.item:hover a {
    opacity: .7;
}

.item > a > h1 {
    margin: 5px 0 10px 0 !important;
}

.itemBody, .itemBody > * {
    margin-bottom: 0px !important;
    color: var(--color-secondary) !important;
    font-size: 14px;
    line-height: 20px;
}

.itemBody {
    margin-bottom: 10px !important;
}

.faded {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
}

.button {
    height: 32px;
    margin-top: 40px;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}

.overline{
    text-transform: uppercase;
}

.chevron {
    transform: rotate(180deg);
    margin-left: 30px;
    margin-top: 6px;
}

.title{
    font-size: 18px !important;
    line-height: 26px !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-weight: 500 !important;
}

.subtitle > * {
    display: flex;
    align-items: center;
}

.subtitle h1 {
    margin: 0 !important;
}

.subtitle:hover h1{
    text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.mainTitle{
    font-weight: 500 !important;
}


@media (min-width: 992px) {
    .buttonRow {
        display: flex;
        justify-content: flex-start;
    }
}