.drawer {
    display: flex;
    justify-content: center;
}

.drawer > div:nth-child(2) {
    max-width: 500px;
}

.drawer :global(.ant-drawer-close) {
    padding: 14px;
}

.drawer :global(.ant-drawer-content-wrapper) {
    max-height: 700px;
}

.headerTitle {
    display: flex;
    align-items: center;
}

.headerTitle > svg {
    margin-right: 10px;
}

.titleRadio {
    display: block;
}

.radioGroup > div {
    margin-top: .75rem;
}

.titleCheckbox {
    display: block;
}

.checkboxGroup > label {
    margin: 9px 0;
}

:global(.ant-tooltip) {
    padding: 0 !important;
}

:global(.ant-tooltip-inner)  {
    background-color: rgba(0, 0, 0, 0) !important;
    padding: 0 !important;
    box-shadow: none !important;
    color: var(--color-black) !important;
}

:global(.ant-tooltip-arrow) {
    display: none !important;
}

.slider :global(.ant-slider-step),
.slider :global(.ant-slider-rail),
.slider :global(.ant-slider-track) {
    height: 2px;
}

.slider :global(.ant-slider-dot) {
    display: none;
}

.slider :global(.ant-slider-handle) {
    background-color: var(--color-black);
    width: 18px;
    height: 18px;
    margin-top: -7px;
}

.slider :global(.ant-slider-mark-text) {
    margin-top: 5px;
}

.buttonsContainer {
    position: absolute;
    width: 100%;
    height: 50px;
    left: 0;
    bottom: 0;
}

.buttonsContainer > div {
    width: 100%;
    display: flex;
}

.buttonsContainer button {
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    height: 100%;
}

.tertiary > span {
    color: var(--color-tertiary) !important;
}

@media (min-device-width: 500px) {
    .drawer {
        display: flex;
        align-items: center;
    }

    .drawer :global(.ant-drawer-content-wrapper) {
        position: static !important;
    }
}

@media (min-width: 1024px) {
    .drawer {
        display: none;
    }
}