.userListContainer {
    margin-top: 32px !important;
}

.card {
    background: unset;
    border: unset;
}

.card > div {
    padding : unset
}

.name {
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 10px !important;
}

.name:hover {
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
}

.titleContainer {
    border-bottom: 1px solid var(--color-black);
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.titleContainer > {
    margin-top: 60px;
}

.titleContainer span {
    text-transform: uppercase;
}

.usersContainer > div:not(:first-child) {
    margin-top: 40px;
}

.cardContainer{
    margin-top: 15px !important;
    margin-bottom: 40px !important;
}

.infoContainer {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
}

.infoContainer > div:first-child {
    max-height: 80px;
    max-width: 70px;
}

.infoContainer > div:nth-child(2) {
    width: 100%;
}

.label {
    text-transform: uppercase;
}

.info {
    width: 100% !important;
    margin-top: 0px;
}

.chevron {
    transform: rotate(-90deg);
}

.teamsContainer {
    background-color: var(--color-call-action);
    padding: 9px 15px;
    margin-top: 15px;
    border-bottom: 2px solid var(--color-black);
    z-index: 1;
}

.microsoftLink {
    text-transform: capitalize;
}

.chatIcon {
    display: flex;
    justify-content: flex-end;
}

.chatIcon > a {
    display: flex;
    align-items: center;
}

.emailContainer {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.emailContainer a:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.thumbnail {
    display: none;
    height: 80px;
    width: 70px;
    margin-right: 20px;
    background-position: center;
    background-size: cover;
}

.imgPlaceholder {
    background-color: var(--color-disabled);
    opacity: .5;
}

.seeMoreButton{
    right: 0;
    position: absolute;
}

@media (min-device-width: 576px) {
    .userListContainer {
        margin-top: 80px !important;
    }

    .usersContainer > div:not(:first-child) {
        margin-top: 0;
    }

    .usersContainer > div {
        margin-top: 20px !important;
    }
}

@media (min-device-width: 796px) {
    .infoContainer > div:first-child {
        margin-right: 20px;
    }

    .infoContainer > div:nth-child(2) {
        width: calc(100% - 90px);
    }

    .thumbnail {
        display: block;
    }
}

@media (min-device-width: 992px) {
    .usersContainer > div:not(:nth-child(3n + 1)) {
        margin-left: calc(300% / 24 / 2)
    }
    .infoContainer {
        padding-bottom: 15px;
    }

    .teamsContainer {
        position: absolute;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        overflow: hidden;
        width: 100%;
        margin-top: 0;
        transition: all 0.2s ease-out;
    }

    .teamsContainer > div {
        margin: 9px 0;
    }

    .teamsContainer > div:nth-child(2) a {
        margin-right: 15px;
    }

    .teamsContainer > div:first-child a {
        margin-left: 15px;
    }

    .cardContainer:hover .teamsContainer,
    .teamsContainer:hover {
        max-height: 50px;
        border-bottom: 2px solid var(--color-black);
        transition: all .2s ease-in;
    }

    .chevron {
        display: none;
    }

    .card:hover .chevron {
        display: inline;
        cursor: pointer;
    }
}