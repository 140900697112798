.profile {
    height: 32px;
    width: 32px;
    background-color: #A0C6D2;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 32px;
}

.profile span {
    font-weight: 600;
}

.profilePicture img {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.profilePicture {
    line-height: 22px;

}
:global(.lightTextHeader) .profilePicture {
    filter: invert(1);
}