.background{
    background-image: url("../../../../assets/images/jpeg/mainPages/whoIsWhoTeams.jpg");
    background-size: 240%;
}

.cutoutLeft{
    width: 1000px !important;
    position: absolute !important;
    left: -500px !important;
}

.loading{
    background: none !important;
}