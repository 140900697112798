.main {
    min-height: calc(100vh - 631px);
    margin-bottom: 50px;
    background: var(--color-white);
    overflow-x: hidden;
}

.noMargin {
    margin: 0;
}

@media screen and (min-width: 769px) { 
    .main {
        min-height: calc(100vh - 194px);
        background: var(--color-white);
    }
}