:root {
    --main-container-max-width: 1170px;
    --header-height: 58px;
    --internal-padding: 0.2rem;
    --regular-padding: 0.5rem;
    --font-size-title-page: 40px;
    --font-size-subtitle: 33px;
    --font-size-lead: 21px;
    --font-size-title: 21px;
    --font-size-body: 15px;
    --font-size-page-description: 15px;
    --font-size-content: 15px;
    --line-height-title: 48px;
    --line-height-subtitle: 40px;
    --line-height-lead: 29px;
    --line-height-content: 24px;
    --line-height-page-description: 21px;
    --line-height-body-and-title: 1.4;
}

@media (min-width: 992px) {
    :root {
        --font-size-page-description: 18px;
        --line-height-page-description: 26px;
    }
}