.cardCover {
    background-position: center 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #ECECEC;
    height: 200px;
}

 .cardBodyContainer {
    justify-content: space-between !important;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.bookButtonContainer {
    align-self: center !important;
}

.specialConditionsText {
    font-family: "DomaineDisplay";
    font-weight: 600 !important;
    color: var(--color-success) !important;
    line-height: 20px;
    font-size: 18px !important;
}

@media (min-width: 576px) {
    .cardCover {
        height: 170px !important;
    }

    .specialConditionsText {
        font-size: 16px !important;
    }
}

@media (min-width: 768px) {
    .cardCover {
        height: 150px !important;
    }
} 

@media (min-width: 1184px) {
    .cardCover {
        height: 200px !important;
    }
} 

.container {
    border: 1px solid var(--color-white) !important;
    cursor: pointer;
}

.container > div:nth-child(2) {
    border-bottom: 1px solid #ECECEC;
    border-left: 1px solid #ECECEC;
    border-right: 1px solid #ECECEC;
}

.title {
    font-family: "DomaineDisplay";
    font-weight: 600 !important;
    color: var(--color-success) !important;
    margin-bottom: unset !important;
    font-size: 32px !important;
    line-height: 40px;
}

.titleIcon {
    align-self: center;
    margin-right: 10px;
    margin-top: 4px;
}

.titleContainer {
    height: 36px;
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
}

.description {
    font-family: "DomaineDisplay";
    font-weight: 500 !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 20px !important;

}

.icon {
    margin-top: .5rem;
    stroke: var(--color-black);
    cursor: pointer;
}

.leftIcon {
    padding-left: 0 !important;
}

.icons > svg:last-child {
    margin-left: 12px;
}

@media (hover: hover) {

    .container:hover .cardCover {
        border-bottom: 1px solid black !important;
    }

    .container:hover {
        background-color: var(--color-black);
    }
    
    .container:hover .description {
        color: var(--color-white) !important;
    }

    .container:hover g {
        stroke: var(--color-white) !important;
    }

    .container:hover g path {
        stroke: var(--color-white) !important;
    }
    
    .container:hover button {
        color: var(--color-black) !important;
        background-color: var(--color-white) !important;
        border: var(--color-white) 1px solid !important;
        transition: none !important;
    }
    
    .container button:hover {
        background-color: var(--color-white) !important;
        color: var(--color-black) !important;
        transition: none !important;
    }

    .container:hover .icon {
        stroke: var(--color-white);
    }

    .container:hover > div:nth-child(2) {
        border-bottom: 1px solid var(--color-black);
        border-left: 1px solid var(--color-black);
        border-right: 1px solid var(--color-black);
    }
}