.linkTitle {
    font-family: 'GT-America';
    font-weight: 400;
    font-size: 14px;
    color: var(--color-black);
    text-decoration: underline;
}

@media (min-width: 1024px) {
    .linkTitle {
        font-size: 16px;
        line-height: 24px;
    }
}