.home-apps-section {
    background-color: var(--color-selection);
    padding-top: 80px;
    padding-bottom: 80px;
}

.container {
    overflow: hidden;
    margin-bottom: 30px;
}

.sections {
    padding-top: 120px;
}

.banner {
    position: absolute;
    padding: 0 !important;
    width: 100%;
    height: 100%;
    max-width: none !important;
}

.knowledgeSection {
    padding-bottom: 80px;
}

.bannerInner{
    height:100%;
    display: flex;
    justify-content:space-between;
    background-position:center;
    background-size:cover;
    opacity: 0.5;
    transition: transform 0.6s ease-out 0.2s;
}

.bannerInner > div {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 0 0 100%;
    max-width: 100%;
}

.bannerInner img {
    width: 15vw;
    height: auto;
    margin-bottom: -50px;
    margin-right: -5px;
}

.overlay{
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    transition: opacity 0.6s ease-out 0.2s;
    opacity: 0;
    pointer-events: none;
}

@media (min-width: 767px) {
    .banner>img {
        max-height: 1064px;
    }

    .container {
        margin-bottom: 60px;
    }
}

@media (min-width: 1440px) {
    .banner>img {
        width: 100%;
    }
}