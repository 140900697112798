@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap");
@import url("./colors.module.css");
@import url("./font.module.css");
@import url("./layout.module.css");

html,
body,
div#root {
    text-rendering: optimizeSpeed;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "GT-America";
    font-weight: 300;
    margin: 0;
    min-height: 100%;
}

h1 {
    font-family: "DomaineDisplay";
    font-size: 40px;
    line-height: 48px;
    font-weight: 500;
    margin-bottom: 25px;
}
h2 {
    font-family: "DomaineDisplay";
    font-size: 30px;
    line-height: 38px;

    font-weight: 500;
    margin-bottom: 25px;
}
h3 {
    font-family: "DomaineDisplay";
    font-size: 26px;
    line-height: 34px;
    font-weight: 500;
    margin-bottom: 25px;
}
h4 {
    font-family: "DomaineDisplay";
    font-size: 22px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 25px;
}
h5 {
    font-family: "DomaineDisplay";
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 25px;
}
h6 {
    font-family: "DomaineDisplay";
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 25px;
}
hr {
    margin: 15px auto 40px;
    border-top: 1px solid var(--color-medium-gray);
}
strong {
    font-weight: 500;
}

a {
    color: var(--color-secondary);
    text-decoration: underline 1px solid var(--color-secondary);
    text-underline-offset: 4px;
}

a:hover {
    color: var(--color-black);
    text-decoration: underline 1px solid var(--color-black);
}

.noDecoration {
    text-decoration: none;
}

.noDecoration:hover {
    text-decoration: none;
}

.noDecoration:hover > h1 {
    color: var(--color-black);
    text-decoration: underline 1px solid var(--color-black);
}

p {
    font-family: "GT-America";
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: var(--color-secondary);
    margin-bottom: 25px;
}

li {
    font-family: "GT-America";
    font-size: 18px;
    line-height: 26px;
    font-weight: 300;
    margin-bottom: 10px;
}

button {
    padding: 10px 24px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 25px;
}

.ql-align-right {
    text-align: right;
}

.ql-align-left {
    text-align: left;
}

.ql-align-center {
    text-align: center;
}

.ql-align-justify {
    text-align: justify;
}

.darkTextHeader {
    filter: invert(0);
}

.lightTextHeader {
    filter: invert(1);
}

#mobileCarouselArrowRight {
    right: 0 !important;
    top: -65px !important;
}

#mobileCarouselArrowLeft {
    left: unset !important;
    right: 75px !important;
    top: -65px !important;
}

#desktopCarouselArrowLeft {
    left: -50px !important;
}

#desktopCarouselArrowRight {
    right: -50px !important;
}

@media (min-width: 768px) {
    h1 {
        font-size: 54px;
        line-height: 64px;
    }

    h2 {
        font-size: 36px;
        line-height: 44px;
    }

    p {
        font-size: 18px;
        line-height: 26px;
    }
}

/* Workaround to change the default ant color, customizing webpack breaks the PWA */
/* More info here: https://ant.design/docs/react/customize-theme */

.ant-typography a {
    color: black !important
}

.ant-input-affix-wrapper-focused {
    border-color: black !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: black !important;
}

.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    border-color: #0d0c0c;
    box-shadow: 0 0 0 2px rgb(0 0 0 / 20%);
}

.ant-spin-dot-item {
    background-color: black !important
}

.ant-spin {
    color: black !important
}