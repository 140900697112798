.drawer {
    z-index: 10000;
}

.modal :global(.ant-btn) {
    background-color: var(--color-black);
    border: var(--color-black) 1px solid;
}

.modal :global(.ant-btn) span {
    font-size: 16px;
    font-weight: 500;
}

.modal :global(.ant-btn):hover {
    background-color: var(--color-white);
    border: var(--color-black) 1px solid;
    color: var(--color-black);
}

.drawer > div:last-child {
    height: calc(100% - 10px) !important;
}

.drawer :global(.ant-drawer-close){
    padding: 16px 20px;
}

.closeIcon {
    border-radius: 50%;
    margin-bottom: 0 !important;
}

.closeIcon path {
    stroke: var(--color-black);
}

.closeIcon:hover {
    background-color: var(--color-light-gray);
}

.drawer :global(.ant-drawer-header) {
    border-bottom: none;
    padding: 16px 20px;
}

.headerTitle {
    font-size: 14px !important;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--color-secondary) !important;
}

.drawer :global(.ant-drawer-body) {
    padding: 0 20px 20px 20px;
}

.drawer .image {
    object-fit: cover;
    width: calc(100vw - 40px);
    height: 150px;
    object-position: 50% 50%;
}

.row > div {
    position: relative;
    padding-bottom: 48px;
}

.category {
    display: flex;
    align-items: center;
    margin-top: 12px;
}

.category div:first-child {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.category span {
    text-transform: uppercase;
}

.drawer h1 {
    margin-top: 10px;
    margin-bottom: 0;
}


.subtitle {
    display: block;
    color: var(--color-secondary) !important;
    font-weight: 300;
    margin-bottom: 12px;
    margin-top: 20px;
    font-size: 15px !important;
}

.date {
    font-size: 12px !important;
}

.iconsContainer {
    margin-top: 30px;
    margin-bottom: 50px;
}

.iconsContainer > div {
    display: flex;
    align-items: center;
}

.iconsContainer > div:not(:first-child) {
    margin-top: 20px;
}

.iconsContainer span {
    color: var(--color-secondary) !important;
}

.icon {
    margin-right: 12px;
}

.icon path {
    stroke: var(--color-black);
}

.linksIcon {
    margin-top: 2px;
}

.mailIcon {
    stroke-width: 1px;
}

.iconsContainer > .organizersGroup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 32px;
}

.subscribe {
    width: 80%;
    height: 40px !important;
    left: 0 !important;
    bottom: 0;
    font-weight: 500;
    padding: 0;
    margin-bottom: 0;
    margin-top: 1em;
    font-size: 14px !important;
}

.subscriptionMobile {
    position: absolute !important;
    bottom: 0;
    padding-bottom: 0 !important;
    display: flex !important;
    align-items: center;
    height: 70px;
    width: 100%;
    left: 0px;
    justify-content: center;
    border-top: var(--color-medium-gray) 1px solid;
    background-color: white;
}

.subscriptionMobile > div {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    border: 1px solid var(--color-black);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subscriptionMobile span {
    font-weight: 700;
    font-size: 13px !important;
}

.bodyText p {
    margin-bottom: 0;
    font-size: 14px;
}

.bodyText strong {
    font-weight: 500;
}

.bodyText {
    background-color: rgba(0, 0, 0, 0);
    font-size: 14px;
    line-height: 22px;
}

.bodyText ul {
    padding-inline-start: 20px;
    margin-block-end: 0;
    margin-block-start: 0;
}

.body {
    margin-bottom: 40px;
}

.instructions {
    margin-bottom: 0;
}

.bodyTitle {
    display: block;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.linksContainer {
    display: flex;
    flex-direction: column;
}

.linkText {
    width: 220px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;   
}

@media (min-width: 576px) {
    .modal {
        width: 570px !important;
        background-color: var(--color-white);
        padding-bottom: 12px;
    }

    .modal :global(.ant-btn) span {
        font-size: 14px;
        font-weight: 400;
    }

    .modal :global(.ant-modal-content) {
        max-height: 80vh;
        overflow: auto;
        overflow-y: overlay;
        box-sizing: border-box;
        box-shadow: none;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .modal :global(.ant-modal-body),
    .modal :global(.ant-modal-header) {
        padding-left: 30px !important;
        padding-right: 30px !important;
        padding-bottom: 16px;
    }

    .modal :global(.ant-modal-header) {
        border-bottom: none;
        position: sticky;
        width: calc(100% - 56px);
        top: 0;
        left: 0;
        background-color: var(--color-white);
        z-index: 1;
        border-radius: 0 !important;
    }

    .modal :global(.ant-modal-body) {
        padding-top: 0;
        order: 3;
    }

    .modal :global(.ant-modal-footer) {
        order: 3;
        width: 100%;
        border: none;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 5px;
        padding-top: 16px;
    }

    .userAdded :global(.ant-modal-footer) {
        padding-bottom: 0px;
    }

    .modal :global(.ant-modal-close) {
        width: 30px;
        margin-right: 26px;
        position: sticky;
        top: 0;
        left: 100%;
        order: 2;
        background-color: var(--color-white);
        margin-bottom: 0;
    }

    .modal :global(.ant-modal-close-x) {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .modal :global(.ant-modal-footer) button:first-child {
        display: none;
    }

    .modal :global(.ant-modal-footer) button:last-child {
        height: 40px;
        border-radius: 0;
        font-size: 16px;
        font-weight: 500;
    }

    .modal .image {
        object-fit: cover;
        width: 510px;
        height: 250px;
        object-position: 50% 50%;
    }

    .modal .category {
        margin-top: 30px;
        margin-bottom: 10px;
    }

    .bodyText {
        font-size: 14px;
        line-height: var(--line-height-content);
    }

    .iconsContainer {
        margin-bottom: 12px;
    }

    .subscription {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
    }

    .subscription span {
        font-weight: 500;
    }

    .tickIcon {
        background-color: var(--color-white);
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: solid var(--color-black) 1px;
        margin-right: 10px;
    }

    .iconsContainer span {
        font-size: 15px !important;
    }

    .iconsContainer a:hover span {
        text-decoration: underline;
        text-underline-offset: 2px;
    }

    .bodyTitle {
        margin-bottom: 15px;
        font-size: 14px;
    }
    .loadingCloseIcon{
        pointer-events: none;
        cursor: not-allowed;
    }

    .linksGroup {
        align-items: flex-start !important;
    }

    .modal .subscribeButton {
        margin-top: 1em;
        background-color: var(--color-black);
    }

    .modal .subscribeButton:focus {
        background-color: var(--color-black);
        border-color: var(--color-black);
    }

    .modal .subscribeSuccess {
        background-color: var(--color-white);
        cursor: unset;
        color: var(--color-black);
        box-shadow: none;
        text-shadow: none;
    }

    .modal .subscribeSuccess:focus {
        background-color: var(--color-white);
        border-color: var(--color-black);
        color: var(--color-black);
        box-shadow: none;
        text-shadow: none;
    }      

    [ant-click-animating-without-extra-node='true']::after{
        display:none;
    }
}

@media (min-width: 576px) and (max-width: 600px) { 
    .modal :global(.ant-modal-body),
    .modal :global(.ant-modal-footer),
    .modal :global(.ant-modal-header) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .modal .image {
        width: 530px;
    }
}