.menuItem {
    padding-top: 15px !important;
    height: unset !important;
    margin: 0 !important;
    background-color: #f4f4f4 !important;
}

.menuItem > span {
    width: 100%;
}

.postType > div > span,
.eventCategory > span {
    font-size: 11px !important;
    text-transform: uppercase;
    color: var(--color-secondary) !important;
    display: block;
}

.postTitle {
    margin-top: 5px;
}

.postTitle > div > span {
    max-width: 100%;
    width: 100%;
    white-space: normal;
    font-family: "DomaineDisplay";
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    white-space: initial;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 21px !important;
}

.date {
    margin-top: 5px;
}

.date > div > span {
    color: #9F9F9F !important;
    font-size: 12px !important;
}

.date > div > .eventDate {
    color: var(--color-black) !important;
}

.divider {
    margin-bottom: 0 !important;
}

.eventCategory {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.eventCategory > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.eventCategory > span {
    display: block;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menuItem > span > a {
    text-decoration: none;
}

.menuItem > span > a:hover {
    text-decoration: none;
}