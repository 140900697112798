.imageContainer {
    width: 76px;
}

.title {
    text-overflow: ellipsis;
    white-space: nowrap; 
    width: 100%;
    overflow: hidden;
}

.card img {
    object-fit: cover;
    width: 76px;
    height: 76px;
    object-position: 50% 50%;
}

.infoContainer {
    overflow: hidden;
    margin-left: 15px;
    width: calc(100% - 76px - 15px);
}

.infoContainer h1 {
    font-size: 16px !important;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 0;
}

.infoContainer .category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.infoContainer .category span {
    text-transform: uppercase;
}

.infoContainer .category div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.date {
    display: block;
    font-size: 13px !important;
    margin-top: 10px;
}

.tick {
    position: absolute;
    left: 26px;
    top: 26px;
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 576px) {
    .infoContainer {
        /* 76px - img width */
        /* 15px - margin left width */
        width: calc(100% - 76px - 15px);
    }
}

@media (min-width: 768px) {
    .cardContainer {
        width: 100%;
    }
}