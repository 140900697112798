.label{
    font-size: 12px;
    line-height: 18px;
}

.labelBlog {
    text-transform: uppercase;
}

.labelCategory {
    text-transform: uppercase;
    color: var(--color-gray);
}

.title {
    margin-top: 10px;
    margin-bottom: 20px !important;
    font-size: 36px !important;
    line-height: 44px !important;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
}

.title:hover{
    text-decoration: underline;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
}

.subtitle {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.image {
    max-width: 100%;
    height: 300px;
    max-height: 300px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 25px;
}

.titleShadow {
    text-shadow: 1px 1px 15px rgb(255 255 255 / 25%);
}

.imageRow > div {
    padding: 0;
}

.paginationSpot ul {
    text-align: right;
}

.paginationSpot ul :global(.ant-pagination-simple-pager), .paginationSpot ul li {
    margin-bottom: 0px;
}

.spotlightMobile :global(.slide) {
    text-align: start;
}
