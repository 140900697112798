.contentContainer {
    width: 100%
}

.plmjAppsHeader {
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.plmjAppsSubtitle {
    align-items: center;
    width: 100%;
    justify-content: center;
}

.appCardLink {
    display: contents;
}

.plmjAppsHeaderTitle {
    margin-bottom: unset !important;
    font-weight: 500 !important;
}

.appCard:hover .cardTitle {
    text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.appCard:hover :global(.ant-card-cover) {
    opacity: 0.6;
}

.appCardCover {
    height: 40px;
    max-width: 40px;
    margin: 0 auto;
}

.plmjAppsOpenInNew {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: -4px;
}

.plmjAppsHeaderArrow {
    display: flex;
    align-items: center;
    padding-left: 0 !important; 
    padding-right: 0 !important; 
    margin-left: 1rem;
}

.plmjAppsFooter {
    justify-content: flex-end;
}

.cardTitle {
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.appCard {
    max-width: 240px;
    background: rgb(0 0 0 / 0%) !important;
    border: unset !important;
    margin: 0 auto !important;
}

.cardContainer { 
    margin-bottom: 41px !important; 
    margin-left: unset !important; 
    margin-right: unset !important; 
}

.publishedDate {
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
    margin-top: unset !important;
    margin-bottom: 21px !important;
}

.arrowNext {
    transform: rotate(180deg);
}

@media (max-width: 575px) {
    .plmjAppsHeaderArrow {
        
    }

    .plmjAppsHeader {
        margin-bottom: 40px;
    }

    .plmjAppsHeaderTitle {
        font-size: 30px !important;
    }

    .appCard {
        max-width: unset;
    }

    .cardContainer {
        row-gap: 30px !important;
    }

    .appCardContainer {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
    }

}

@media (min-width: 576px) {
    .appCardContainer {
        padding-bottom: .5rem;
    }

    .appCardContainer:nth-child(2n +1) {
        border-right: 1px solid black;
    }

    .plmjAppsHeaderTitle {
        font-size: 40px !important;
    }
}

@media (min-width: 768px) {
    .appCardContainer:not(:nth-child(3n)) {
        border-right: 1px solid black;
    }

    .appCardContainer:nth-child(3n) {
        border-right: none;
    }
}

@media (min-width: 992px) {
    .appCardContainer {
        border-right: 1px solid black !important;
    }

    .appCardContainer:last-child {
        border-right: unset !important;
    }

}