.category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 50px;
}

.category span {
    text-transform: uppercase;
}

.category div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}