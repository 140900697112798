.knowledgeContainer {
    margin: auto !important;
}

.background {
    width: 100%;
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 0;
    background-color: var(--color-black);
    background-image: url(../../../../assets/images/svg/knowledge-banner-mob.svg);
    background-repeat: no-repeat;
    background-position: bottom right;
    margin-top: 3px;
}

.allContainer {
    margin-bottom: 80px;
}

.knowledgeHeader {
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-top: 80px;
}

.knowledgeHeaderTitle {
    margin-bottom: unset !important;
    color: var(--color-white) !important;
    font-weight: 500 !important;
}

.contentsContainer {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important;
    padding-bottom: 16px !important;
}

.knowledgeContentRow {
    margin-bottom: 15px;
}

.knowledgeContentRow > div > h1 {
    margin: unset;
}

.knowledgeContentTitle {
    color: var(--color-white) !important;
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 290px;
    white-space: nowrap;
}

.knowledgeTitleArrow {
    margin-bottom: -6px;
    margin-left: 5px;
}

.knowledgeTitleArrow path {
    stroke: var(--color-white) !important;
}

.knowledgeContentLink {
    display: block;
}

.knowledgeContentLink:hover>div {
    text-decoration: underline solid var(--color-white) from-font !important;
    text-underline-offset: 4px;
}

.knowledgeContentText {
    color: var(--color-tertiary) !important;
    font-family: "GT-America";
    font-weight: 300;
}

.knowledgeFooter {
    justify-content: flex-end;
    padding-top: 1rem;
}

.seeMoreButton {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
    border-color: var(--color-white) !important;
    margin-bottom: 100px;
}

.seeMoreButton:hover {
    background: var(--color-white) !important;
    color: var(--color-black) !important;
}

.arrowPrev {
    filter: invert(1);
}

.arrowNext {
    transform: rotate(180deg);
    filter: invert(1);
}

@media (min-width: 768px) {
    .knowledgeContentTitle {
        text-overflow: ellipsis;
        display: -webkit-box;
        white-space: unset;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

@media (min-width: 991px) {
    .contentsContainer {
        border-bottom: none !important;
    }
    .background {
        display: block;
        height: 100%;
        width: 100vw;
        background-image: url(../../../../assets/images/svg/knowledge-banner-desk.svg);
        background-size: cover;
        background-position: bottom;
        background-repeat: no-repeat;
        background-color: unset;
    }
}

@media (max-width: 991px) {
    .knowledgeFooter {
        float: left;
    }
}

@media (max-width: 575px) {
    .knowledgeHeader {
        margin-bottom: 40px;
    }
    .knowledgeHeaderTitle {
        font-size: 30px !important;
    }
}