.icon path {
    stroke: var(--color-black);
}

.link:hover {
    opacity: 0.5;
}

.linkText {
    width: 270px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;   
}

@media screen and (min-width: 769px) {
    .linkText {
        width: 150px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;   
    }
}

@media screen and (min-width: 900px) {
    .linkText {
        width: 210px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;   
    }
}

@media screen and (min-width: 1024px) {
    .linkText {
        width: 230px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;   
    }
}