.subscription {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.subscription > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

.subscription > span {
    margin-left: 10px;
    font-size: 13px !important;
    font-weight: 500;
}

.subscription button {
    display: none;
    border-radius: 0 !important;
    transition: unset !important;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.infoContainer > h1 {
    margin: 5px 0 0 0 !important;
    font-size: 18px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.infoContainer .category span {
    text-transform: uppercase;
}

.infoContainer .category {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.infoContainer .category div {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.infoContainer > span:nth-child(3) {
    margin-top: 10px;
    font-weight: 300;
    color: var(--color-secondary) !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.infoContainer > span:last-child{
    margin-top: 10px;
    font-weight: 300 !important;
    font-size: 12px !important;
    line-height: 18px !important;
}

.card {
    width: 100%;
    border: none;
    cursor: pointer;
}

.card > :global(.ant-card-body) {
    padding: 0;
}

.card > :global(.ant-card-cover),
.card > :global(.ant-card-cover) > img {
    height: 150px;
}

.bodyContainer {
    flex-flow: column;
}

.cover {
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}

.upperTick {
    position: absolute;
    right: 12px;
    top: 10px;
    background-color: var(--color-white);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.upperTick svg{
    stroke-width: 2px;
}

@media (min-width: 768px) {

    .subscription button {
        display: block;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-bottom: 0;
    }

    .userAdded {
        height: 40px;
        min-height: 40px;
    }
}

@media (hover: hover) and (min-width: 768px) {
    .subscription button:hover {
        background-color: var(--color-black) !important;
        color: var(--color-white) !important;
    }
  }