

.resultsContainer {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.resultsContainer > span {
    color: var(--color-tertiary) !important;
}

.date {
    font-size: 13px !important;
}

.overline{
    text-transform: uppercase;
}

.overlineContainer{
    margin-bottom: 5px !important;
}

.cardContainer {
    margin-bottom: 40px !important;
    max-width: 270px !important;
    margin-left: auto;
    margin-right: auto;
}

.faded {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
}

.itemBody, .itemBody > * {
    margin-bottom: 10px !important;
    color: var(--color-secondary) !important;
    font-size: 14px;
}

.listWrap{
    margin-top: 45px !important; 
}

.title {
    margin-top: 0px !important;
    font-weight: 500 !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
}

.image {
    max-width: 100%;
    height: 260px;
    margin-bottom: 5px;
    background-color: transparent;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.link:hover .title {
    text-decoration: underline solid black from-font !important;
    text-underline-offset: 4px !important;
}

.link:hover .image {
    opacity: 0.6;
}

.pagination {
    width: auto;
    margin-right: -17px;
}

.pagination > ul{
    display: flex;
    justify-content: flex-end;
}

.mainContainer {
    margin-bottom: 75px !important;
}

.searchInputContainer {
    width: 100%;
    margin-bottom: 40px;
}

.searchInputContainer > .searchInput {
    height: 50px;
    padding-right: unset;
    padding-left: 15px;
}

.searchInput input {
    font-family: "DomaineDisplay";
    font-weight: 400 !important;
    letter-spacing: 0px;
    font-size: 18px;
    line-height: 20px;
}

.searchInput input::placeholder {
    color: var(--color-tertiary);
    opacity: 1;
    font-size: 18px !important;
    line-height: 20px !important;
}

.searchInput :global(.ant-input-prefix) {
    margin-right: unset;
}

.searchInputIcon {
    width: 20px;
    height: 20px;
    margin-right: 18px;
    margin-left: 13px;
}

.tabMenuContainer {
    margin-top: 30px;
    width: 100%;
    margin-bottom: 20px;
}

.noMarginBottom {
    margin-bottom: 0;
}

.divider {
    position: absolute;
    width: 100vw;
    left: -20px;
    height: 1px;
    background-color: var(--color-black);
    margin-top: 30px;
}

@media screen and (min-width: 481px) {
    .divider {
        left: -50px;
    }
}

@media screen and (min-width: 769px) {
    .divider {
        left: -90px;
    }

    .cardContainer {
        max-width: 270px !important;
    }

    .image {
        height: 260px;
    }
}

@media screen and (min-width: var(--main-container-max-width)) {
    .divider {
        left: calc((100vw - var(--main-container-max-width) + 180px) / -2);
    }
}

@media screen and (min-width: 992px) {
    .cardContainer {
        max-width: 245px;
    }

    .image {
        height: 160px;
    }
}

@media (min-width: 1009px) {
    .displayMobile {
        display: none;
    }
    .image {
        height: 200px;
    }
}

@media screen and (min-width: 1200px) {
    .cardContainer {
        max-width: 225px;
    }

    .image {
        height: 260px;
    }
}


@media screen and (min-width: 1350px) {
    .divider {
        left: calc((100vw - var(--main-container-max-width)) / -2) ;
    }
}

@media screen and (min-width: 1440px) {
    .cardContainer {
        max-width: 270px;
    }

    .image {
        height: 260px;
    }
}
