.page{
    background-image: radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%);
    height: 100vh;
}

.card{
    width: 300px;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 80px !important;
    border-radius: 4px !important;
}

.loginButton{
    height: 40px !important;
    background-color: #2F2F2F !important;
    border-radius: 2px !important;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
    color: white !important;
    display: inline-flex !important;
    justify-content: center;
}

.loginButton > span{
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
    align-items: center;
    display: inherit !important;
    align-self: center;
}
.loginButton > svg {
    position: relative;
    top: 5px;
}

.microsoftIcon{
    width: 18px;
}

.lockIcon{
    width: 23px;
    fill: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8px;
}
.circle {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #bdbdbd;
    margin-bottom: 1em !important;
}