.row {
  margin-bottom: 35px;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  position: relative;
}

.background {
  position: absolute;
  width: 50000vw;
  height: 105%;
  left: -2500vw;
  background-color: var(--color-white);
  opacity: .7;
}

.container {
  padding-bottom: 1rem;
  border-bottom: solid 1px  var(--color-tertiary);
}

.container:last-child {
  padding-bottom: 10px;
  border-bottom: none;
}

.container:not(:first-child) {
  margin-top: 1rem;
}

.title > h1 {
  margin-bottom: 2px !important;
  font-weight: 300 !important;
}

.text > span {
  color: var(--color-secondary) !important;
  font-family: "GT-America";
  font-weight: 300;
  font-size: 12px !important;
  line-height: 18px !important;
}

.seeIcon {
  margin-left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.seeIcon > a {
  display: flex;
  align-items: center;
}

.weeklyMenu {
  height: 25px;
  cursor: pointer;
}

.weeklyMenu h1 {
  margin-bottom: 0px !important;
  font-size:18px !important;
  line-height: 26px !important;
}

.text .musicName {
  font-size:14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  margin-bottom: 2px;
  text-decoration: none;
}

.text .musicLink:hover {
  text-decoration: underline;
}

.disableLink {
  pointer-events: none;
  cursor: not-allowed !important;
}

.container > .dateContainer {
  justify-content: space-between;
  flex-flow: row nowrap;
}

.modal {
  max-width: 900px !important;
}

.wrapper {
  align-items: center !important;
}

.modal > div > div:nth-child(2) {
  max-width: 1000px !important;
}

.modal :global(.ant-modal-body) {
  height: 100% !important;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.modal :global(.ant-modal-content) {
  height: 212px !important;
  /* height: auto !important; */
}

.modal :global(.ant-modal-close) {
  width: 25px;
  height: 25px;
  margin-top: 7px;
  margin-bottom: 0;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 92%;
  order: 2;
  background-color: black !important;
  z-index: 10000 !important;
}

.modal :global(.ant-modal-close-x) {
  display: flex;
  align-items: center !important;
}

.modal :global(.ant-modal-close-x) > svg {
  width: 100%;
  height: 100%;
}

.modal :global(.ant-modal-close-x) > svg > path {
  stroke: white;
}

.menuImg {
    width: 100%;
    height: auto;
}

@media (min-width: 480px) {
  .modal :global(.ant-modal-content) {
    height: 300px !important;
  }

  .modal :global(.ant-modal-close) {
    width: 27px;
    height: 27px;
    margin-top: 7px;
    left: 93%;
  }
}

@media (min-width: 576px) {
  .container > .dateContainer {
    justify-content: flex-start;
  }

  .modal :global(.ant-modal-content) {
    height: 330px !important;
  }

  .modal :global(.ant-modal-close) {
    width: 28px;
    height: 28px;
    margin-top: 6px;
    left: 94%;
  }
}

@media (min-width: 768px) {
  .modal :global(.ant-modal-content) {
    height: 485px !important;
  }

  .modal :global(.ant-modal-close) {
    width: 30px;
    height: 30px;
    margin-top: 10px;
    left: 95%;
  }
}

@media (min-width: 992px) {
  .row {
    margin-bottom: 60px;
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
  }

  .container {
    border-bottom: none;
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    padding-bottom: 0;
    margin-bottom: 1rem;
  }

  .container:last-child {
    border-bottom: none;
  }

  .container:not(:last-child) {
    border-right: solid 1px  var(--color-tertiary);
  }

  .background {
    display: block;
    background-color: var(--color-white);
    height: 100%;
    opacity: .7;
  }

  .seeIcon {
    align-items: flex-start;
  }

  .seeIcon > a {
    margin-top: .25rem;
  }

  .container:not(:nth-child(2)) {
    padding: 0 1.5rem;
  }

  .modal :global(.ant-modal-content) {
    height: 565px !important;
  }

  .modal :global(.ant-modal-close) {
    margin-top: 10px;
    left: 96%;
  }
}

@media (min-width: 1200px) {
  .modal :global(.ant-modal-content) {
    height: 530px !important;
  }

  .modal > div > div:nth-child(2) {
    max-width: 1000px !important;
  }

  .modal :global(.ant-modal-close) {
    width: 35px;
    height: 35px;
    margin-top: 15px;
    left: 95%;
  }
}

@media (min-width: var(--main-container-max-width)) {
  .background {
    left: calc((100vw - var(--main-container-max-width)) / -2);
  }
}
