.title {
    margin-top: 10px;
    margin-bottom: 25px !important;
    font-size: 30px !important;
    line-height: 38px !important;
}

.lastUpdate { 
    margin-top: 0px !important; 
    margin-bottom: 30px; 
}

.introContainer {
    margin-bottom: 40px;
}

.videoContainer {
    width: 100%;
}

.videoContainer > * {
    width: 100%;
}

.wrapper {
    position: relative;
    padding-top: 56.25%;
}

.player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.spotifyFrame {
    height: 360px;
}

html:not(:root) body .embedVideoMain a {
    font-size: 16px !important;
    line-height: 24px !important;
}

@media (min-width: 1024px) {
    .title {
        font-size: 40px !important;
        line-height: 48px !important;
    }

    .postImage img {
        object-fit: cover;
        width: 100%;
    }
}