.title {
    margin-top: 10px;
    margin-bottom: 25px !important;
    font-size: 30px !important;
    line-height: 38px !important;
    font-family: "DomaineDisplay";
    text-align: left;
}

.lastUpdate {
    margin-top: 0px !important;
    margin-bottom: 30px;
    color: #666666 !important;
}

.introContainer {
    margin-bottom: 40px;
}

.postImage img {
    object-fit: cover;
    max-height: 600px;
    width: inherit;
}

@media (min-width: 768px) {
    .title {
        font-size: var(--font-size-title-page) !important;
        line-height: var(--line-height-title) !important;
    }

    .postImage img {
        width: unset;
    }
}