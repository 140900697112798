:global(.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before) {
    content: none !important;
}

.side:global(.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow) {
    border-color: var(--color-black);
}

.apps {    
    background-color: var(--color-light-gray);
    margin-bottom: 20px;
}

.apps > :global(.pro-inner-item) {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}

.apps > :global(.pro-inner-item > .pro-icon-wrapper > .pro-icon) {
    justify-content: start !important;
}

.apps > :global(.prefix-wrapper) {
    width: 10px;
}

.appsRow > :global(.pro-inner-item > .pro-item-content) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.appsRow > :global(.pro-inner-item > .pro-item-content) > svg {
    width: 24px;
    margin-right: 10px
}

.side:global(.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item) {
    padding: 20px;
}

:global(.pro-item-content) {
    font-family: "GT-America";
    font-weight: 400;
    font-size: 16px;
}

.side {
    top: var(--header-height);
    color: var(--color-black) !important;
    position: fixed !important;
} 

.side > :global(.pro-sidebar-inner) {
    background: var(--color-white) !important;
    box-shadow: 5px 45px 45px rgba(0, 0, 0, 0.1);
}

.side > div > div > nav:global(.pro-menu) {
    padding-top: 0px;
    padding-bottom: 70px;
}

.sub:global(.open) > div > span {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.sub > :global(.react-slidedown.pro-inner-list-item) {
    background-color: var(--color-white) !important;
}

.sideTitle, .sub > div > span {
    font-family: "GT-America";
    font-weight: 500;
    font-size: 18px;
    color: var(--color-black);
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: unset !important;
}

:global(.popper-inner) {
    display: none !important;
}

@media screen and (min-width: 769px) {
    .side > div > div > nav:global(.pro-menu) {
        padding-top: 40px;
        padding-left: 40px;
        margin-bottom: 40px;
    }

    .side:global(.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item) {
        padding: 15px 35px 15px 20px;
    }
}
