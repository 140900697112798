.background{
    background-image: url("../../assets/images/jpeg/mainPages/whoIsWhoTeams.jpg");
    background-size: cover;
    background-position: top;
}

.container {
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 50%;
    background: rgb(227,227,227);
    background: -moz-linear-gradient(0deg, rgba(227,227,227,1) 66%, rgba(0,0,0,0) 80%);
    background: -webkit-linear-gradient(0deg, rgba(227,227,227,1) 66%, rgba(0,0,0,0) 80%);
    background: linear-gradient(0deg, rgba(227,227,227,1) 66%, rgba(0,0,0,0) 80%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e3e3e3",endColorstr="#000000",GradientType=1);
    /* background-image: url("../../assets/images/svg/who-is-who-background.svg"); */
}

.intro {
    margin-bottom: 40px !important;
}

.searchBar {
    margin-bottom: 40px !important;
}

.searchBar svg {
    margin-left: 13px;
    margin-right: .5rem;
}

.filtersContainer {
    margin-bottom: 50px !important;
}

.paginationWho > ul {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.paginationWho > ul > li:nth-child(2) {
    width: auto;
}

.cutout{
    height: 1000px;
    position: absolute;
    left: -100px;
    top: 200px;
    max-width: 100%;
}