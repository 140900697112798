.tab2{
    padding-left: 9px;
    height: 30px;
    line-height: 28px;
    width: 165px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.tab2 > .title{
    font-size: 16px !important;
    margin: 0;
    padding: 0;
    line-height: 27px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    flex: 1 1;
}

.tabWhite{
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
}

.tabWhite .title{
    color: var(--color-black) !important;
}

.tabBlack{
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
}

.tabBlack .title{
    color: var(--color-white) !important;
}

.iconWrap{
    width: 44px;
    height: 28px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 3px;
}

.closeWrap2 > svg{
    width: 14px;
    height: 14px;
    margin-top: 8.5px;
    margin-right: 6px;
}

.closeWrap2 > svg > path{
    stroke: var(--color-medium-gray) !important;
}

.tabBlack .closeWrap2{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.tabWhite .closeWrap2{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.tabBlack .pinWrap{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.tabWhite .pinWrap{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.pinWrap > svg{
    width: 20px !important;
    height: 20px !important;
    margin-top: 4px !important;
    margin-right: 5px !important;
    fill: var(--color-medium-gray) !important;
}

.pinWrap > svg > path{
    stroke: var(--color-medium-gray) !important;
}



.tabWhite:hover{
    background-color: var(--color-black);
    border: 1px solid var(--color-black);
}

.tabWhite:hover .title{
    color: var(--color-white) !important;
}

.tabBlack:hover{
    background-color: var(--color-white);
    border: 1px solid var(--color-white);
}

.tabBlack:hover .title{
    color: var(--color-black) !important;
}

.tabBlack:hover .closeWrap2{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.tabWhite:hover .closeWrap2{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.tabBlack:hover .pinWrap{
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255,1) 34%, rgba(255, 255, 255,1) 100%);
}

.tabWhite:hover .pinWrap{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0,0,0,1) 34%, rgba(0,0,0,1) 100%);
}

.tabBlack .closeWrap2:hover > svg > path{
    stroke: var(--color-black) !important;
}

.tabWhite .closeWrap2:hover > svg > path{
    stroke: var(--color-white) !important;
}

.tabBlack .pinWrap:hover > svg{
    fill: var(--color-black) !important;
}

.tabBlack .pinWrap:hover > svg > path{
    stroke: var(--color-black) !important;
}

.tabWhite .pinWrap:hover > svg{
    fill: var(--color-white) !important;
}

.tabWhite .pinWrap:hover > svg > path{
    stroke: var(--color-white) !important;
}



.dropdown{
    width: 30px;
    min-width: 30px;
    padding: 0;
    justify-content: center;
    align-items: center;
    margin-right: 2.5px;
    margin-left: 2.5px;
}

.dropdown > span{
    font-size: 13px;
    color: var(--color-white);
}

.dropdown:hover > span{
    color: var(--color-black);
}

.menu{
    padding: 0 !important;
    background-color: var(--color-black) !important;
}

.menu > li{
    padding: 0;
}