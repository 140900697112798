@font-face {
    font-family: "DomaineDisplay";
    font-weight: 400;
    src: url('./fonts/DomaineDisplayWeb/DomaineDisplayWeb-Regular.woff');
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 500;
    src: url('./fonts/DomaineDisplayWeb/DomaineDisplayWeb-Medium.woff');
}

@font-face {
    font-family: "DomaineDisplay";
    font-weight: 600;
    src: url('./fonts/DomaineDisplayWeb/DomaineDisplayWeb-Semibold.woff');
}

@font-face {
    font-family: "GT-America";
    font-weight: 300;
    src: url("./fonts/GT-America-Extended/GT-America-Extended-Light.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 400;
    src: url("./fonts/GT-America-Extended/GT-America-Extended-Regular.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 500;
    src: url("./fonts/GT-America-Extended/GT-America-Extended-Medium.woff");
}

@font-face {
    font-family: "GT-America";
    font-weight: 600;
    src: url("./fonts/GT-America-Extended/GT-America-Extended-Bold.woff");
}
