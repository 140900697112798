.cardWithoutImage {
    background-color: var(--color-gray) !important;
    width: 100%;
    height: 100%;
}

.cardWithImage {
    display: block;
    width: auto;
    height: auto;
}

.cardWithImage :global(.ant-card-body) {
    position: absolute;
    background-color: #323232;
    opacity: 30%;
    top: 0;
    width: 100%;
    height: 100%;
}