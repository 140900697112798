.kioskHeader {
    margin-bottom: 60px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.headerLink {
    display: contents;
}

.kioskHeaderTitle {
    margin-bottom: unset !important;
    font-weight: 500 !important;
}

.kioskHeaderArrow {
    display: flex;
    align-items: center;
}

.kioskFooter {
    justify-content: flex-end;
}

.cardTitle {
    margin-bottom: 0px !important;
    font-weight: 400 !important;
}

.newspaperCard {
    background: rgb(0 0 0 / 0%) !important;
    border: unset !important;
}

.kioskCarousel > div:nth-child(2) > div > *{
    max-height: 100% !important;
}

.newspaperCard > div:nth-child(1) > img{
    object-fit: contain;
}

.newspaperCard > div:nth-child(2){
    padding: unset;
    margin-top: 15px;
}

.newspaperCard:hover .cardTitle {
    text-decoration: underline solid black from-font;
    text-underline-offset: 4px;
}

.newspaperCard:hover :global(.ant-card-cover) {
    opacity: 0.6;
}

.newspaperCard > div:first-child {
    border: 1px solid #e3e3e3;
}

.seeMoreButton:hover {
    background: var(--color-black) !important;
    color: var(--color-white) !important;
}

.cardContainer { 
    margin-bottom: 41px !important; 
    margin-left: unset !important; 
    margin-right: unset !important; 
}

.publishedDate {
    font-weight: 300 !important;
    color: var(--color-secondary) !important;
    margin-top: unset !important;
    margin-bottom: 21px !important;
}

.kioskHeaderArrowSvg {
    margin-left: 1rem;
}

.arrowNext {
    transform: rotate(180deg);
}

.tooltip{
    margin-top: 6px !important;
}

.tooltip > div > div:nth-child(2){
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    padding: 12px 10px !important;
    width: 1200px;
}

.tooltip > div > div:first-child{
    display: none !important;
}

.tooltip .tooltipRow{
    cursor: pointer;
}

.tooltip .tooltipRow > div{
    width: 100%;
}

.tooltip .tooltipRow:last-child {
    margin-top: 10px;
}

.tooltip .labelRow{
    justify-content: space-between;
}

.tooltip .labelRow > .label{
    color: var(--color-gray) !important;
}

.tooltip .labelRow > .copyLabel{
    color: var(--color-gray) !important;
    text-transform: uppercase;
}

.tooltip .tooltipRow:hover .copyLabel{
    color: var(--color-white) !important;
}


@media (max-width: 575px) {

    .newspaperCard > div:nth-child(1) > img {
        max-height: 225px;
    }

    .kioskHeaderArrow {
        padding-left: 17px;
    }

    .kioskHeaderArrowSvg {
        height: 21px !important;
        width: 11px !important;
    }

    .kioskHeader {
        margin-bottom: 40px;
    }

    .kioskHeaderTitle {
        font-size: 30px !important;
    }

}

@media (min-width: 576px) and (max-width: 767.98px) {
    .newspaperCard > div:nth-child(1) > img {
        max-height: 225px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .newspaperCard > div:nth-child(1) > img {
        max-height: 202px;
    }
}

@media (min-width: 1024px) and (max-width: 1200px) {
    .newspaperCard > div:nth-child(1) > img {
        max-height: 116px;
    }
}

@media (min-width: 1201px) and (max-width: 1350px) {
    .newspaperCard > div:nth-child(1) > img {
        max-height: 137px;
    }
}

@media (min-width: 1351px) {
    .newspaperCard > div:nth-child(1) > img {
        max-height: 162px;
    }
}