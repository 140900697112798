.lead {
    font-size: 18px;
    line-height: 26px;
    color: var(--color-black);
    margin-bottom: 20px;
    font-family: "DomaineDisplay";
    margin-bottom: 0 !important;
}
 
 @media (min-width: 480px) and (max-width: 767px) {
    .lead {
       font-size: 15px;
       font-weight: 400;
       line-height: 20px
    }
 }

 @media (min-width: 768px) {
    .lead {
       font-size: var( --font-size-lead) !important;
       font-weight: 400;
       line-height: var(--line-height-lead) !important;
    }
 }