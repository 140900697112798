.banner {
    height: 300px;
    width: 100vw;
    position: absolute;
    padding: 0 !important;
    max-width: none !important;
    overflow: hidden;
}

.banner img {
    position: absolute;
    width: 800px;
    height: 600px;
    left: -140px;
    top: -225px;
}

.cardModal{
    max-width: 500px !important;
}
.modal * {
    border: none !important;
    box-shadow: none !important;
}

.modal .body {
    font-family: 'GT-America';
    font-weight: 300;
}

.modal>div:nth-child(2)>div>div {
    font-size: 18px;
    font-weight: 600;
}

.cardModal>div:nth-child(2)>div:nth-child(3) {
    padding-top: 0;
}

.modal>div>div>button {
    font-weight: 600;
    width: 80px;
    height: 50px;
}

.modal>div>div>button:nth-child(1):hover {
    color: var(--color-black);
    opacity: .6;
}

.modal>div>div>button:nth-child(2) {
    background-color: var(--color-black);
}

.modal>div>div>button:nth-child(2):hover {
    opacity: .6;
}

.container {
    padding-bottom: 60px;
}

.cardModal>div>div:last-child {
    height: unset;
}

.filterIconMobile {
    position: fixed !important;
    background-color: var(--color-black);
    bottom: 25px;
    right: 25px;
    width: 60px !important;
    height: 60px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 1;
}

.filterIconMobile > svg > g line, 
.filterIconMobile > svg > g circle {
    stroke: var(--color-white);
}

.activeFilters {
    width: .75rem;
    height: .75rem; 
    background-color: var(--color-call-action);
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50%;
}

@media only screen and (min-width: 376px){
    .banner {
        background-position: -75px;
    }
}

@media only screen and (min-width: 447px) {
    .banner img {
        left: -75px;
    }
}

@media only screen and (min-width: 481px) {
    .banner img {
        top: -150px
    }
}

@media only screen and (min-width: 525px) {
    .banner img {
        top: -175px
    }
}

@media only screen and (min-width: 622px) {
    .banner img {
        top: -250px;
        width: 1200px
    }
}

@media (min-width: 769px) {
    .banner img {
        left: -1vw;
        top: 0;
        width: 110vw;
        height: 400px;
        top: -50px;
    }
}

@media (min-width: 839px) {
    .banner img {
        top: -50px;
    }
}

@media (min-width: 928px) {
    .banner img {
        height: 350px;
        left: -5vw;
    }
}

@media (min-width: 1024px) {
    .banner img {
        left: 0px;
        width: 100%;
        height: 1000px;
        top: -375px
    }

    .filterIconMobile {
        display: none;
    }
}

@media (min-width: 1200px) {
    .banner {
        height: 400px;
    }
    .banner img {
        height: 800px;
        width: 1300px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1300px) {
    .banner img {
        height: 800px;
        width: 1400px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1400px) {
    .banner img {
        height: 800px;
        width: 1500px;
        left: 0px;
        top: -290px
    }
}

@media (min-width: 1500px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 700px;
        width: 1600px;
        top: -200px;
        left: -20px;
    }
}

@media (min-width: 1600px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 800px;
        width: 1700px;
        top: -290px;
        left: -20px;
    }
}

@media (min-width: 1700px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 900px;
        width: 1900px;
        top: -350px;
        left: -20px;
    }
}

@media (min-width: 1880px) {
    .banner {
        height: 375px;
    }
    .banner img {
        height: 1200px;
        width: 2000px;
        top: -500px;
        left: -20px;
    }
}

@media (min-width: 1981px) {
    .banner img {
        height: calc(100vw/1.7);
        width: calc(100vw + 200px);
        top: calc((100vw/3.5)*-1);
        left: calc(100vw/100*-3)
    }
}

@media (max-device-width: 419px) {
    .cardModal {
        height: 95%;
    }

    .cardModal :global(.ant-modal-content) {
        height: 100%;
    }

    .cardModal :global(.ant-modal-header) {
        height: 54px;
    }

    .cardModal :global(.ant-modal-close),
    .cardModal :global(.ant-modal-header) {
        border-bottom: solid 1px var(--color-medium-gray);
    }

    .cardModal :global(.ant-modal-body) {
        background-color: var(--color-white);
        padding-bottom: 70px;
    }

    .hasInstructions :global(.ant-modal-body) {
        padding-bottom: 30px;
    }

    .cardModal :global(.ant-row-end) {
        position: absolute;
        bottom: 0;
    }
}