.label {
    font-size: 12px;
    line-height: 18px;
}

.labelBlog {
    text-transform: uppercase;
}

.labelCategory {
    text-transform: uppercase;
    color: var(--color-gray);
}

.subtitle {
    font-family: "GT-America";
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.arrowNext {
    transform: rotate(180deg);
}

.tinyImage {
    width: 78px;
    height: 75px;
    background-position: top center;
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    border: 0px solid #e3e3e3;
    opacity: 1;
    transition: ease-in-out opacity 0.2s, ease-in-out border 0.2s;
    cursor: pointer;
    margin: 0 4px;
}

.tinyImage:hover {
    opacity: 0.6;
}

.tinyImageSelected {
    border: 4px solid #e3e3e3;
    opacity: 0.6;
}

.titleShadow {
    text-shadow: 1px 1px 15px rgb(255 255 255 / 25%);
}

.image {
    width: 513px;
    height: 450px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    margin: 0 auto;
    padding: 0 16px;
}

@media screen and (max-width: 767px) {
    .image {
        width: 100%;
        height: auto;
        padding: 0;
    }
}

@media screen and (min-width: 768px) {
    .title {
        font-size: var(--font-size-title-page) !important;
        line-height: var(--line-height-title) !important;
        margin-top: 10px;
        margin-bottom: 20px !important;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        overflow: hidden;
    }

    .title:hover {
        text-decoration: underline;
        text-underline-offset: 4px;
        text-decoration-thickness: 1px;
    }

    .primaryCarouselRow>div:nth-child(2),
    .secondaryCarouselRow>div:nth-child(2) {
        padding-left: 30px;
    }

    .primaryCarouselRow>div>.noMargin {
        margin-bottom: 0px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1349px) {
    .primaryCarouselRow {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .secondaryCarouselRow {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .secondaryCarouselRow .slick-slide {
        width: 75px !important;
        margin-left: 13px;
    }

    .container {
        display: flex;
        justify-content: center;
        /* Centraliza horizontalmente o conteúdo */
        align-items: center;
        /* Centraliza verticalmente o conteúdo */
        flex-wrap: wrap;
        /* Permite que o conteúdo quebre para a próxima linha se necessário */
    }

    .image {
        width: 397px;
        height: 450px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 3px;
        /* Adiciona espaço ao redor da imagem */
    }

    .tinyImage {
        width: 57px;
        height: 65px;
        margin: 0 3px;
    }

    .secondaryCarouselRow .slick-slide:first-child {
        margin-left: 0px;
    }

    .spotlightContainer {
        margin-bottom: 60px;
    }

    .primaryCarouselRow>div:nth-child(2),
    .secondaryCarouselRow>div:nth-child(2) {
        padding-left: 60px;
    }
}

@media screen and (min-width: 1350px) {
    .primaryCarouselRow {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .secondaryCarouselRow {
        width: 100%;
        margin-bottom: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .secondaryCarouselRow .slick-slide {
        width: 75px !important;
        margin-left: 13px;
    }

    .secondaryCarouselRow .slick-slide:first-child {
        margin-left: 0px;
    }

    .spotlightContainer {
        margin-bottom: 60px;
    }

    .primaryCarouselRow>div:nth-child(2),
    .secondaryCarouselRow>div:nth-child(2) {
        padding-left: 60px;
    }
}