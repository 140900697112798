.intro {
    margin-top: 64px !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    background-color: #E9F4F6;
    max-width: none !important;
    position: relative;
}

.intro > div {
    max-width: var(--main-container-max-width);
    margin: 0 auto;
}

.intro > div:last-child {
    margin-top: 25px;
    display: flex;
}

.intro > div > h1 {
    font-weight: 400;
    line-height:  50px;
}

.intro > div > span {
    font-size: 15px !important;
    color: var(--color-secondary) !important;
    line-height: 19.5px;
}

.iconContainer {
    position: absolute;
    bottom: -1px;
    max-width: none !important;
    width: 100vw;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.iconContainer > svg:first-child {
    margin-left: -90px;
}

.iconContainer > svg:last-child {
    display: none;
}

@media (min-width: 481px) {
    .iconContainer > svg:first-child {
        margin-left: -70px;
    }
}

@media (min-width: 768px) {

    .intro > div > span {
        font-size: 24px !important;
        line-height: 31.2px;
        font-weight: 400 !important;
    }

    .iconContainer > svg:first-child {
        margin-left: -50px;
    }
}

@media (min-width: 769px) {
    .intro {
        padding-top: 119px !important;
        margin-top: 0 !important;
    }
}

@media (min-width: 992px) {
    .intro > div:last-child {
        margin-top: 50px;
    }
}

@media (min-width: 1200px) {
    .iconContainer > svg:last-child {
        display: block;
    }
}

@media (min-width: 1400px) {
    .iconContainer > svg:first-child {
        margin-left: -10px !important;
    }
}