.card {
  display: flex;
  border: 1px solid rgb(0, 0, 0, 20%);
  padding: 15px 15px;
  padding-right: 0;
  cursor: pointer;
}

.card > * {
    margin-right: 10px;
}

.card > .tick{
    position: absolute;
    right: 0px;
 
}

.card:not(:nth-child(2)) {
  margin-top: -1px;
  border-top: 1px solid rgb(0, 0, 0, 0);
}

.card:hover {
  border: solid 1px var(--color-black);
}

.card > span {
  white-space: unset;
  max-width: calc(100% - 75px - 10px);
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.card:hover > span:nth-child(3) {
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
}

.category {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  margin-right: 10px;
}

.tick {
    display: flex;
    width: 24px !important;
    height: 24px !important;
    border: solid 1px var(--color-black);
    border-radius: 50%;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 15px !important;
}

.title{
  padding-left: 10px;
}