.userListContainer {
    margin: 0 auto 60px auto !important;
}

.userListContainerLoading {
    pointer-events: none;
    opacity: 0.6;
}

.details {
    overflow: hidden;
    z-index: 11;
    position: absolute;
    width: 100%;
    max-height: 0;
    opacity: 0;
    transition: ease-in-out max-height 0.6s, cubic-bezier(0.215, 0.610, 0.355, 1) opacity 0.3s;
}

.card :global(.ant-card-body){
    transition: ease-in-out opacity 0.3s;
}

.card:hover :global(.ant-card-body){
    opacity: 0;
}

.card:hover .details {
    max-height: 800px;
    opacity: 1;
};


.closeIcon path {
    stroke: var(--color-white);
}

.teamsContainer > div{
    height: 50px;
}

.teamsContainer > div:last-child {
    display: flex;
    justify-content: flex-end;
}

.officeContainer {
    margin-top: 10px;
    margin-bottom: 15px;
}

.officeContainer > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.officeContainer > div > .department {
    margin: 0;
    color: var(--color-light-gray-2) !important;
    display: flex;
    flex-direction: column;
}

.closeIcon path {
    stroke: var(--color-white);
}

.name {
    color: var(--color-white) !important;
}

.jobTitle {
    color: var(--color-light-gray-2) !important;
    text-transform: uppercase;
}

.department {
    font-family: "GT-America" !important;
    font-size: 12px;
    margin: 5px 0;
    overflow: hidden;
    display: flex;
    align-items: flex-center;
}

.department > .label {
    color: var(--color-secondary) !important;
    text-transform: uppercase;
    display: block;
    line-height: 170%;
    margin-right: 5px;
}

.department > a {
    text-overflow: ellipsis;
    white-space:  nowrap;
    overflow: hidden;
}

.department > .comma:nth-child(2){
    display: none;
}

.infoText {
    max-width: 100%;
    display: block;
    color: var(--color-light-gray-2) !important;
    text-overflow: ellipsis;
    white-space: pre-line;
    overflow: hidden;
}

.link {
    text-decoration: underline white 1px;
    text-underline-offset: 2px;
    cursor: pointer;
}

.divider {
    border-top: 1px solid var(--color-light-gray-2) !important;
    margin: 12px 0 !important;
}

.teamsIconLink {
    display: flex;
    /* align-items: center; */
}

@media (min-device-width: 768px) {
    .teamsContainer {
        position: static;
        width: calc(100% + 40px);
        text-transform: capitalize;
        bottom: 0;
        margin-left: -20px;
        margin-bottom: -20px;
        margin-top: 8px;
        padding: 9px 20px 14px 20px;
        background-color: var(--color-call-action) !important;
        cursor: pointer;
    }

    .teamsContainer > div{
        height: 25px;
    }
    
    .link {
        text-decoration: unset;
        text-underline-offset: unset;
    }

    .link:hover {
        color: var(--color-white) !important;
        text-decoration: underline white 1px;
        text-underline-offset: 2px;
        cursor: pointer;
    }
}

@media (min-device-width: 1024px) and (max-device-width: 1089px) {
    .microsoftLink{
        font-size: 12px !important;
    }

    
}