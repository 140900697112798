blockquote {
  padding: 10px 0px 10px 25px !important;
  margin: 0 0 25px;
  font-size: 20px;
  line-height: 28px;
  border-left: 5px solid var(--color-medium-gray);
  display: block;
  margin-block-start: 10px;
  margin-block-end: 10px;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.wrapper{
  margin-bottom: 25px !important;
}

@media (min-width: 768px) {
  .wrapper p{
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}
  @media (min-width: 768px) {
  .wrapper h1{
    font-size: var(--font-size-title) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}