.pageItem {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: fit-content;
}

.pageItem:last-child {
    margin-bottom: unset;
}

.pageItemTitle {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pageItemTitle:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.noResults {
    margin-bottom: unset !important;
}

.loading {
    opacity: 0.5;
    cursor:not-allowed;
}

.highlight {
    background-color: black;
    color: white;
    padding: unset !important;
}

.noResultsMessage {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.button {
    margin-bottom: unset;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}