.wrapper p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 768px) {
  .wrapper p {
    color: var(--color-secondary);
    font-size: var(--font-size-body) !important;
    line-height:var(--line-height-body-and-title)!important;
  }
}
