.backTop {
    display: none;
}

.backTop>div>div {
    border: 1px solid white;
}

.tooltip>div>div:nth-child(2) {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
    padding: 12px 10px !important;
    border: 1px solid white;
}

@media (min-width: 1024px) {
    .backTop {
        display: block
    }
}