.wrapper {
  margin-top: 15px !important;
  margin-bottom: 40px !important;
}

.wrapper h1 {
  font-family: "DomaineDisplay";
  font-size: 36px !important;
  line-height: 44px !important;
  margin-bottom: 25px !important;
  font-weight: 500 !important;
}

.wrapper p img {
  object-fit: cover;
  width: 100%;
}

.wrapper img {
  width: 100%;
}

.wrapper p {
  color: var(--color-secondary);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 25px;
}

@media screen and (min-device-width: 768px) {

  .wrapper h1 {
   
    font-size: var(--font-size-title) !important;
    line-height: var(--line-height-body-and-title)!important;
  
  }
  .wrapper p {
    color: var(--color-secondary);
    font-size: var(--font-size-body) !important;
    line-height: var(--line-height-body-and-title)!important;
  }
}


@media screen and (min-device-width: 1024px) {
  .wrapper p img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    object-fit: unset;
    width: auto;
  }


}
