.eventItemCategory {
    display: flex;
    align-items: center;
    margin-top: unset;
    line-height: unset;
}

.eventItemCategory div:first-child {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.eventItemCategory span {
    text-transform: uppercase;
    color: var(--color-secondary) !important;
}

.eventItem {
    margin-bottom: 15px;
    display: grid;
    cursor: pointer;
    width: fit-content;
}

.eventItem:hover .eventItemTitle {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 1px;
}

.eventItemTitle {
    font-family: "DomaineDisplay";
    color: black;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.eventItem:last-child {
    margin-bottom: unset;
}

.noResultsMessage {
    font-family: "GT-America";
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    text-align: left;
}

.highlight {
    background-color: black;
    color: white;
    padding: unset !important;
}

.button {
    margin-bottom: unset;
}

.button:hover {
    background-color: var(--color-black) !important;
    color: var(--color-white) !important;
}