.description {
    font-family: "GT-America";
    font-size: var(--font-size-page-description);
    line-height: var(--line-height-page-description);
    font-weight: 300 !important;
}

@media (min-width: 480px) and (max-width: 767px) {
    .description {
        font-size: 15px;
        font-weight: 400 !important;
        line-height: 20px
    }
 }

@media (min-width: 768px) {
    .description {
        display: block;
        width: 80%;
        font-size: 18px;
        font-weight: 400 !important;
        line-height: 25px;
    }
}
