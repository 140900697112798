.pageBody {
    overflow-x: auto;
    font-family: "GT America" !important;
}

.pageBody p {
    margin-bottom: 0 !important;
}

.pageBody p img {
    object-fit: cover;
    width: 100%;
}

.pageBody strong {
    color: var(--color-black);
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.pageBody a {
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.pageBody li {
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.pageBody p {
    font-size: var(--font-size-content);
    line-height: var(--line-height-content);
}

.pageBody h1 {
    font-weight: 400;
    font-size: var(--font-size-title-page);
    line-height: var(--line-height-title);
}

.pageBody h2 {
    font-weight: 400;
    font-size: var(--font-size-subtitle);
    line-height: var(--line-height-subtitle);
}

.pageBody h3 {
    font-weight: 400;
    font-size: var(--font-size-lead);
    line-height: var(--line-height-lead);
}

.pageBody * {
    color: #444444 !important;
}

@media screen and (min-device-width: 1024px) {
    .pageBody p img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        object-fit: unset;
        width: auto;
    }
}